import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {StorageService} from '../storage/storage.service';
import {FileService} from '../core/file.service';
import {KeyMap} from "../../types/interfaces";
import {ProviderType} from "../../types/enums";

enum GlobalSettingStorage {
  MAP = 'map-setting',
  PROVIDER = 'provider-setting',
  RECIPE_IMPORT = 'recipe-import-setting'
}

@Injectable({
  providedIn: 'root',
})
export class GlobalSettingService {

  constructor(
    private api: ApiService,
    private storageService: StorageService,
    private fileService: FileService,
  ) {
  }

  async getFaqAccount(): Promise<any> {
    return this.loadFromDbOrFile('faq-account');
  }

  async getFaqCovid(): Promise<any> {
    return this.loadFromDbOrFile('faq-covid');
  }

  async getFaqDelivery(): Promise<any> {
    return this.loadFromDbOrFile('faq-delivery');
  }

  async getFaqPayment(): Promise<any> {
    return this.loadFromDbOrFile('faq-payment');
  }

  async getFaqGeneral(): Promise<any> {
    return this.loadFromDbOrFile('faq-general');
  }

  async getFaqOrder(): Promise<any> {
    return this.loadFromDbOrFile('faq-order');
  }

  async getImprint(): Promise<any> {
    return this.loadFromDbOrFile('imprint');
  }

  async getGdpr(): Promise<string> {
    return this.loadFromDbOrFile('gdpr');
  }

  async getTermsAndConditions(): Promise<string> {
    return this.loadFromDbOrFile('terms-and-conditions');
  }

  async getWithdrawal(): Promise<string> {
    return this.loadFromDbOrFile('withdrawal');
  }

  async loadFromDbOrFile(name: string): Promise<string> {
    return (await this.api.get('global-setting/text/' + name)) || (await this.fileService.loadTextFile('./assets/texts/' + name + '.html'));
  }

  async getMapSetting(): Promise<any> {
    let setting = await this.getStoredMapSetting();
    if (!setting) {
      setting = await this.api.get('global-setting/text/map');
      await this.saveMapSetting(setting);
    }
    return setting || 'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png';
  }

  async getBooleanSetting(settingType: string, field: string): Promise<boolean> {
    return this.api.get('global-setting/boolean/' + settingType + '/' + field);
  }

  async getShowCustomerAppointments(): Promise<boolean> {
    return this.api.get('global-setting/customer-appointment');
  }

  async getRecipeImportSetting(): Promise<any> {
    let setting = await this.getStoredRecipeImportSetting();
    if (!setting) {
      setting = await this.api.get('global-setting/recipe-import');
      await this.saveRecipeImportSetting(setting);
    }
    return setting || {};
  }

  async getProviderSetting(refresh?: boolean): Promise<any> {
    const providersAreLoaded = await this.hasStoredProviderSetting();
    if (!providersAreLoaded || refresh) {
      const providerSetting = await this.api.get('global-setting/provider');
      await this.saveProviderSetting(providerSetting);
    }
    return this.getStoredProviderSetting();
  }

  async clear() {
    await this.storageService.remove(GlobalSettingStorage.RECIPE_IMPORT, GlobalSettingStorage.MAP);
  }

  private async getStoredRecipeImportSetting(): Promise<any> {
    const value = await this.storageService.get(GlobalSettingStorage.RECIPE_IMPORT);
    return value ? JSON.parse(value) : null;
  }

  private async getStoredMapSetting(): Promise<string> {
    return this.storageService.get(GlobalSettingStorage.MAP);
  }

  async getStoredProviderSetting(): Promise<KeyMap<boolean>> {
    const value = await this.storageService.get(GlobalSettingStorage.PROVIDER);
    const defaultSetting = {
      [ProviderType.FACEBOOK]: false,
      [ProviderType.APPLE]: false,
      [ProviderType.GOOGLE]: false
    };
    const storedValue = value ? JSON.parse(value) : {};
    return Object.assign({}, defaultSetting, storedValue);
  }

  private async hasStoredProviderSetting(): Promise<boolean> {
    const value = await this.storageService.get(GlobalSettingStorage.PROVIDER);
    return !!value;
  }

  private async saveMapSetting(setting: string) {
    return this.storageService.save(GlobalSettingStorage.MAP, setting);
  }

  private async saveProviderSetting(setting: any) {
    return this.storageService.save(GlobalSettingStorage.PROVIDER, JSON.stringify(setting || {}));
  }

  private async saveRecipeImportSetting(setting: any): Promise<any> {
    return this.storageService.save(GlobalSettingStorage.RECIPE_IMPORT, JSON.stringify(setting || {}));
  }

}
