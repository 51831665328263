import { Pipe, PipeTransform } from '@angular/core';
import {User} from "../types/models";

@Pipe({
  name: 'userName'
})
export class UserNamePipe implements PipeTransform {

  transform(user: User, mail: boolean = false): string {
    let nameText = null;
    let mailText = null;
    if (user?.FirstName && user?.LastName) {
      nameText = user.FirstName + ' ' + user.LastName;
    } else if (user?.FirstName || user?.LastName) {
      nameText = user?.FirstName || user?.LastName;
    }
    if (user?.Mail && nameText && mail) {
      mailText = ' (' + user.Mail + ')';
    } else if (user?.Mail && !nameText) {
      mailText = user.Mail;
    }
    return !nameText && !mailText ? '-' : ((nameText || '') + (mailText || ''));
  }

}
