import { Injectable } from '@angular/core';
import {ApiService} from "../global/api.service";
import {Store} from "../../types/models";
import {LeafletCoordinate} from '../../types/thirdparty';
import {DeliveryType} from "../../types/enums";

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(
    private api: ApiService
  ) { }

  async get(): Promise<Store> {
    return this.api.get('store');
  }

  async getById(storeId: string): Promise<Store> {
    if (!storeId) {
      return null;
    }
    return this.api.get('store/' + storeId);
  }

  async getEmployeeStoreId(): Promise<string> {
    return this.api.get('store/employee');
  }

  async find(addressId: string, params = {}): Promise<Store> {
    if (!addressId) {
      return null;
    }
    return this.api.get(`store/find/${addressId}`, params);
  }

  async getAllDeliveryAreas(): Promise<any[]> {
    return this.api.get('store/delivery-areas');
  }

  async getAllDeliveryAreasForMap(): Promise<{ coordinate: LeafletCoordinate; options: any, styleName: string, deliveryType: DeliveryType }[]> {
    const areas = await this.getAllDeliveryAreas();
    const mapAreas = [];
    for (const area of areas) {
      mapAreas.push({
        deliveryType: area.type,
        coordinate: area.coordinate,
        options: { radius: area.radius},
        styleName: 'area'
      });
    }
    return mapAreas;
  }
}
