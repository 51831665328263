import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {ApiService} from "../global/api.service";
import {ValidationService} from "../core/validation.service";
import {Appointment, Order, TimeSlot, TimeSlotType} from "../../types/models";
import {TimeSlotSchedule} from "../../types/io";
import {AdminTimeSlotStorageService} from "../storage/admin-time-slot-storage.service";
import {Observable, Subject} from "rxjs";
import {GeoLocation} from "../../types/interfaces";
import {AdminOrderStorageService} from "../storage/admin-order-storage.service";
import {AuthService} from "../global/auth.service";
import {UserService} from "../customer/user.service";
import {TimeSlotState} from "../../types/enums";
import {AdminUserService} from "./admin-user.service";

@Injectable({
  providedIn: 'root'
})
export class AdminTimeSlotService {

  private startLocationTrackingSubject = new Subject<string>();
  private stopLocationTrackingSubject = new Subject<void>();

  constructor(
    private api: ApiService,
    private validationService: ValidationService,
    private storageService: AdminTimeSlotStorageService,
    private orderStorageService: AdminOrderStorageService,
    private authService: AuthService,
    private userService: UserService,
    private adminUserService: AdminUserService
  ) {
  }

  init() {
    this.authService.Logout.subscribe(async () => this.stopLocationTrackingSubject.next());
    this.authService.LoggedIn.subscribe(async () => {
      if (await this.userService.hasRole()) {
        const tracking = await this.adminUserService.getMyTask();
        if (tracking.TimeSlotId) {
          const timeSlot = await this.getMy();
          if (timeSlot && timeSlot.State === TimeSlotState.DELIVERING) {
            return this.startLocationTrackingSubject.next(timeSlot.Id);
          }
        }

      }
    });
  }

  public static getEmpty(): TimeSlot {
    return {
      Name: null,
      LateOrder: false,
      LateOrderTime: moment().startOf('d').toDate(),
      OrderTime: moment().startOf('d').toDate(),
      PackingTime: moment().startOf('d').hours(8).toDate(),
      StartTime: moment().startOf('d').hours(9).toDate(),
      EndTime: moment().startOf('d').hours(16).toDate(),
      Appointments: 10,
      Draft: true,
      Terminal: false,
    }
  }

  public get StartLocationTracking(): Observable<string> {
    return this.startLocationTrackingSubject.asObservable();
  }

  public get StopLocationTracking(): Observable<void> {
    return this.stopLocationTrackingSubject.asObservable();
  }

  async split(timeSlot: TimeSlot): Promise<TimeSlot> {
    return this.api.post('admin/time-slot/split', timeSlot, 'Splitten erfolgreich');
  }

  async removeSplit(timeSlot: TimeSlot): Promise<TimeSlot> {
    return this.api.delete('admin/time-slot/split/' + timeSlot.Id, 'Entfernen erfolgreich');
  }

  async saveAppointmentSplit(timeSlot: TimeSlot): Promise<TimeSlot> {
    return this.api.post('admin/time-slot/split/appointments', timeSlot, 'Speichern erfolgreich');
  }

  async updateRoute(timeSlot: TimeSlot): Promise<String> {
    return this.api.post('admin/time-slot/route/' + timeSlot.Id, timeSlot, 'Speichern erfolgreich');
  }

  async publishTimeSlot(timeSlot: TimeSlot): Promise<TimeSlot> {
    return this.api.post('admin/time-slot/publish/' + timeSlot.Id, {});
  }

  async getAllOpen(): Promise<TimeSlot[]> {
    return this.api.get('admin/time-slot/open');
  }

  async moveAppointment(appointment: Appointment, timeSlotId: string): Promise<Appointment> {
    return this.api.post('admin/time-slot/move/appointment/' + timeSlotId, appointment, 'Speichern erfolgreich');
  }

  async getAllByTime(start: any, end: any, withGroups = false): Promise<TimeSlot[]> {
    const params = { Groups: withGroups };
    const startDate = moment(start).format('YYYY-MM-DD');
    const endDate = moment(end).format('YYYY-MM-DD');
    return this.api.get(`admin/time-slot/by-time/${startDate}/${endDate}`, params);
  }

  async getOpen(id: string): Promise<TimeSlot> {
    return this.api.get('admin/time-slot/open/' + id);
  }

  async getWithRoute(id: string): Promise<TimeSlot> {
    return this.api.get('admin/time-slot/with-route/' + id);
  }

  async getMy(): Promise<TimeSlot> {
    return this.api.get('admin/time-slot/my');
  }

  async startPacking(timeSlot: TimeSlot): Promise<TimeSlot> {
    return this.api.post('admin/time-slot/start/packing/' + timeSlot.Id, {});
  }

  async abortPacking(timeSlot: TimeSlot): Promise<TimeSlot> {
    const response = await this.api.post('admin/time-slot/abort/packing/' + timeSlot.Id, {});
    if (!!response) {
      await this.storageService.clearTimeSlotPacking(timeSlot.Id);
    }
    return response;
  }

  async finishPacking(timeSlot: TimeSlot): Promise<TimeSlot> {
    const response = await this.api.post('admin/time-slot/finish/packing/' + timeSlot.Id, {});
    if (!!response) {
      await this.storageService.clearTimeSlotPacking(timeSlot.Id);
    }
    return response;
  }

  async startDelivering(timeSlot: TimeSlot): Promise<TimeSlot> {
    const response = await this.api.post('admin/time-slot/start/delivering/' + timeSlot.Id, {});
    if (!!response) {
      this.startLocationTrackingSubject.next(response.Id);
    }
    return response;
  }

  async abortDelivering(timeSlot: TimeSlot): Promise<TimeSlot> {
    const response = await this.api.post('admin/time-slot/abort/delivering/' + timeSlot.Id, {});
    if (!!response) {
      this.stopLocationTrackingSubject.next();
    }
    return response;
  }

  async finishDelivering(timeSlot: TimeSlot): Promise<TimeSlot> {
    const response = await this.api.post('admin/time-slot/finish/delivering/' + timeSlot.Id, {});
    if (!!response) {
      this.stopLocationTrackingSubject.next();
    }
    return response;
  }

  async startDeliveringAppointment(appointment: Appointment): Promise<TimeSlot> {
    return this.api.post(`admin/time-slot/start/delivering/appointment/${appointment.Id}`, {});
  }

  async abortDeliveringAppointment(appointment: Appointment): Promise<TimeSlot> {
    return this.api.post(`admin/time-slot/abort/delivering/appointment/${appointment.Id}`, {});
  }

  async finishDeliveringAppointment(appointment: Appointment): Promise<TimeSlot> {
    return this.api.post(`admin/time-slot/finish/delivering/appointment/${appointment.Id}`, {});
  }

  async finishAppointment(appointment: Appointment): Promise<TimeSlot> {
    const response = await this.api.post(`admin/time-slot/finish/appointment`, appointment);
    if (!!response) {
      await this.orderStorageService.clearOrder();
    }
    return response;
  }

  async abort(id: string): Promise<TimeSlot> {
    return this.api.post(`admin/time-slot/abort/${id}`, {});
  }

  async getScheduleByTime(start: any, end: any, withGroups = true): Promise<TimeSlotSchedule> {
    const params = { Groups: withGroups };
    const startDate = moment(start).format('YYYY-MM-DD');
    const endDate = moment(end).format('YYYY-MM-DD');
    return this.api.get(`admin/time-slot/by-time/as-map/${startDate}/${endDate}`, params);
  }

  async getOrder(orderId: string): Promise<Order> {
    return this.api.get(`admin/time-slot/order/${orderId}`);
  }

  async get(id: string): Promise<TimeSlot> {
    return this.api.get('admin/time-slot/' + id);
  }

  async save(timeSlot: TimeSlot, successMessage = 'Speichern erfolgreich'): Promise<TimeSlot> {
    if (this.hasErrors(timeSlot)) {
      return null;
    }
    return this.api.post('admin/time-slot', timeSlot, successMessage);
  }

  public async saveLocation(timeSlotId: string, location: GeoLocation): Promise<any> {
    return this.api.post(`admin/time-slot/location/${timeSlotId}`, location);
  }

  private hasErrors(timeSlot: TimeSlot): boolean {
    const conditions = [
      {
        field: 'StartTime',
        name: 'Die End-Zeit muss nach der Start-Zeit liegen',
        condition: startTime => moment(startTime).isBefore(moment(timeSlot.EndTime))
      },
      {
        field: 'OrderTime',
        name: 'Der Bestelleingang muss vor der Start-Zeit liegen',
        condition: orderTime => moment(orderTime).isBefore(moment(timeSlot.StartTime))
      },
      {
        field: 'PackingTime',
        name: 'Die Packzeit muss vor der Start-Zeit liegen',
        condition: packaging => moment(packaging).isBefore(moment(timeSlot.StartTime))
      }
    ];
    /*
    if (timeSlot.LateOrder) {
      conditions.push(
        {
          field: 'LateOrderTime',
          name: 'Die Nachbestell-Zeit muss vor der Start-Zeit liegen',
          condition: lateOrderTime => moment(lateOrderTime).isBefore(moment(timeSlot.StartTime))
        }
      );
    }*/
    const notNegativeFields = [
      { field: 'DeliveryCost', name: 'Lieferkosten' },
      { field: 'MinimumOrderValue', name: 'Mindestbestellwert' }
    ];
    return this.validationService.hasConditionalErrors(timeSlot, conditions) ||  this.validationService.hasNegativeValues(timeSlot, notNegativeFields);
  }

  async delete(timeSlot: TimeSlot): Promise<TimeSlot> {
    if (!timeSlot?.Id) {
      return timeSlot;
    }
    return this.api.delete('admin/time-slot/' + timeSlot.Id, 'Löschen erfolgreich');
  }

  copyFromType(type: TimeSlotType, timeSlot: TimeSlot, refDate: any): TimeSlot {
    const date = moment(refDate).startOf('d');
    const start = moment(type.StartTime);
    const end = moment(type.EndTime);
    const startTime = moment(date).set({ hours: start.hours(), minutes: start.minutes() });
    const endTime = moment(date).set({ hours: end.hours(), minutes: end.minutes() });
    const packaging = moment(startTime).subtract(type.PackingTime || 0, 'minutes');
    const order = moment(startTime).subtract(type.OrderTime || 0, 'minutes');
    const lateOrder = moment(startTime).subtract(type.LateOrderTime || 0, 'minutes');
    const copy: TimeSlot = JSON.parse(JSON.stringify(timeSlot));
    copy.StartTime = startTime.toDate();
    copy.EndTime = endTime.toDate();
    copy.OrderTime = order.toDate();
    copy.PackingTime = packaging.toDate();
    copy.LateOrderTime = lateOrder.toDate();
    copy.Name = type.Name || timeSlot.Name;
    copy.LateOrder = type.LateOrder;
    copy.Appointments = type.Appointments;
    copy.Terminal = type.Terminal;
    copy.DeliveryCost = type.DeliveryCost;
    copy.MinimumOrderValue = type.MinimumOrderValue;
    copy.DeliveryAreaCircles = type.DeliveryAreaCircles?.length > 0 ? [...type.DeliveryAreaCircles] : [];
    return copy;
  }
}
