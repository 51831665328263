import {Injectable} from '@angular/core';
import {Provider, Role, Store, TerminalGroup, User} from '../../types/models';
import {ApiService} from '../global/api.service';
import {AdminStoreService} from './admin-store.service';
import {AuthStorageService} from '../storage/auth-storage.service';
import {DeliveryType, ProviderType, RoleType, UserType} from "../../types/enums";
import {ArrayService} from "../core/array.service";
import {GeoLocation} from "../../types/interfaces";

@Injectable({
  providedIn: 'root'
})
export class AdminUserService {
  cachedUser: User = null;

  constructor(
    private api: ApiService,
    private storageService: AuthStorageService,
    private storeService: AdminStoreService,
    private arrayService: ArrayService
  ) {
  }

  public static getEmptyUser(): User {
    return {
      FirstName: null,
      LastName: null,
      Mail: null,
      Type: UserType.PRIVATE,
      Phone: null,
      Provider: { Name: ProviderType.KIWI, Password: null }
    }
  }

  async getAllEmployeesByStore() {
    return this.api.get(`admin/user/by-store`);
  }

  getParams(filter?: string, offset?: number, limit?: number) {
    const params: any = {};
    if (offset != null) {
      params.offset = offset;
    }
    if (limit) {
      params.limit = limit;
    }
    if (filter) {
      params.filter = filter;
    }
    return params;
  }

  async get(id: string): Promise<User> {
    return this.api.get('admin/user/' + id);
  }

  async getAll(filter?: string, offset?: number, limit?: number): Promise<User[]> {
    return this.api.get(`admin/user/find`, this.getParams(filter, offset, limit));
  }

  async findAll(filter: string): Promise<User[]> {
    if (!filter || filter.length === 0) {
      return [];
    }
    return this.api.get(`admin/user/find/not-admin/${filter}`);
  }

  async getMyTask(): Promise<{ OrderId: string, TimeSlotId: string, TimeSlotGroupId: string }> {
    return this.api.get('admin/user/task/my')
  }

  public async saveTaskLocation(id: string, location: GeoLocation): Promise<any> {
    return this.api.post(`admin/user/task/location/${id}`, location);
  }

  async getMail(): Promise<string> {
    const user = await this.getMe();
    return user ? user.Mail : '';
  }

  async isAdmin(): Promise<boolean> {
    const user = await this.getMe();
    return user ? user.IsAdmin : false;
  }

  async isDataManager(): Promise<boolean> {
    const user = await this.getMe();
    if (!user) {
      return false;
    }
    return !!(user.Roles || []).find(r => r.Name === RoleType.DATA_MANAGER);
  }

  async isStoreManager(storeId?: string, exactMatch: boolean = false): Promise<boolean> {
    return this.hasRoleForStore(RoleType.STORE_MANAGER, storeId, exactMatch);
  }

  async isDriver(storeId?: string, exactMatch: boolean = false): Promise<boolean> {
    return this.hasRoleForStore(RoleType.DRIVER, storeId, exactMatch);
  }

  async isStockEmployee(storeId?: string, exactMatch: boolean = false): Promise<boolean> {
    return this.hasRoleForStore(RoleType.STOCK_EMPLOYEE, storeId, exactMatch);
  }

  public async hasRoleForStore(role: RoleType, storeId?: string, exactMatch: boolean = false): Promise<boolean> {
    const user = await this.getMe();
    if (!user) {
      return false;
    }
    if (user.IsAdmin && !exactMatch) {
      return true;
    }
    const hasRoleForStoreCondition = (r: Role) => r.Name === role && (!storeId || r.StoreId === storeId);
    return !!(user.Roles || []).find(r => hasRoleForStoreCondition(r));
  }

  public async getStoreId(): Promise<string> {
    return this.storageService.getEmployeeStoreId();
  }

  public async updateAndGetStoresByRoleAndTypeAndWarehousing(type: DeliveryType, warehousing: boolean, ...roles: RoleType[]): Promise<Store[]> {
    let storeId = await this.storageService.getEmployeeStoreId();
    const stores = await this.getStoresByRoles(roles, type, warehousing);
    const storeMap = this.arrayService.toMap(stores);
    const user = await this.getMe();
    if (!user) {
      return [];
    }
    if (storeId && !storeMap[storeId]) {
      await this.storageService.removeEmployeeStoreId();
    } else if (!storeId && user.IsAdmin && stores.length > 0) {
      storeId = stores[0].Id;
      await this.storageService.saveEmployeeStoreId(storeId);
    } else if (!storeId || !(await this.hasRolesForStore(roles, storeId))) {
      const roleMap = roles.reduce((a, v) => ({ ...a, [v]: v }), {});
      const role = (user.Roles || []).find(r => roleMap.hasOwnProperty(r.Name));
      storeId = role ? role.StoreId : null
      await this.storageService.saveEmployeeStoreId(storeId);
    }
    return stores;
  }

  public async updateAndGetStoresByRoleAndType(type: DeliveryType, ...roles: RoleType[]): Promise<Store[]> {
    return this.updateAndGetStoresByRoleAndTypeAndWarehousing(type, null, ...roles);
  }

  public async updateAndGetStoresByRole(...roles: RoleType[]): Promise<Store[]> {
    return this.updateAndGetStoresByRoleAndTypeAndWarehousing(null, null, ...roles);
  }

  private async hasRolesForStore(roles: RoleType[], storeId: string) {
    for (const role of roles) {
      if (await this.hasRoleForStore(role, storeId)) {
        return true;
      }
    }
    return false;
  }

  private async getStoresByRoles(roles: RoleType[], type?: DeliveryType, wareHousing: boolean = null): Promise<Store[]> {
    const apiCalls = [];
    for (const role of roles) {
      apiCalls.push(this.storeService.getAllByUserRole(role, type ? { Type: type } : null));
    }
    const responses: Store[][] = await Promise.all(apiCalls);
    if (!responses || responses.length === 0) {
      return [];
    }
    const storeMap = {};
    for (const stores of responses) {
      for (const store of stores) {
        if (wareHousing == null || store.Warehousing === wareHousing) {
          storeMap[store.Id] = store;
        }
      }
    }
    return Object.values(storeMap);
  }

  async getMe(): Promise<User> {
    if (this.cachedUser == null) {
      this.cachedUser = await this.api.get('admin/user/me');
    }
    return this.cachedUser;
  }

  clearCache() {
    this.cachedUser = undefined;
  }

  async isSelf(user: User): Promise<boolean> {
    if (!user) {
      return false;
    }
    const other = await this.getMe();
    if (!other) {
      return false;
    }
    return other.Id === user.Id;
  }

  async delete(user: User): Promise<User> {
    if (user.Id) {
      return this.api.delete('admin/user/' + user.Id, 'Löschen erfolgreich');
    }
    return user;
  }

  async updatePassword(userId: string, provider: Provider): Promise<Provider> {
    return this.api.post('admin/user/password/' + userId, provider, 'Passwort speichern erfolgreich');
  }

  async createOrUpdate(user: User): Promise<User> {
    if (user?.Id) {
      return this.api.post('admin/user/' + user.Id, user, 'Speichern erfolgreich');
    }
    return this.api.post('admin/user', user, 'Speichern erfolgreich');
  }

  async addTerminalGroup(user: User, terminalGroup: TerminalGroup): Promise<boolean> {
    return this.api.post('admin/user/terminal-group/add', { Id: user.Id, TerminalGroupId: terminalGroup.Id }, 'Hinzufügen erfolgreich');
  }

  async removeTerminalGroup(user: User, terminalGroup: TerminalGroup): Promise<boolean> {
    return this.api.post('admin/user/terminal-group/remove', { Id: user.Id, TerminalGroupId: terminalGroup.Id }, 'Entfernen erfolgreich');
  }
}
