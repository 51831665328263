import {Injectable} from '@angular/core';
import {Device} from "@capacitor/device";
import {Platform} from "@ionic/angular";
import {App} from '@capacitor/app';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  private platformName: string;
  private appId: string;
  private appVersion: any;

  private changeSubject = new Subject<void>();

  constructor(
    private platform: Platform
  ) {
  }

  public get Changed(): Observable<void> {
    return this.changeSubject.asObservable();
  }

  async init() {
    App.addListener('appStateChange', async status => {
      if (status.isActive === true) {
        return this.initPlatform();
      }
    });
    await this.initPlatform();
  }

  async initPlatform() {
    const info = await Device.getInfo();
    await this.platform.ready();
    this.platformName = info.platform;
    try {
      const appInfo = await App.getInfo();
      this.appId = this.isIos() ? 'net.kiwi-delivery.app' : appInfo.id;
      this.appVersion = appInfo.version;
    } catch (e) {
    }
    this.changeSubject.next();
  }

  public isIos(): boolean {
    return this.platformName === 'ios' || this.platformName === 'ipad' || this.platformName == 'iphone';
  }

  public isAndroid(): boolean {
    return this.platformName === 'android';
  }

  public isApp(): boolean {
    return !this.isBrowser();
  }

  public isBrowser(): boolean {
    return this.platformName === 'web';
  }

  public getName(): string {
    return this.platformName;
  }

  public getAppVersion() {
    return this.appVersion;
  }

  public getAppId(): string {
    return this.appId;
  }

  public getApiVersion(): string {
    return '2.2.0';
  }

  public async getOperatingSystem(): Promise<string> {
    const info = await Device.getInfo();
    return info.operatingSystem;
  }
}
