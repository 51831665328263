import { Pipe, PipeTransform } from '@angular/core';
import {DeliveryStateItem} from '../types/models';
import {DeliveryState} from "../types/enums";

@Pipe({
  name: 'deliveryState'
})
export class DeliveryStatePipe implements PipeTransform {

  deliveryStates = {
    [DeliveryState.INCOMING]: 'Bestellung eingegangen',
    [DeliveryState.PACKED]: 'Lieferung gepackt',
    [DeliveryState.DELIVERING]: 'Lieferung unterwegs',
    [DeliveryState.LOCATION]: 'Bestellung verfolgen',
    [DeliveryState.DELIVERED]: 'Bestellübergabe'
  };

  transform(deliveryState: DeliveryStateItem, ...args: unknown[]): string {
    return deliveryState ? this.deliveryStates[deliveryState.Key] || deliveryState.Key : '';
  }

}
