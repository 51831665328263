import { Injectable } from '@angular/core';
import {StorageService} from './storage.service';
import {
  AdminInventoryEditToggle, AdminOrderCorrectionAndReturnToggle,
  AdminOrderDetailToggle,
  AdminOrderEditToggle,
  AdminProductEditToggle,
  AdminRecipeEditToggle, AdminStoreEditToggle,
  AdminTerminalGroupEditToggle,
  AdminTimeSlotDetailToggle,
  AdminTimeSlotEditToggle,
  AdminTimeSlotGroupEditToggle,
  AdminTimeSlotSplitEditToggle,
  AdminTimeSlotTypeEditToggle,
  AdminUserEditToggle,
  CompetitionEditToggle,
  RevenueToggle
} from '../../types/toggles';
import {TimeMode} from "../../types/enums";

enum ToggleStorage {
  COMPETITION_EDIT = 'toggle-competition-edit',
  INVENTORY_EDIT = 'toggle-admin-inventory-edit',
  NEWS_VIEW = 'toggle-news-list-view-mode',
  ORDER_DETAIL = 'toggle-admin-order-detail',
  ORDER_EDIT = 'toggle-admin-order-edit',
  ORDER_CORRECTION_AND_RETURN = 'toggle-admin-order-correction-and-return',
  ORDER_LIST_MONTH = 'toggle-admin-order-list-month',
  ORDER_LIST_WEEK = 'toggle-admin-order-list-week',
  PRODUCT_EDIT = 'toggle-product-edit',
  RECIPE_EDIT = 'toggle-recipe-edit',
  QR_INVERT = 'toggle-qr-inverted',
  REVENUE = 'toggle-revenue',
  ROLE_STORE_ID = 'toggle-role-store-id',
  STATISTIC_MONTH = 'toggle-statistics-month',
  STATISTIC_YEAR = 'toggle-statistics-year',
  STORE_EDIT = 'toggle-admin-store-edit',
  TERMINAL_GROUP_EDIT = 'toggle-terminal-group-edit',
  TIMESLOT = 'toggle-time-slot',
  TIMESLOT_CREATE_YEAR = 'toggle-admin-time-slot-create-year',
  TIMESLOT_DETAIL = 'toggle-admin-time-slot-detail',
  TIMESLOT_EDIT = 'toggle-admin-time-slot-edit',
  TIMESLOT_GROUP_EDIT = 'toggle-admin-time-slot-group-edit',
  TIMESLOT_LIST = 'toggle-admin-time-slot-list',
  TIMESLOT_LIST_YEAR = 'toggle-admin-time-slot-list-year',
  TIMESLOT_LIST_VIEW = 'toggle-time-slot-list-view-mode',
  TIMESLOT_MONTH = 'toggle-time-slot-month',
  TIMESLOT_SPLIT_EDIT = 'toggle-admin-time-slot-split-edit',
  TIMESLOT_TYPE_EDIT = 'toggle-admin-time-slot-type-edit',
  TIMESLOT_WEEK = 'toggle-time-slot-week',
  USER_EDIT = 'toggle-admin-user-edit'
}

@Injectable({
  providedIn: 'root'
})
export class ToggleStorageService {

  constructor(private storageService: StorageService) { }

  async clear() {
    return this.storageService.remove(
      ToggleStorage.COMPETITION_EDIT,
      ToggleStorage.INVENTORY_EDIT,
      ToggleStorage.NEWS_VIEW,
      ToggleStorage.ORDER_DETAIL,
      ToggleStorage.ORDER_EDIT,
      ToggleStorage.ORDER_CORRECTION_AND_RETURN,
      ToggleStorage.ORDER_LIST_MONTH,
      ToggleStorage.ORDER_LIST_WEEK,
      ToggleStorage.PRODUCT_EDIT,
      ToggleStorage.RECIPE_EDIT,
      ToggleStorage.QR_INVERT,
      ToggleStorage.REVENUE,
      ToggleStorage.ROLE_STORE_ID,
      ToggleStorage.STATISTIC_MONTH,
      ToggleStorage.STATISTIC_YEAR,
      ToggleStorage.STORE_EDIT,
      ToggleStorage.TIMESLOT,
      ToggleStorage.TIMESLOT_CREATE_YEAR,
      ToggleStorage.TIMESLOT_DETAIL,
      ToggleStorage.TIMESLOT_EDIT,
      ToggleStorage.TIMESLOT_GROUP_EDIT,
      ToggleStorage.TIMESLOT_MONTH,
      ToggleStorage.TIMESLOT_LIST,
      ToggleStorage.TIMESLOT_LIST_YEAR,
      ToggleStorage.TIMESLOT_LIST_VIEW,
      ToggleStorage.TIMESLOT_SPLIT_EDIT,
      ToggleStorage.TIMESLOT_WEEK,
      ToggleStorage.USER_EDIT,
    )
  }

  async removeRoleStoreId() {
    return this.storageService.remove(ToggleStorage.ROLE_STORE_ID);
  }

  async saveRoleStoreId(id: string) {
    return this.storageService.save(ToggleStorage.ROLE_STORE_ID, id);
  }

  async getRoleStoreId(): Promise<string> {
    return this.storageService.get(ToggleStorage.ROLE_STORE_ID);
  }

  async setTimeSlotListViewMode(mode: string) {
    return this.storageService.save(ToggleStorage.TIMESLOT_LIST_VIEW, mode);
  }

  async getTimeSlotListViewMode(): Promise<string> {
    return this.storageService.get(ToggleStorage.TIMESLOT_LIST_VIEW);
  }

  async getInvertedQRCode(): Promise<boolean> {
    const value = await this.storageService.get(ToggleStorage.QR_INVERT);
    return value ? JSON.parse(value) : true;
  }

  async setInvertedQRCode(value: boolean) {
    return this.storageService.save(ToggleStorage.QR_INVERT, JSON.stringify(value));
  }

  async clearAdminOrderEditToggle() {
    return this.storageService.remove(ToggleStorage.ORDER_EDIT);
  }

  async saveAdminTimeSlotDetailToggle(key: string, value: boolean) {
    const obj = await this.getAdminTimeSlotDetailToggle();
    obj[key] = value;
    return this.setAdminTimeSlotDetailToggle(obj);
  }

  async getAdminTimeSlotDetailToggle(): Promise<AdminTimeSlotDetailToggle> {
    const value = await this.storageService.getObject(ToggleStorage.TIMESLOT_DETAIL);
    const defaultValue: AdminTimeSlotDetailToggle = {
      shoppingList: false,
      route: false,
      appointments: false,
      selectedAppointment: true,
      data: true,
      notes: false
    };
    return Object.assign({}, defaultValue, value || {});
  }

  async setAdminTimeSlotDetailToggle(toggle: AdminTimeSlotDetailToggle) {
    return this.storageService.saveObject(ToggleStorage.TIMESLOT_DETAIL, toggle);
  }

  async saveAdminTimeSlotEditToggle(key: string, value: boolean) {
    const obj = await this.getAdminTimeSlotEditToggle();
    obj[key] = value;
    return this.setAdminTimeSlotEditToggle(obj);
  }

  async getAdminTimeSlotEditToggle(): Promise<AdminTimeSlotEditToggle> {
    const value = await this.storageService.getObject(ToggleStorage.TIMESLOT_EDIT);
    const defaultValue: AdminTimeSlotEditToggle = {
      type: false,
      planning: false,
      deliveryAreas: false,
      data: true,
      children: false,
      appointments: false,
      price: false,
    };
    return Object.assign({}, defaultValue, value || {});
  }

  async setAdminTimeSlotEditToggle(toggle: AdminTimeSlotEditToggle) {
    return this.storageService.saveObject(ToggleStorage.TIMESLOT_EDIT, toggle);
  }

  async saveAdminOrderEditToggleKey(key: string, value: boolean) {
    const obj = await this.getAdminOrderEditToggle();
    obj[key] = value;
    return this.setAdminOrderEditToggle(obj);
  }

  async getAdminOrderEditToggle(): Promise<AdminOrderEditToggle> {
    const value = await this.storageService.getObject(ToggleStorage.TIMESLOT_EDIT);
    const defaultValue: AdminOrderEditToggle = {
      map: false,
      products: false
    };
    return Object.assign({}, defaultValue, value || {});
  }

  async getAdminOrderCorrectionAndReturnToggle(): Promise<AdminOrderCorrectionAndReturnToggle> {
    const value = await this.storageService.getObject(ToggleStorage.ORDER_CORRECTION_AND_RETURN);
    const defaultValue: AdminOrderCorrectionAndReturnToggle = {
      orders: false,
      summary: false
    };
    return Object.assign({}, defaultValue, value || {});
  }

  async setAdminOrderCorrectionAndReturnToggle(toggle: AdminOrderCorrectionAndReturnToggle) {
    return this.storageService.saveObject(ToggleStorage.ORDER_CORRECTION_AND_RETURN, toggle);
  }

  async setAdminOrderEditToggle(toggle: AdminOrderEditToggle) {
    return this.storageService.saveObject(ToggleStorage.TIMESLOT_EDIT, toggle);
  }

  async clearRevenue() {
    return this.storageService.remove(ToggleStorage.STATISTIC_YEAR, ToggleStorage.STATISTIC_MONTH, ToggleStorage.REVENUE);
  }

  async getSelectedTime(prefix: string, timeMode: TimeMode): Promise<string> {
    return this.storageService.get(`toggle-${prefix}-${timeMode}`);
  }

  async setSelectedTime(value, prefix: string, timeMode: TimeMode) {
    return this.storageService.save(`toggle-${prefix}-${timeMode}`, value);
  }

  async getSelectedTimeMode(prefix: string): Promise<TimeMode> {
    return this.storageService.get(`toggle-${prefix}`);
  }

  async setSelectedTimeMode(prefix: string, timeMode: TimeMode) {
    return this.storageService.save(`toggle-${prefix}`, timeMode);
  }

  async getRevenueToggle(): Promise<RevenueToggle> {
    const value = await this.storageService.getObject(ToggleStorage.REVENUE);
    const defaultValue:RevenueToggle =  {
      list: false,
      overview: true,
      graph: false,
      exports: false
    };
    return Object.assign({}, defaultValue, value || {});
  }

  async setRevenueToggle(toggle: RevenueToggle) {
    return this.storageService.saveObject(ToggleStorage.REVENUE, toggle);
  }

  async getCompetitionEditToggle(): Promise<CompetitionEditToggle> {
    const value = await this.storageService.getObject(ToggleStorage.COMPETITION_EDIT);
    const defaultValue: CompetitionEditToggle =  {
      baseData: true,
      terms: false,
      images: false,
      description: false,
      result: false
    };
    return Object.assign({}, defaultValue, value || {});
  }

  async setCompetitionEditToggle(toggle: CompetitionEditToggle) {
    return this.storageService.saveObject(ToggleStorage.COMPETITION_EDIT, toggle);
  }

  async getProductEditToggle(): Promise<AdminProductEditToggle> {
    const value = await this.storageService.getObject(ToggleStorage.PRODUCT_EDIT);
    const defaultValue: AdminProductEditToggle =  {
      baseData: true,
      info: false,
      nutritionScores: false,
      images: false,
      prices: false,
      unit: false,
      ingredients: false
    };
    return Object.assign({}, defaultValue, value || {});
  }

  async setProductEditToggle(toggle: AdminProductEditToggle) {
    return this.storageService.saveObject(ToggleStorage.PRODUCT_EDIT, toggle);
  }

  async getRecipeEditToggle(): Promise<AdminRecipeEditToggle> {
    const value = await this.storageService.getObject(ToggleStorage.RECIPE_EDIT);
    const defaultValue = {
      baseData: true,
      products: false,
      steps: false,
      images: false,
      ingredients: false
    };
    return Object.assign({}, defaultValue, value || {});
  }

  async setRecipeEditToggle(toggle: AdminRecipeEditToggle) {
    return this.storageService.saveObject(ToggleStorage.RECIPE_EDIT, toggle);
  }

  async saveAdminOrderDetailToggle(key: string, value: boolean) {
    const obj = await this.getAdminOrderDetailToggle();
    obj[key] = value;
    return this.setAdminOrderDetailToggle(obj);
  }

  async getAdminOrderDetailToggle(): Promise<AdminOrderDetailToggle> {
    const value = await this.storageService.getObject(ToggleStorage.ORDER_DETAIL);
    const defaultValue: AdminOrderDetailToggle = {
      products: false,
      code: false,
      data: true,
      appointment: false,
      actions: false
    };
    return Object.assign({}, defaultValue, value || {});
  }

  async setAdminOrderDetailToggle(toggle: AdminOrderDetailToggle) {
    return this.storageService.saveObject(ToggleStorage.ORDER_DETAIL, toggle);
  }

  async saveAdminTimeSloGroupEditToggle(key: string, value: boolean) {
    const obj = await this.getAdminTimeSlotGroupEditToggle();
    obj[key] = value;
    return this.setAdminTimeSlotGroupEditToggle(obj);
  }

  async getAdminTimeSlotGroupEditToggle(): Promise<AdminTimeSlotGroupEditToggle> {
    const value = await this.storageService.getObject(ToggleStorage.TIMESLOT_GROUP_EDIT);
    const defaultValue: AdminTimeSlotGroupEditToggle = {
      selection: true,
      data: false,
      timeSlots: false,
      planning: false
    };
    return Object.assign({}, defaultValue, value || {});
  }

  async setAdminTimeSlotGroupEditToggle(toggle: AdminTimeSlotGroupEditToggle) {
    return this.storageService.saveObject(ToggleStorage.TIMESLOT_GROUP_EDIT, toggle);
  }

  async saveAdminTimeSlotSplitEditToggle(key: string, value: boolean) {
    const obj = await this.getAdminTimeSlotSplitEditToggle();
    obj[key] = value;
    return this.setAdminTimeSlotSplitEditToggle(obj);
  }

  async getAdminTimeSlotSplitEditToggle(): Promise<AdminTimeSlotSplitEditToggle> {
    const value = await this.storageService.getObject(ToggleStorage.TIMESLOT_SPLIT_EDIT);
    const defaultValue: AdminTimeSlotSplitEditToggle = {
      appointments: false
    };
    return Object.assign({}, defaultValue, value || {});
  }

  async setAdminTimeSlotSplitEditToggle(toggle: AdminTimeSlotSplitEditToggle) {
    return this.storageService.saveObject(ToggleStorage.TIMESLOT_SPLIT_EDIT, toggle);
  }


  async getAdminInventoryEditToggle(): Promise<AdminInventoryEditToggle> {
    const value = await this.storageService.getObject(ToggleStorage.INVENTORY_EDIT);
    const defaultValue: AdminInventoryEditToggle = {
      data: true,
      stock: false,
      location: false,
      price: false
    };
    return Object.assign({}, defaultValue, value || {});
  }

  async setAdminInventoryEditToggle(toggle: AdminInventoryEditToggle) {
    return this.storageService.saveObject(ToggleStorage.INVENTORY_EDIT, toggle);
  }

  async getAdminUserEditToggle(): Promise<AdminUserEditToggle> {
    const value = await this.storageService.getObject(ToggleStorage.USER_EDIT);
    const defaultValue: AdminUserEditToggle = {
      data: true,
      deliveryAddress: false,
      billingAddress: false,
      actions: false,
      mandates: false,
      roles: false,
      orders: false,
      provider: false,
      terminals: false,
      paymentMethods: false
    };
    return Object.assign({}, defaultValue, value || {});
  }

  async setAdminUserEditToggle(toggle: AdminUserEditToggle) {
    return this.storageService.saveObject(ToggleStorage.USER_EDIT, toggle);
  }

  async getAdminTerminalGroupEditToggle(): Promise<AdminTerminalGroupEditToggle> {
    const value = await this.storageService.getObject(ToggleStorage.TERMINAL_GROUP_EDIT);
    const defaultValue: AdminTerminalGroupEditToggle = {
      users: false,
      terminals: false
    };
    return Object.assign({}, defaultValue, value || {});
  }

  async setAdminTerminalGroupEditToggle(toggle: AdminTerminalGroupEditToggle) {
    return this.storageService.saveObject(ToggleStorage.TERMINAL_GROUP_EDIT, toggle);
  }

  async getAdminTimeSlotTypeEditToggle(): Promise<AdminTimeSlotTypeEditToggle> {
    const value = await this.storageService.getObject(ToggleStorage.TIMESLOT_TYPE_EDIT);
    const defaultValue: AdminTimeSlotTypeEditToggle = {
      deliveryAreas: false,
      price: false,
      data: true,
    };
    return Object.assign({}, defaultValue, value || {});
  }

  async setAdminTimeSlotTypeEditToggle(toggle: AdminTimeSlotTypeEditToggle) {
    return this.storageService.saveObject(ToggleStorage.TIMESLOT_TYPE_EDIT, toggle);
  }

  async getAdminStoreEditToggle(): Promise<AdminStoreEditToggle> {
    const value = await this.storageService.getObject(ToggleStorage.STORE_EDIT);
    const defaultValue: AdminStoreEditToggle = {
      data: true,
      address: false,
      deliveryAreas: false,
      settings: false,
      map: false
    };
    return Object.assign({}, defaultValue, value || {});
  }

  async setAdminStoreEditToggle(toggle: AdminStoreEditToggle) {
    return this.storageService.saveObject(ToggleStorage.STORE_EDIT, toggle);
  }
}
