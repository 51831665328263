import { Injectable } from '@angular/core';
import {ApiService} from "../global/api.service";
import {ToastService} from "./toast.service";
import {AuthStorageService} from '../storage/auth-storage.service';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {HasImages} from '../../types/interfaces';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  public static readonly DEFAULT_AVATAR = 'assets/icons/kiwi-user.svg';
  public static readonly DEFAULT_PRODUCT = 'assets/logo_with_border.png';
  public static readonly DEFAULT_CATEGORY = 'assets/icons/kiwi-logo.svg';
  public static readonly DEFAULT_COMPETITION = 'assets/competition_placeholder.jpg';

  private imageNameCache = {};
  private isUploading = false;
  private http: HttpClient ;

  constructor(
    private api: ApiService,
    private storageService: AuthStorageService,
    private toastService: ToastService,
    handler: HttpBackend,
  ) {
    this.http = new HttpClient(handler);
  }

  public async loadTextFile(path: string): Promise<any> {
    try {
      return await this.http.get(path, { responseType: 'text'}).toPromise()
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  public async saveFiles (obj: HasImages, newImages: File[], type: string) {
    if (!newImages || newImages.length === 0) {
      return;
    }
    const images = obj.Images && obj.Images.length > 0 ? [...obj.Images] : [];
    for (const file of newImages) {
      const url = await this.post(file, type);
      if (url) {
        images.push(url);
      }
    }
    obj.Images = images;
  }

  public async saveFile<T extends {Image?: string}> (obj: T, newImage: File, type: string) {
    if (obj && newImage) {
      const url = await this.post(newImage, type);
      if (url) {
        obj.Image = url;
      }
    }
  }

  public async post(file: File, type: string): Promise<string> {
    try {
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      formData.append('Type', type);
      return this.upload(formData);
    } catch (error) {
      this.toastService.presentError(error);
      return null;
    }
  }

  private async upload(formData: FormData): Promise<string> {
    const apiKey = await this.storageService.getApiKey();
    const http = new XMLHttpRequest();
    return new Promise<string>((resolve, reject) => {
      http.open('POST', this.api.getUrl() + 'file');
      http.setRequestHeader('x-api-key', apiKey);
      http.onreadystatechange = () => {
        if (http.readyState === 4) {
          const url = (http.responseText || '').replace(/["]+/g, '');
          if (http.status === 200) {
            resolve(url);
          } else {
            reject(url);
          }
        }
      };
      http.send(formData);
    });
  }

  private getNameOfFile(fileUrl: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const client = new XMLHttpRequest();
      client.open('GET', fileUrl, true);
      client.send();

      client.onreadystatechange = function () {
        if (this.readyState === this.HEADERS_RECEIVED) {
          const contentType = client.getResponseHeader('content-disposition');
          resolve(contentType ? contentType.split('attachment; filename=')[1] : 'Datei');
          client.abort();
        }
      };
    });
  }

  public getFileName(FileUrl: string) {
    if (!this.isUploading && !this.imageNameCache[FileUrl]) {
      this.isUploading = true;
      this.getNameOfFile(FileUrl).then((name) => {
        this.imageNameCache[FileUrl] = name;
        this.isUploading = false;
      });
    }
    return this.imageNameCache[FileUrl] || 'Datei';
  }
}
