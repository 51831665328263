import {Injectable} from '@angular/core';
import {AuthService} from "./auth.service";
import {AuthStorageService} from "../storage/auth-storage.service";
import {Supplier} from "../../types/interfaces";
import {ModalController} from "@ionic/angular";
import {IdleModalPage} from "../../modals/idle-modal/idle-modal.page";

@Injectable({
  providedIn: 'root'
})
export class IdleService {

  listener: Supplier<void>;
  idleActivation = 1000 * 45;
  timeoutId: any;
  modalOpen: boolean;

  constructor(
    private authService: AuthService,
    private authStorage: AuthStorageService,
    private modalController: ModalController
  ) { }

  init() {
    this.authService.LoggedIn.subscribe(async () => this.start());
    this.authService.Logout.subscribe(async () => this.stop());
  }

  async start() {
    const isTerminal = await this.authStorage.isTerminal();
    this.stop();
    if (isTerminal) {
      this.updateTimer();
      const listener = this.getListener();
      window.addEventListener('click', listener);
      window.addEventListener('keydown', listener);
      this.listener = listener;
    }
  }

  stop() {
    if (this.listener) {
      window.removeEventListener('click', this.listener);
      window.removeEventListener('keydown', this.listener);
      this.listener = null;
    }
    this.clearTimer();
  }

  clearTimer() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  updateTimer() {
    this.clearTimer();
    this.timeoutId = setTimeout(async () => this.showModal(), this.idleActivation);
  }

  async showModal() {
    if (this.modalOpen) {
      return;
    }
    this.modalOpen = true;
    const modal = await this.modalController.create({
      component: IdleModalPage,
      cssClass: 'space-around height-75'
    });
    await modal.present();
    const response = await modal.onDidDismiss();
    this.modalOpen = false;
    if (!response.data || response.data.Restart) {
      this.updateTimer();
    }
  }

  getListener(): Supplier<void>{
    return () => this.updateTimer();
  }
}
