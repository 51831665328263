import { Pipe, PipeTransform } from '@angular/core';
import {PushSetting} from '../types/models';
import {AddressPipe} from './address.pipe';
import {PushSettingType} from "../types/enums";

@Pipe({
  name: 'pushSettings'
})
export class PushSettingsPipe implements PipeTransform {

  constructor(
    private addressPipe: AddressPipe
  ) {}

  transform(pushSetting: PushSetting, format?: string): string {
    const name = this.getName(pushSetting);
    const store = this.getStore(pushSetting);
    if (format === 'type') {
      return name;
    } else if (format === 'store') {
      return store;
    }
    return store ? name + ' für ' + store : name;
  }

  getStore(pushSetting: PushSetting): string {
    return pushSetting.Store ? pushSetting.Store.Name || this.addressPipe.transform(pushSetting.Store.Address) : '';
  }

  getName(pushSetting: PushSetting): string {
    if (pushSetting.Type === PushSettingType.COSTUMER_DELIVERY_STATE) {
      return 'Mein Lieferstatus';
    } else if (pushSetting.Type === PushSettingType.EMPLOYEE_DELIVERING) {
      return 'Offene Lieferaufträge';
    } else if (pushSetting.Type === PushSettingType.EMPLOYEE_LOW_INVENTORY) {
      return 'Niedriger Lagerbestand';
    } else if (pushSetting.Type === PushSettingType.EMPLOYEE_NEW_ORDER) {
      return 'Bestelleingang';
    } else if (pushSetting.Type === PushSettingType.EMPLOYEE_PACKING) {
      return 'Offen Packaufträge';
    }
    return pushSetting.Type;
  }

}
