import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { DeliveryType } from "../../types/enums";
import { DeliveryStorageService } from "../../services/storage/delivery-storage.service";
import { Address } from "../../types/models";
import { AuthStorageService } from "../../services/storage/auth-storage.service";
import { ToastService } from "../../services/core/toast.service";
import { AddressService } from "../../services/customer/address.service";
import { Subscription } from "rxjs";
import { AuthService } from "../../services/global/auth.service";

@Component({
  selector: 'app-delivery-type-select-modal',
  templateUrl: './delivery-type-select-modal.page.html',
  styleUrls: ['./delivery-type-select-modal.page.scss'],
})
export class DeliveryTypeSelectModalPage implements OnInit, OnDestroy {

  @Input() SelectedDeliveryType: DeliveryType = DeliveryType.APPOINTMENT;
  @Input() SelectedAddress: Address = {};
  @Input() ShowClose: boolean = true;
  @Input() AllowMissingStore: boolean = false;

  deliveryTypes: DeliveryType[] = [DeliveryType.APPOINTMENT]; //DeliveryType.INSTANT,
  initialDeliveryType: DeliveryType = DeliveryType.APPOINTMENT;
  initialAddress: Address = {};

  isGuest: boolean = true;
  isChip: boolean = false;
  addresses: Address[] = [];
  showAppointments: boolean = false;
  loaded: boolean = false;
  logoutSubscription: Subscription;
  stores = {
    [DeliveryType.APPOINTMENT]: { Type: DeliveryType.APPOINTMENT },
    // [DeliveryType.INSTANT]: { Type: DeliveryType.INSTANT },
  }

  constructor(
    private modalController: ModalController,
    private storageService: DeliveryStorageService,
    private authStorage: AuthStorageService,
    private toastService: ToastService,
    private deliveryStorage: DeliveryStorageService,
    private addressService: AddressService,
    private authService: AuthService
  ) {
  }

  async ngOnInit() {
    this.loaded = false;
    this.showAppointments = await this.deliveryStorage.showAppointments();
    this.initialDeliveryType = this.SelectedDeliveryType;
    this.initialAddress = this.SelectedAddress;
    this.isGuest = await this.authStorage.isGuest();
    this.isChip = await this.authStorage.isChip();
    this.addresses = await this.addressService.getAllDelivery();
    this.loaded = true;
    this.logoutSubscription = this.authService.Logout.subscribe(() => this.closeModal());
  }

  ngOnDestroy() {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }

  async setSelectedAddress(address: Address) {
    this.SelectedAddress = address;
    const store = await this.addressService.findStoreByAddress(address, this.SelectedDeliveryType);
    if (!store && !this.AllowMissingStore) {
      await this.toastService.presentWarning(this.getErrorMessage());
      return;
    } else if (this.AllowMissingStore || store) {
      await this.addressService.setSelectedAddressAndStore(this.SelectedAddress, store);
      await this.deliveryStorage.saveDeliveryType(this.SelectedDeliveryType);
    }
    return this.closeModal();
  }

  async closeWithoutAddressChange() {
    if (this.initialAddress?.Id === this.SelectedAddress?.Id && this.initialDeliveryType !== this.SelectedDeliveryType) {
      const store = await this.addressService.findStoreByAddress(this.SelectedAddress, this.SelectedDeliveryType);
      if (this.AllowMissingStore || store) {
        await this.addressService.setSelectedAddressAndStore(this.SelectedAddress, store);
        return this.closeModal();
      }
    }
    if (this.initialAddress?.Id === this.SelectedAddress?.Id && this.initialDeliveryType !== this.SelectedDeliveryType) {
      await this.toastService.presentWarning('Keine Adresse ausgewählt');
    }
    return this.closeModal();
  }

  closeModal(data?: any) {
    return this.modalController.dismiss(data);
  }

  async typeChanged() {
    this.addresses = await this.addressService.getAllDelivery({ DeliveryType: this.SelectedDeliveryType });
  }

  getAddressColor(address: Address) {
    if (address.hasOwnProperty('StoreId') && !address.StoreId) {
      return 'danger';
    } else if (address.Id === this.SelectedAddress?.Id) {
      return 'primary';
    }
    return null;
  }

  getErrorMessage() {
    return this.SelectedDeliveryType === DeliveryType.APPOINTMENT
      ? 'Terminlieferung ist für diese Adresse nicht möglich'
      : 'Sofortlieferung ist für diese Adresse nicht möglich';
  }

  async newAddress() {
    if (this.isGuest) {
      await this.toastService.presentError('Die Funktion ist für Gäste deaktiviert.');
      return this.closeModal();
    }
    await this.storageService.saveDeliveryType(this.initialDeliveryType);
    return this.closeModal({ Redirect: ['/profile', 'address-detail', 'create', 'select'] });
  }

  async editAddress(address: Address) {
    if (this.isGuest) {
      await this.toastService.presentError('Die Funktion ist für Gäste deaktiviert.');
      return this.closeModal();
    }
    return this.closeModal({ Redirect: ['/profile', 'address-detail', address.Id] });
  }
}
