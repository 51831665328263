import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common'

@Pipe({
  name: 'Price'
})
export class PricePipe implements PipeTransform {
  constructor(
    private decimalPipe: DecimalPipe
  ){}

  transform(value: number, decimals: number = 2, placeholder: string = null): string {
    if (value == null) {
      return placeholder;
    }
    const cents = value || 0;
    let transformed = this.decimalPipe.transform(cents / 100, '1.' + decimals + '-' + decimals);
    if (transformed) {
      transformed = transformed.replace('.', ',');
    } else {
      transformed = '0,00'
    }
    return transformed + ' €';
  }

}
