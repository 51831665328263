// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.version-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  height: 100%;
  gap: 24px;
}

.version-title {
  font-family: var(--font-primary);
  font-size: 24px;
  text-align: center;
  color: #fff;
}

.version-text {
  font-family: var(--font-secondary);
  text-align: center;
  font-size: 14px;
  color: #fff;
}
.version-text span {
  background-color: #fff;
  padding: 6px;
  border-radius: 4px;
}

.version-image {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 350px;
}

.fa-wifi {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/app-version-modal/app-version-modal.page.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,wBAAA;EACA,mBAAA;EACA,YAAA;EACA,SAAA;AAAF;;AAGA;EACE,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AAAF;;AAGA;EACE,kCAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;AAAF;AAEE;EACE,sBAAA;EACA,YAAA;EACA,kBAAA;AAAJ;;AAIA;EACE,OAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,UAAA;EACA,gBAAA;AADF;;AAIA;EACE,WAAA;AADF","sourcesContent":["\n.version-wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: stretch;\n  align-items: center;\n  height: 100%;\n  gap: 24px;\n}\n\n.version-title {\n  font-family: var(--font-primary);\n  font-size: 24px;\n  text-align: center;\n  color: #fff;\n}\n\n.version-text {\n  font-family: var(--font-secondary);\n  text-align: center;\n  font-size: 14px;\n  color: #fff;\n\n  span {\n    background-color: #fff;\n    padding: 6px;\n    border-radius: 4px;\n  }\n}\n\n.version-image {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 80%;\n  max-width: 350px;\n}\n\n.fa-wifi {\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
