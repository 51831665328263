export enum AddressType {
  DELIVERY = 'delivery',
  BILLING = 'billing'
}

export enum DeliveryState {
  INCOMING = 'incoming',
  PACKED = 'packed',
  DELIVERING = 'delivering',
  LOCATION = 'location',
  DELIVERED = 'delivered'
}

export enum DeliveryType {
  INSTANT = 'instant',
  APPOINTMENT = 'appointment'
}

export enum NewsType {
  USER_DELETE = 'user-delete',
  OPEN_BILLING = 'open-billing',
  CHARGEBACK_ERROR = 'chargeback-error',
}

export enum OrderState {
  OPEN = 'open',
  DONE = 'done'
}

export enum PaymentLogType {
  CHARGEBACK_ERROR = 'chargeback error',
  CHARGEBACK_SUCCESS = 'chargeback success'
}

export enum PaymentMethodType {
  APPLE_PAY = 'apple-pay',
  BILL = 'bill',
  CREDIT_CARD = 'credit-card',
  EC_PIN = 'ec-pin',
  EC_DELIVERY = 'ec-delivery',
  GOOGLE_PAY = 'google-pay',
  PAYPAL = 'paypal',
  CHECKOUT = 'checkout',
  SEPA = 'sepa-debit'
}

export enum PaymentServiceType {
  COMPUTOP = 'computop',
  SELF_SERVICE = 'self-service',
  STRIPE = 'stripe',
  SUMUP = 'sumup'
}

export enum PaymentStatus {
  OPEN = 'open',
  PENDING = 'pending',
  CANCEL = 'cancel',
  SUCCESS = 'success',
  ERROR = 'error'
}

export enum PrintingState {
  WAITING = 'waiting',
  GENERATING = 'generating',
  PRINTING = 'printing',
  PRINTED = 'printed'
}

export enum ProviderType {
  APPLE = 'apple',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  KIWI = 'kiwi',
  KIWI_CHIP = 'kiwi-chip'
}

export enum PushSettingType {
  COSTUMER_DELIVERY_STATE = 'costumer_delivery_state',
  EMPLOYEE_DELIVERING = 'employee_delivering',
  EMPLOYEE_NEW_ORDER = 'employee_new_order',
  EMPLOYEE_LOW_INVENTORY = 'employee_low_inventory',
  EMPLOYEE_PACKING = 'employee_packing'
}

export enum RoleType {
  ADMIN = 'admin',
  STORE_MANAGER = 'store_manager',
  STOCK_EMPLOYEE = 'stock_employee',
  DRIVER = 'driver_employee',
  DATA_MANAGER = 'data_manager'
}

export enum TimeSlotElementType {
  TIME_SLOT = 'time-slot',
  TIME_SLOT_GROUP = 'time-slot-group',
  TIME_SLOT_PARENT = 'time-slot-parent',
}

export enum TimeSlotState {
  OPEN = 'open',
  PACKING = 'packing',
  PACKED = 'packed',
  DELIVERING = 'delivering',
  DONE = 'done'
}

export enum TimeMode {
  WEEK = 'week', MONTH = 'month', YEAR = 'year'
}

export enum UserType {
  PRIVATE = 'private', CORPORATE = 'corporate'
}

export enum Weekday {
  MO = 'Mo', TU = 'Tu', WE = 'We', TH = 'Th', FR = 'Fr', SA = 'Sa', SU = 'Su'
}
