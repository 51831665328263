import {Pipe, PipeTransform} from '@angular/core';
import {DeliveryType} from "../types/enums";

@Pipe({
  name: 'deliveryType'
})
export class DeliveryTypePipe implements PipeTransform {

  types = {
    [DeliveryType.INSTANT]: 'Sofort',
    [DeliveryType.APPOINTMENT]: 'Termin',
    'none': '- ohne Einschränkung -'
  };

  typesLong = {
    [DeliveryType.INSTANT]: 'Sofort-Lieferung',
    [DeliveryType.APPOINTMENT]: 'Termin-Lieferung',
    'none': '- ohne Einschränkung -'
  };

  transform(value: string, long = false): unknown {
    const typTranslation = long ? this.typesLong : this.types;
    if (typTranslation.hasOwnProperty(value)) {
      return typTranslation[value];
    }
    return value;
  }

}
