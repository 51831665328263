import {Injectable, NgZone} from '@angular/core';
import {AuthService} from "./auth.service";
import {NavController} from "@ionic/angular";
import {UserService} from "../customer/user.service";
import {App, URLOpenListenerEvent} from "@capacitor/app";
import {Router} from "@angular/router";
import {AuthStorageService} from "../storage/auth-storage.service";

@Injectable({
  providedIn: 'root'
})
export class PageNavigationService {

  constructor(
    private authService: AuthService,
    private navController: NavController,
    private userService: UserService,
    private zone: NgZone,
    private router: Router,
    private authStorage: AuthStorageService
  ) {
  }

  public init() {
    this.deepLink();
    this.authService.Login.subscribe(async () => this.redirectToStart());
    this.authService.Logout.subscribe(async () => this.redirectToSignIn());
  }

  private async redirectToSignIn() {
    const params = await this.authStorage.getSignInMode();
    return this.navController.navigateRoot('/signin' + (params ? '/' + params : ''));
  }

  private async redirectToStart() {
    const isEmployee = await this.userService.hasRole();
    if (isEmployee) {
      return this.navController.navigateRoot('/admin');
    } else {
      return this.navController.navigateRoot('/product-category-list');
    }
  }

  deepLink() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split('.net').pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }
}
