import {Injectable} from '@angular/core';
import {StorageService} from "./storage.service";
import {DeliveryStorageService} from './delivery-storage.service';
import {CartProductItemStorage, CartRecipeStorage, CartProductStorage} from "../../types/storage";
import {Observable, Subject} from "rxjs";
import {TimeSlotService} from "../customer/time-slot.service";

enum CartStorage {
  CART = 'kiwi-cart',
  RECIPES = 'kiwi-cart-recipes',
  IN_SYNC = 'kiwi-cart-synchronized'
}

@Injectable({
  providedIn: 'root'
})
export class CartStorageService {

  oldKeys = ['cart-recipe', 'cart-product', 'cart-recipe-product', 'cart_recipe', 'cart_product', 'cart_recipe_product'];
  private changeSubject: Subject<void> = new Subject<void>();

  constructor(
    private storageService: StorageService,
    private deliversStorageService: DeliveryStorageService,
    private timeSlotService: TimeSlotService
  ) {
  }

  public get Change(): Observable<void> {
    return this.changeSubject.asObservable();
  }

  async saveProduct(cartProduct: CartProductItemStorage) {
    if (!cartProduct?.ProductId) {
      return;
    }
    const cart: CartProductStorage = await this.getProductCart();
    if (cartProduct.Amount > 0) {
      cart[cartProduct.ProductId] = cartProduct;
    } else {
      delete cart[cartProduct.ProductId];
    }
    await this.saveProductCart(cart);
  }

  async removeProduct(productId: string) {
    const cart: CartProductStorage = await this.getProductCart();
    delete cart[productId];
    await this.saveProductCart(cart);
  }

  async getProduct(productId: string): Promise<CartProductItemStorage> {
    const cart: CartProductStorage = await this.getProductCart();
    return cart[productId] || { ProductId: productId, Amount: 0 };
  }

  async getCart(): Promise<{ Products: CartProductStorage, Recipes: string[] }> {
    return {
      Products: await this.getProductCart(),
      Recipes: Object.keys(await this.getRecipeCart())
    };
  }

  async removeRecipe(id: string) {
    if (!id) {
      return;
    }
    const cart = await this.getRecipeCart();
    delete cart[id];
    return this.saveRecipeCart(cart);
  }

  async addRecipe(id: string) {
    if (!id) {
      return;
    }
    const cart = await this.getRecipeCart();
    cart[id] = id;
    return this.saveRecipeCart(cart);
  }

  async getRecipeCart(): Promise<CartRecipeStorage> {
    const recipes = await this.storageService.get(CartStorage.RECIPES);
    return recipes ? JSON.parse(recipes) : {};
  }

  async getProductCart(): Promise<CartProductStorage> {
    const products = await this.storageService.get(CartStorage.CART);
    return products ? JSON.parse(products) : {};
  }

  async saveProductCart(productCart: CartProductStorage) {
    const beforeChange = await this.getRaw();
    if (Object.keys(productCart).length > 0) {
      await this.storageService.save(CartStorage.CART, JSON.stringify(productCart));
    } else {
      await this.storageService.remove(CartStorage.CART);
    }
    const afterChange = await this.getRaw();
    await this.updateSynchronized(beforeChange, afterChange);
    this.changeSubject.next();
  }

  async saveRecipeCart(recipes: CartRecipeStorage) {
    const beforeChange = await this.getRaw();
    if (Object.keys(recipes).length > 0) {
      await this.storageService.save(CartStorage.RECIPES, JSON.stringify(recipes));
    } else {
      await this.storageService.remove(CartStorage.RECIPES);
    }
    const afterChange = await this.getRaw();
    await this.updateSynchronized(beforeChange, afterChange);
  }

  private async updateSynchronized(oldState: string, newState: string) {
    const state = oldState === newState;
    const previousSynchronized = await this.isSynchronized();
    await this.storageService.saveBoolean(CartStorage.IN_SYNC, state && previousSynchronized);
  }

  public async setSynchronized(flag: boolean) {
    await this.storageService.saveBoolean(CartStorage.IN_SYNC, flag);
  }

  public async isSynchronized(): Promise<boolean> {
    const value = await this.storageService.get(CartStorage.IN_SYNC);
    return value == null ? true : value === 'true';
  }

  private async getRaw() {
    const products = await this.storageService.get(CartStorage.CART);
    const recipes = await this.storageService.get(CartStorage.RECIPES);
    return products + ' ' + recipes;
  }

  async clearCart() {
    await this.timeSlotService.removeSelected();
    await this.deliversStorageService.clearDeliveryData();
    await this.clear();
    await this.setSynchronized(false);
  }

  async clear() {
    await this.storageService.remove(CartStorage.CART, CartStorage.RECIPES, CartStorage.IN_SYNC, ...this.oldKeys);
  }

  async isEmpty() {
    return !(await this.storageService.get(CartStorage.CART));
  }
}
