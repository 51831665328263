import { Injectable } from '@angular/core';
import {PlatformService} from "../core/platform.service";
import {SecureStoragePlugin} from "capacitor-secure-storage-plugin";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    protected platformService: PlatformService
  ) { }

  async saveObject(key: string, value: any) {
    return this.save(key, JSON.stringify(value));
  }

  async saveNumber(key: string, value: number) {
    return this.save(key, String(value));
  }

  async saveBoolean(key: string, value: boolean) {
    return this.save(key, String(value));
  }

  async save(key: string, value: any) {
    try {
      if (value) {
        if (this.isBrowser()) {
          localStorage.setItem(key, value);
        } else {
          await SecureStoragePlugin.set({ key, value });
        }
      } else {
        await this.remove(key);
      }
    } catch (e) {
    }
  }

  async getNumber(key: string): Promise<number> {
    const value = await this.get(key);
    if (value == null) {
      return null;
    }
    const numberValue = Number(value);
    return isNaN(numberValue) ? null : numberValue;
  }

  async getBoolean(key: string): Promise<boolean> {
    const value = await this.get(key);
    return value === 'true';
  }

  async getObject<Type>(key: string): Promise<Type> {
    const value = await this.get(key);
    return value ? JSON.parse(value) : null;
  }

  async get(key: string): Promise<any> {
    let value;
    if (this.isBrowser()) {
      value = localStorage.getItem(key);
    } else {
      try {
        const result = await SecureStoragePlugin.get({key});
        if (result) {
          value = result.value;
        }
      } catch (err) {
        value = null;
      }
    }
    if (value === 'null') {
      value = null;
    }
    return value;
  }

  async remove(...keys: string[]) {
    for (const key of keys) {
      await this.removeItem(key);
    }
  }

  async removeItem(key: string) {
    try {
      if (this.isBrowser()) {
        localStorage.removeItem(key);
      } else {
        await SecureStoragePlugin.remove({key});
      }
    } catch (e) {
    }
  }

  private isBrowser() {
    return this.platformService.isBrowser();
  }

}
