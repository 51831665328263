import { Injectable } from '@angular/core';
import {PlatformService} from './platform.service';
import { PushNotifications, ActionPerformed, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../global/api.service';
import {Router} from '@angular/router';
import {AuthStorageService} from '../storage/auth-storage.service';
import {ToastService} from './toast.service';
import {AuthService} from "../global/auth.service";

@Injectable({
  providedIn: 'root'
})
export class PushService {

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private router: Router,
    private toastService: ToastService,
    private platformService: PlatformService,
    private storageService: AuthStorageService,
    private authService: AuthService
  ) { }

  init() {
    this.authService.LoggedIn.subscribe(async () => this.start());
    this.authService.Logout.subscribe(async () => this.stop());
  }

  async start() {
    if (this.platformService.isApp()) {
      await this.registerPush();
      return PushNotifications.removeAllDeliveredNotifications();
    }
  }

  async stop() {
    if (this.platformService.isApp()) {
      await this.api.delete('user/push-token', '', false, false);
      await PushNotifications.removeAllListeners();
    }
  }

  async registerTokenDevice(token: string) {
    const deviceInfo = await Device.getId();
    return this.post('user/push-token', {
      Type: this.platformService.isAndroid() ? 'android' : 'ios',
      Token: token,
      DeviceInfo: deviceInfo,
    } );
  }

  private async post(path: string, body: any = {}): Promise<any> {
    try {
      return this.http.post(this.api.getUrl() + path, body).toPromise();
    } catch (e) {
      return null;
    }
  }

  private async registerPush() {
    await PushNotifications.removeAllListeners();

    PushNotifications.addListener('registration', async (token: Token) => {
      await this.registerTokenDevice(token.value);
      await PushNotifications.removeAllDeliveredNotifications();
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
      this.presentToast(notification);
      await PushNotifications.removeAllDeliveredNotifications();
    });

    PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
      const data = notification.notification.data;
      if (data.detailsTopic) {
        await PushNotifications.removeAllDeliveredNotifications();
        if (data.employeeStoreId) {
          await this.storageService.saveEmployeeStoreId(data.employeeStoreId);
        }
        const route = ['/', data.detailsTopic];
        if (data.detailsId) {
          route.push(data.detailsId);
        }
        await this.router.navigate(route);
      }
    });

    try {
      const permission = await PushNotifications.requestPermissions();
      if (permission.receive === 'granted') {
        await PushNotifications.register();
      }
    } catch (err) {
      console.log('Error:' + err);
    }
  }


  async presentToast(notification: any) {
    const buttons = [];
    if (notification && notification.data && notification.data.detailsId && notification.data.detailsTopic) {
      buttons.push({
        icon: 'arrow-forward-outline',
        handler: async () => {
          await PushNotifications.removeAllDeliveredNotifications();
          if (notification.data.employeeStoreId) {
            await this.storageService.saveEmployeeStoreId(notification.data.employeeStoreId);
          }
          await this.router.navigateByUrl(`/${notification.data.detailsTopic}/${notification.data.detailsId}`);
        },
      });
    }
    buttons.push({
      icon: 'close-outline',
      role: 'cancel',
      handler: async () => {
        await PushNotifications.removeAllDeliveredNotifications();
      },
    });
    const message = notification.title + '<br>' + notification.body;
    return this.toastService.presentSuccess(message, { duration: null, cssClass: 'push-toast-css', buttons});
  }
}
