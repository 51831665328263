import { Pipe, PipeTransform } from '@angular/core';
import {TimeSlotState} from "../types/enums";

@Pipe({
  name: 'timeSlotState'
})
export class TimeSlotStatePipe implements PipeTransform {

  timeslotSates = {
    [TimeSlotState.OPEN]: 'Offen',
    [TimeSlotState.PACKING]: 'Wird gepackt',
    [TimeSlotState.PACKED]: 'Gepackt',
    [TimeSlotState.DELIVERING]: 'Wird ausgeliefert',
    [TimeSlotState.DONE]: 'Abgeschlossen'
  };

  transform(value: string): string {
    return value ? this.timeslotSates[value] || value : value;
  }

}
