import { Injectable } from '@angular/core';
import {Address} from '../../types/models';
import {StorageService} from './storage.service';
import {GeoLocation} from "../../types/interfaces";
import {GeoLocationStorage} from "../../types/storage";

enum LocationStorage {
  ADDRESS = 'geo-location-by-address',
  LOCATION = 'geo-location'
}

@Injectable({
  providedIn: 'root'
})
export class LocationStorageService {

  readonly VALID_LOCATION = 2 * 60;
  readonly VALID_LOCATION_BY_ADDRESS = 5 * 60;

  constructor(
    private storageService: StorageService
  ) { }

  async clear() {
    return this.storageService.remove(LocationStorage.ADDRESS, LocationStorage.LOCATION);
  }

  async saveLocationByAddress(address: Address, location: GeoLocation) {
    if (!address.Zip || !address.Street || !address.Latitude || !address.Longitude) {
      return;
    }
    const map = await this.getAddressLocationMaps() || {};
    map[this.getAddressKey(address)] = { Latitude : location.Latitude, Longitude : location.Longitude, Time: Date.now() };
    return this.saveAddressLocationMaps(map);
  }

  async getLocationByAddress(address: Address): Promise<GeoLocation> {
    if (!address.Zip || !address.Street) {
      return;
    }
    const map = await this.getAddressLocationMaps() || {};
    const key = this.getAddressKey(address);
    const location = map[key];
    return location && this.isValid(location, this.VALID_LOCATION_BY_ADDRESS) ? location : null;
  }

  private async saveAddressLocationMaps(map: {[addressKey: string]: GeoLocationStorage}) {
    const newMap = {};
    for (const key in map) {
      if (this.isValid(map[key], this.VALID_LOCATION_BY_ADDRESS)) {
        newMap[key] = map[key];
      }
    }
    return this.storageService.save(LocationStorage.ADDRESS, JSON.stringify(newMap));
  }

  private async getAddressLocationMaps(): Promise<{[addressKey: string]: GeoLocationStorage}> {
    const value = await this.storageService.get(LocationStorage.ADDRESS);
    return value ? JSON.parse(value) : null;
  }

  async saveGeoLocation(geo: GeoLocationStorage) {
    geo.Time = Date.now();
    return this.storageService.save(LocationStorage.LOCATION, geo ? JSON.stringify(geo) : '');
  }

  async getGeoLocation(): Promise<GeoLocationStorage> {
    const location = await this.storageService.get(LocationStorage.LOCATION);
    if (!location)  {
      return null;
    }
    const parsedLocation = JSON.parse(location);
    return this.isValid(location, this.VALID_LOCATION) ? parsedLocation : null;
  }

  private getAddressKey(address: Address): string {
    let key = address.Zip + ' ' + address.City + ' ' + address.Street;
    return key.replace(/[\s.-]+/g, '_').toLowerCase();
  }

  private isValid(geoLocation: GeoLocationStorage, validSeconds: number = 120) {
    return Date.now() - geoLocation.Time <= validSeconds * 1000;
  }
}
