import { Injectable } from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class FormatService {

  constructor(protected decimalPipe: DecimalPipe) { }

  formatByWeight(amount: number): string {
    return this.formatBy(amount, 'g', 'kg');
  }

  formatByVolume(amount: number): string {
    return this.formatBy(amount, 'ml', 'l');
  }

  formatBy(_amount: number, _unit: string, altUnit: string): string {
    let amount = _amount;
    let unit = _unit;
    if (_amount >= 1000) {
      amount = _amount / 1000;
      unit = altUnit;
    }
    const decimal = (amount - Math.floor(amount));
    return (decimal === 0 ? amount : this.decimalPipe.transform(amount, '1.1-2', 'DE-de')) + ' ' + unit;
  }

  formatByPiece(amount: number, _displayPieceUnit?: boolean): string {
    const showUnit = _displayPieceUnit == null ? true : _displayPieceUnit;
    const number = Math.floor(amount);
    const rawDecimal = (amount - number) * 100;
    let decimal = 0;
    if (number < 10) {
      if (rawDecimal < 12.5) {
        decimal = 0;
      } else if (rawDecimal < 37.5) {
        decimal = 25;
      } else if (rawDecimal < 62.5) {
        decimal = 50;
      } else {
        decimal = 75;
      }
    }
    if (number === 0 && decimal === 0) {
      decimal = 25;
    }
    const formattedAmount = Number(number + '.' + decimal);
    return (decimal === 0 ? number : this.decimalPipe.transform(formattedAmount, '1.1-2', 'DE-de'))
      + (showUnit ? ' ' + 'Stk' : '');
  }
}
