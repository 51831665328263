import { Injectable } from '@angular/core';
import {ApiService} from "../global/api.service";
import {AuthStorageService} from "../storage/auth-storage.service";
import {Terminal} from "../../types/models";

@Injectable({
  providedIn: 'root'
})
export class TerminalService {

  constructor(
    private api: ApiService,
    private storage: AuthStorageService
  ) { }

  private async get(): Promise<Terminal> {
    const id = await this.storage.getTerminalId();
    if (!id) {
      return null;
    }
    return this.api.get('terminal/' + id);
  }

  async getOrCreate(): Promise<Terminal> {
    let terminal: Terminal = await this.get();
    if (terminal) {
      return terminal;
    }
    terminal = await this.api.post('terminal', {});
    if (terminal) {
      await this.storage.saveTerminalId(terminal.Id);
    }
    return terminal;
  }
}
