import { Pipe, PipeTransform } from '@angular/core';
import {Address} from "../types/models";

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(address: Address, ...args: unknown[]): string {
    if (!address) {
      return '';
    }
    let text = address.Street;
    if (address.Zip) {
      text += (text ? ', ' : '') + address.Zip;
    }
    if (address.City) {
      text += (text ? (address.Zip ? ' ' : ', ') : '') + address.City;
    }
    return text;
  }

}
