import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {PlatformService} from '../core/platform.service';
import {ModalController} from '@ionic/angular';
import {AppVersionModalPage} from '../../modals/app-version-modal/app-version-modal.page';
import {Subscription} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {

  modal: any;
  subscription: Subscription;

  constructor(
    private api: ApiService,
    private platformService: PlatformService,
    private modalController: ModalController
  ) { }

  init() {
    this.subscription = this.platformService.Changed.subscribe(async () => this.checkVersion());
  }

  async getApiVersion(): Promise<string> {
    return this.api.get('app-version/api');
  }

  async getStatusByVersion(version: string, os: string): Promise<number> {
    const params = { Code: version, Os: os};
    return this.api.get('app-version', params);
  }

  async checkVersion() {
    if (this.platformService.isBrowser()) {
      return;
    }
    const appVersion = this.platformService.getAppVersion();
    const operatingSystem = await this.platformService.getOperatingSystem();
    const status = await this.getStatusByVersion(appVersion, operatingSystem);
    if (!status || status === 426) {
      return this.openModal(operatingSystem);
    } else {
      return this.closeModal();
    }
  }

  async openModal(operatingSystem: string) {
    if (!this.modal) {
      this.modal = await this.modalController.create({
        component: AppVersionModalPage,
        cssClass: 'fullscreen-modal',
        componentProps: {
          Status: 426,
          OperatingSystem: operatingSystem
        },
        backdropDismiss: false,
      });

      return await this.modal.present();
    }
  }

  async closeModal() {
    if (this.modal) {
      await this.modal.dismiss();
      this.modal = null;
    }
  }
}
