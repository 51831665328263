import {Injectable} from '@angular/core';
import {ToastService} from "./toast.service";

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(
    private toastService: ToastService
  ) {
  }

  arrayHasErrors(obj: any[], fields: { field: string, name: string, fctName?: string }[]): boolean {
    const fieldsByFct = this.splitFieldsByFctName(fields);
    for (const item of obj) {
      for (const fctName in fieldsByFct) {
        const error = this[fctName](item, fieldsByFct[fctName]);
        if (error) {
          return error;
        }
      }
    }
    return null;
  }

  splitFieldsByFctName(fields: { field: string, name: string, fctName?: string }[]): { [fctName: string]: { field: string, name: string }[] } {
    const splitted: { [fctName: string]: { field: string, name: string }[] } = {};
    for (const field of fields) {
      const fieldFctName = field.fctName || 'hasEmptyFields';
      splitted[fieldFctName] = splitted[fieldFctName] || [];
      splitted[fieldFctName].push(field);
    }
    return splitted;
  }

  hasConditionalErrors(obj: any, fields: { field: string, name: string, condition: (field: any) => boolean }[], additionalErrors?: string): boolean {
    const errors = [];
    for (const field of fields) {
      const value = obj.hasOwnProperty(field.field) ? obj[field.field] : obj;
      if (!field.condition(value)) {
        errors.push(field);
      }
    }
    const error = errors.length > 0;
    if (error) {
      const message = (additionalErrors ? additionalErrors + '<br><br>' : '')
        + 'Das Objekt kann nicht gespeichert werden:<br>' + errors.map(f => f.name).join('<br>');
      this.toastService.presentError(message);
    } else if (additionalErrors) {
      this.toastService.presentError(additionalErrors);
    }
    return error || !!additionalErrors;
  }

  hasEmptyFields(object: any, fields: { field: string, name: string, mapFct?: (field: any) => any }[], additionalErrors?: string): boolean {
    const errors = [];
    for (const field of fields) {
      if (!field.mapFct && !object.hasOwnProperty(field.field)) {
        continue;
      }
      const value = field.mapFct ? field.mapFct(object)[field.field] : object[field.field];
      if (value === undefined || value == null || (value.hasOwnProperty('length') && value.length === 0)) {
        errors.push(field);
      }
    }
    const error = errors.length > 0;
    if (error) {
      const message = (additionalErrors ? additionalErrors + '<br><br>' : '')
        + 'Folgende Felder dürfen nicht leer sein:<br>' + errors.map(f => f.name).join('<br>');
      this.toastService.presentError(message);
    } else if (additionalErrors) {
      this.toastService.presentError(additionalErrors);
    }
    return error || !!additionalErrors;
  }

  hasNegativeValues(object: any, fields: { field: string, name: string, mapFct?: (field: any) => any }[]): boolean {
    const errors = [];
    for (const field of fields) {
      if (!field.mapFct && !object.hasOwnProperty(field.field)) {
        continue;
      }
      const value = field.mapFct ? field.mapFct(object)[field.field] : object[field.field];
      const number = +value;
      if (number < 0) {
        errors.push(field);
      }
    }
    const error = errors.length > 0;
    if (error) {
      const message = 'Folgende Felder dürfen keinen negativen Wert haben:<br>' + errors.map(f => f.name).join('<br>');
      this.toastService.presentError(message);
    }
    return error;
  }
}
