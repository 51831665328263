import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {KeyMap} from "../../types/interfaces";
import {OrderDepositReturn} from "../../types/models";

enum OrderStorage {
  RETURN = 'order-returns',
  AMOUNT = 'order-amounts',
  DEPOSIT_RETURN = 'order-deposit-returns',
  PACKING = 'order-packing',
  EDIT_FINISHED = 'order-edit-finished'
}

@Injectable({
  providedIn: 'root'
})
export class AdminOrderStorageService {

  constructor(
    private storageService: StorageService
  ) {
  }

  async saveEditFinishedByOrderId(orderId: string, flag: boolean) {
    const value = await this.getEditFinished();
    value[orderId] = flag;
    return this.storageService.saveObject(OrderStorage.EDIT_FINISHED, value);
  }

  async getEditFinishedByOrderId(orderId: string): Promise<boolean> {
    const value = await this.getEditFinished();
    return !!value[orderId];
  }

  async getEditFinished(): Promise<KeyMap<boolean>> {
    return (await this.storageService.getObject(OrderStorage.EDIT_FINISHED)) || {};
  }

  async saveReturnProduct(orderId: string, orderProductId: string, amount: number) {
    const value = await this.getAllReturnsByOrderId(orderId);
    value[orderProductId] = amount;
    return this.saveAllReturnsByOrderId(orderId, value);
  }

  async removeReturnProduct(orderId: string, orderProductId: string) {
    const value = await this.getAllReturnsByOrderId(orderId);
    delete value[orderProductId];
    return this.saveAllReturnsByOrderId(orderId, value);
  }

  async clearReturns() {
    return this.storageService.remove(OrderStorage.RETURN);
  }

  async clearReturnsByOrderId(orderId: string) {
    const returns = await this.getAllReturns();
    delete returns[orderId];
    return this.saveAllReturns(returns);
  }

  async clearOrder() {
    return this.storageService.remove(OrderStorage.RETURN, OrderStorage.DEPOSIT_RETURN, OrderStorage.EDIT_FINISHED, OrderStorage.AMOUNT);
  }

  async saveAllReturns(returns: KeyMap<KeyMap<number>>) {
    for (const orderId in returns) {
      const orderReturn = returns[orderId] || {};
      if (Object.keys(orderReturn).length === 0) {
        delete returns[orderId];
      }
    }
    if (Object.keys(returns).length === 0) {
      return this.storageService.remove(OrderStorage.RETURN);
    }
    return this.storageService.saveObject(OrderStorage.RETURN, returns);
  }

  async saveAllReturnsByOrderId(orderId: string, returns: KeyMap<number>) {
    if (!orderId) {
      return;
    }
    const allReturns = await this.getAllReturns();
    const keys = Object.keys(returns);
    for (const key of keys) {
      if (returns[key] === 0) {
        delete returns[key];
      }
    }
    allReturns[orderId] = returns;
    return this.saveAllReturns(allReturns);
  }

  async getAllReturns(): Promise<KeyMap<KeyMap<number>>> {
    const value: KeyMap<KeyMap<number>> = await this.storageService.getObject(OrderStorage.RETURN);
    return value || {};
  }

  async getAllReturnsByOrderId(orderId: string): Promise<KeyMap<number>> {
    if (!orderId) {
      return {};
    }
    const returns = await this.getAllReturns();
    return returns[orderId] || {};
  }

  async saveAmountCorrection(orderId: string, orderProductId: string, amount: number) {
    const value = await this.getAllAmountsByOrderId(orderId);
    value[orderProductId] = amount;
    return this.saveAllAmountsByOrderId(orderId, value);
  }

  async clearAmounts() {
    return this.storageService.remove(OrderStorage.AMOUNT);
  }

  async clearAmountsByOrderId(orderId: string) {
    const amounts = await this.getAllAmounts();
    delete amounts[orderId];
    return this.saveAllAmounts(amounts);
  }

  async getAllAmountsByOrderId(orderId: string): Promise<KeyMap<number>> {
    if (!orderId) {
      return {};
    }
    const amounts = await this.getAllAmounts();
    return amounts[orderId] || {};
  }

  async getAllAmounts(): Promise<KeyMap<KeyMap<number>>> {
    const value: KeyMap<KeyMap<number>> = await this.storageService.getObject(OrderStorage.AMOUNT);
    return value || {};
  }

  async saveAllAmountsByOrderId(orderId: string, amounts: KeyMap<number>) {
    if (!orderId) {
      return;
    }
    const allAmounts = await this.getAllAmounts();
    const keys = Object.keys(amounts);
    for (const key of keys) {
      if (amounts[key] === 0) {
        delete amounts[key];
      }
    }
    allAmounts[orderId] = amounts;
    return this.saveAllAmounts(allAmounts);
  }

  async saveAllAmounts(amounts: KeyMap<KeyMap<number>>) {
    for (const orderId in amounts) {
      const amount = amounts[orderId] || {};
      if (Object.keys(amount).length === 0) {
        delete amounts[orderId];
      }
    }
    if (Object.keys(amounts).length === 0) {
      return this.storageService.remove(OrderStorage.AMOUNT);
    }
    return this.storageService.saveObject(OrderStorage.AMOUNT, amounts);
  }

  async saveDepositReturn(depositReturn: OrderDepositReturn) {
    const value = await this.getAllDepositReturns();
    value[depositReturn.Key] = { Amount: depositReturn.Amount, PriceGross: depositReturn.PriceGross };
    return this.saveAllDepositReturns(value);
  }

  async removeDepositReturn(depositReturn: OrderDepositReturn) {
    const value = await this.getAllDepositReturns();
    delete value[depositReturn.Key];
    return this.saveAllDepositReturns(value);
  }

  async clearDepositReturns() {
    return this.storageService.remove(OrderStorage.DEPOSIT_RETURN);
  }

  getDepositReturnKey(deposit: OrderDepositReturn, index: number): string {
    return deposit.Id || `${String(index)}_${String(deposit.PriceGross)}`
  }

  async hasStoredDepositReturns(): Promise<boolean> {
    const value = await this.storageService.get(OrderStorage.DEPOSIT_RETURN);
    return !!value;
  }

  async getAllDepositReturns(): Promise<KeyMap<OrderDepositReturn>> {
    const value = await this.storageService.get(OrderStorage.DEPOSIT_RETURN);
    return value ? JSON.parse(value) : {};
  }

  async saveAllDepositReturns(returns: KeyMap<OrderDepositReturn>) {
    return this.storageService.save(OrderStorage.DEPOSIT_RETURN, JSON.stringify(returns));
  }

  public async savePacking(state: KeyMap<boolean>) {
    if (Object.keys(state).length > 0) {
      return this.storageService.save(OrderStorage.PACKING, JSON.stringify(state));
    } else {
      return this.clearPacking();
    }
  }

  public async getPacking(): Promise<KeyMap<boolean>> {
    const value = await this.storageService.get(OrderStorage.PACKING);
    return value ? JSON.parse(value) : {};
  }

  async clearPacking() {
    return this.storageService.remove(OrderStorage.PACKING, OrderStorage.RETURN, OrderStorage.AMOUNT);
  }

  async clear() {
    return this.storageService.remove(
      OrderStorage.PACKING,
      OrderStorage.DEPOSIT_RETURN,
      OrderStorage.RETURN,
      OrderStorage.AMOUNT,
      OrderStorage.EDIT_FINISHED
    );
  }

}
