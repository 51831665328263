import { Injectable } from '@angular/core';
import {StorageService} from "./storage.service";
import {KeyMap} from "../../types/interfaces";

enum TimeSlotStorage {
  PACKING = 'time-slot-packing'
}

@Injectable({
  providedIn: 'root'
})
export class AdminTimeSlotStorageService {

  constructor(private storageService: StorageService) { }

  async saveTimeSlotPacking(timeSlotId: string, state: KeyMap<KeyMap<boolean>>) {
    if (!timeSlotId) {
      return;
    }
    const value = await this.getPacking();
    value[timeSlotId] = state;
    return this.savePacking(value);
  }

  private async savePacking(state: KeyMap<KeyMap<KeyMap<boolean>>>) {
    const timeSlotIds = Object.keys(state);
    for (const timeSlotId of timeSlotIds) {
      if (Object.keys(state[timeSlotId]).length === 0) {
        delete state[timeSlotId];
      }
    }
    if (Object.keys(state).length > 0) {
      return this.storageService.save(TimeSlotStorage.PACKING, JSON.stringify(state));
    } else {
      return this.clearPacking();
    }
  }

  private async getPacking(): Promise<KeyMap<KeyMap<KeyMap<boolean>>>> {
    const value = await this.storageService.get(TimeSlotStorage.PACKING);
    return value ? JSON.parse(value) : {};
  }

  async getTimeSlotPacking(id: string): Promise< KeyMap<KeyMap<boolean>>> {
    if (!id) {
      return {};
    }
    const value = await this.getPacking();
    return value[id] || {};
  }

  async clearPacking() {
    return this.storageService.remove(TimeSlotStorage.PACKING);
  }

  async clearTimeSlotPacking(timeSlotId: string) {
    const value = await this.getPacking();
    value[timeSlotId] = {};
    return this.savePacking(value);
  }

  async clear() {
    return this.storageService.remove(TimeSlotStorage.PACKING);
  }
}
