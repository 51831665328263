import { Pipe, PipeTransform } from '@angular/core';
import {RecipeProduct} from '../types/models';
import {FormatService} from '../services/core/format.service';

@Pipe({
  name: 'recipeProductAmount'
})
export class RecipeProductAmountPipe implements PipeTransform {

  constructor(protected formatService: FormatService) {
  }

  transform(recipeProduct: RecipeProduct, recipeNumPersons?: number, numPersons?: number, displayPieceUnit?: boolean): string {
    if (!recipeProduct.Product) {
      return '';
    } else if (!recipeProduct.Product.Unit) {
      return recipeProduct.Amount ? String(recipeProduct.Amount) : '';
    } else if (recipeProduct.Amount == null) {
      return recipeProduct.Product.Unit
    }
    return this.formatRecipeProduct(recipeProduct, recipeNumPersons, numPersons, displayPieceUnit);
  }

  formatRecipeProduct(recipeProduct: RecipeProduct, recipeNumPersons?: number, numPersons?: number, displayPieceUnit?: boolean): string {
    const amount = this.getAmount(recipeProduct, recipeNumPersons, numPersons);
    if (recipeProduct.Product.Unit === 'Stk') {
      return this.formatService.formatByPiece(amount, displayPieceUnit);
    } else if (recipeProduct.Product.Unit === 'g') {
      return this.formatService.formatByWeight(amount);
    } else if (recipeProduct.Product.Unit === 'ml') {
      return this.formatService.formatByVolume(amount);
    }
    return amount + ' ' + recipeProduct.Product.Unit;
  }

  getAmount(recipeProduct: RecipeProduct, recipeNumPersons?: number, numPersons?: number): number {
    if (numPersons == null || numPersons === 0 || recipeNumPersons == null || recipeNumPersons === 0) {
      return recipeProduct.Amount;
    }
    const amountPerPerson = recipeProduct.Amount / recipeNumPersons;
    return amountPerPerson * numPersons;
  }

}
