import {Pipe, PipeTransform} from '@angular/core';
import {TimeMode} from "../types/enums";

@Pipe({
  name: 'timeMode'
})
export class TimeModePipe implements PipeTransform {

  transform(timeMode: TimeMode): unknown {
    if (timeMode === TimeMode.WEEK) {
      return 'Woche';
    } else if (timeMode === TimeMode.MONTH) {
      return 'Monat';
    } else if (timeMode === TimeMode.YEAR) {
      return 'Jahr';
    }
    return timeMode;
  }

}
