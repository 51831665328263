import { Pipe, PipeTransform } from '@angular/core';
import {FormatService} from '../services/core/format.service';
import {Product} from '../types/models';

@Pipe({
  name: 'productAmount'
})
export class ProductAmountPipe implements PipeTransform {

  constructor(protected formatService: FormatService) {
  }

  transform(product: Product): string {
    if (!product) {
      return '';
    } else if (!product.Unit) {
      return product.Amount ? String(product.Amount) : '';
    } else if (product.Amount == null) {
      return product.Unit
    }
    return this.formatProduct(product);
  }

  formatProduct(product: Product): string {
    if (product.Unit === 'Stk') {
      return this.formatService.formatByPiece(product.Amount);
    } else if (product.Unit === 'g') {
      return this.formatService.formatByWeight(product.Amount);
    } else if (product.Unit === 'ml') {
      return this.formatService.formatByVolume(product.Amount);
    }
    return product.Amount + ' ' + product.Unit;
  }

}
