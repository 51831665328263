import {Component} from '@angular/core';
import {ButtonConfig} from "../../types/io";
import {ModalController} from "@ionic/angular";
import {AuthService} from "../../services/global/auth.service";
import {CountdownConfig, CountdownEvent} from "ngx-countdown";

@Component({
  selector: 'app-idle-modal',
  templateUrl: './idle-modal.page.html',
  styleUrls: ['./idle-modal.page.scss'],
})
export class IdleModalPage {

  buttonConfig: ButtonConfig = {
    logout: { Text: 'Jetzt abmelden', Color: 'danger' },
    continue: { Text: 'Angemeldet bleiben' }
  };
  countDownConfig: CountdownConfig;

  constructor(
    private authService: AuthService,
    private modalController: ModalController
  ) {
  }

  ngOnInit() {
    this.countDownConfig = {
      leftTime: 30,
      formatDate: ({ date }) => `${date / 1000}`,
    };
  }

  async close(restart: boolean = true) {
    return this.modalController.dismiss({ Restart: restart });
  }

  async logout() {
    await this.authService.logout();
    return this.close(false);
  }

  async continue() {
    return this.close();
  }

  callMethod(methodName: string) {
    this[methodName]();
  }

  async handleEvent(event: CountdownEvent) {
    if (event.action === 'done') {
      return this.logout();
    }
  }
}
