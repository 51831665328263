import {Injectable} from '@angular/core';
import {Address, User, UserDeleteRequest} from '../../types/models';
import {ApiService} from '../global/api.service';
import {StoreService} from './store.service';
import {FileService} from '../core/file.service';
import {AdminUserService} from '../admin/admin-user.service';
import {DeliveryStorageService} from '../storage/delivery-storage.service';
import {UserType} from "../../types/enums";

@Injectable({
  providedIn: 'root',
})
export class UserService {

  cachedRoleFlag: boolean = null;

  constructor(
    private api: ApiService,
    private storageService: DeliveryStorageService,
    private storeService: StoreService,
    private fileService: FileService,
    private adminUserService: AdminUserService,
  ) {
  }

  public async get(): Promise<User> {
    const user: User = await this.api.get('user');
    if (user) {
      user.Type = user.Type || UserType.PRIVATE;
    }
    return user;
  }

  public async save(user: User, newImage: File = undefined): Promise<User> {
    if (newImage) {
      await this.fileService.saveFile(user, newImage, 'User');
    }
    return this.api.post('user', user);
  }

  public async requestDelete(message?: string): Promise<boolean> {
    return this.api.post('user/request-delete', message ? { Message: message } : {});
  }

  public async getDeleteRequest(): Promise<UserDeleteRequest> {
    return this.api.get('user/request-delete');
  }

  public async create(Mail: string): Promise<User> {
    return this.save({ Mail }, null);
  }

  public isComplete(user: User): boolean {
    const firstName = this.nameValidation(user?.FirstName);
    const lastName = this.nameValidation(user?.LastName);
    const mail = this.mailValidation(user?.Mail);
    const phone = this.phoneValidation(user?.Phone);
    return firstName.Valid && lastName.Valid && mail.Valid && phone.Valid;
  }

  public phoneValidation(input: string): { Valid: boolean, Messages: string[]} {
    const phone = (input || '').trim();
    const messages = [];
    const length = phone.length > 4;
    let valid = true;
    if (!length) {
      messages.push('Die Nummer ist zu kurz');
      valid = false;
    }
    if (/(\p{L})+$/gu.test(phone)) {
      messages.push('Die Nummer enthält nicht erlaubte Zeichen');
      valid = false
    }
    return { Valid: valid, Messages: messages };
  }

  public mailValidation(input: string): { Valid: boolean, Messages: string[]} {
      const mail = (input || '').trim();
      const length = mail.length;
      const at = mail.indexOf('@');
      const domainSeparator = mail.indexOf('.', at);
      const domain = mail.substring(domainSeparator + 1);
      const valid = length >= 6 && at > 0 && domainSeparator > 0 && domain.length > 0;
      return { Valid: valid, Messages: valid ? [] : ['Keine gültige E-Mail-Adresse']};
  }

  public nameValidation(input: string): { Valid: boolean, Messages: string[] } {
    const messages = [];
    let valid = true;
    const name = (input || '').trim();
    const lengthCheck = name.length >= 2;
    if (lengthCheck) {
      const start = name.substring(0, 2);
      const letterRegex = /^(\p{L}){2}$/gu;
      const nameRegex = /^(\p{L}|\s|[.-])+$/gu;
      const startIsValid = letterRegex.test(start);
      const nameIsValid = nameRegex.test(name);
      if (!startIsValid) {
        messages.push('Der Name muss mit mindestens zwei Buchstaben beginnen');
        valid = false;
      }
      if (!nameIsValid) {
        messages.push('Der Name enthält nicht erlaubte Zeichen. Es sind nur Buchstaben sowie . und - erlaubt.');
        valid = false;
      }
    } else {
      valid = false;
      messages.push('Der Name ist zu kurz');
    }
    return { Valid: valid, Messages: messages };
  }

  async hasRole(): Promise<boolean> {
    if (this.cachedRoleFlag == null) {
      this.cachedRoleFlag = await this.api.get('user/has-role');
    }
    return this.cachedRoleFlag;
  }

  clearCache() {
    this.cachedRoleFlag = null;
    this.adminUserService.clearCache();
  }
}
