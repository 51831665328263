import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, Subject} from "rxjs";
import {ToastService} from "../core/toast.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private subject: Subject<void> = new Subject<void>();
  private api: string;
  private cachedError = {
    POST: {},
    GET: {},
    DELETE: {}
  };
  private localPort = ':420';
  private devPort = ':421';
  private masterPort = ':422';
  private devTerminalKey = '371c2aea-5ad4-498f-a79c-e211485a5107';
  private masterTerminalKey = '17d893a1-90c2-470d-ae35-a1e3dd9c8bee';
  private devApi = 'https://dev.api.kiwi-germany.com/';
  private masterApi = 'https://api.kiwi-delivery.net/';

  constructor(
    private http: HttpClient,
    private toastService: ToastService
  ) {
    this.setUrl();
  }

  setUrl() {
    try {
      if (location.href.indexOf(this.localPort) !== -1) {
        this.api = 'http://localhost:3000/';
      } else if (location.href.indexOf('dev.') !== -1 || location.href.indexOf(this.devPort) !== -1) {
        this.api = this.devApi;
      } else {
        this.api = this.masterApi;
      }
      return 'done';
    } catch (error) {
      console.log(error);
    }
  }

  isLocalhost(): boolean {
    return location.href.indexOf('localhost:') !== -1;
  }

  getTerminalKey()  {
    return this.api === this.masterApi ? this.masterTerminalKey : this.devTerminalKey;
  }

  isTerminal(): boolean {
    return location.href.indexOf('terminal.') !== -1
      || location.href.indexOf(this.localPort + '1') !== -1
      || location.href.indexOf(this.devPort + '1') !== -1
      || location.href.indexOf(this.masterPort + '1') !== -1
  }

  getUrl() {
    return this.api;
  }

  public get Unauthorized(): Observable<void>  {
    return this.subject.asObservable();
  }

  public getErrorMessage(type: string, path: string): any {
    return this.cachedError[type]?.[path];
  }

  public async get(path: string, queryParams: {} = {}, disableWarning = true, successMessage: string = '', logout: boolean = true): Promise<any> {
    try {
      const response = await this.http.get(this.api + path, { params: queryParams, observe: 'response' } ).toPromise();
      await this.toastService.presentSuccess(successMessage);
      return response.body;
    } catch (e) {
      if (logout && e.status === 401) {
        this.subject.next();
      } else if (!disableWarning) {
        this.toastService.presentWarning(e);
      }
      this.cachedError.GET[path] = e.error;
      return null;
    }
  }

  public async postParams(path: string, object: any, params: any = null, successMessage: string = '', showErrorMessage: boolean = true, logout: boolean = true): Promise<any> {
    try {
      const responseObject = await this.http.post(this.api + path, object, { params: params }).toPromise();
      await this.toastService.presentSuccess(successMessage);
      return responseObject;
    } catch (e) {
      if (logout && e.status === 401) {
        this.subject.next();
      } else if (e.status === 423 && showErrorMessage)  {
        await this.toastService.presentError(e.error || 'Die Funktion ist für dich deaktiviert.');
      } else if (showErrorMessage) {
        await this.toastService.presentError(e.error || 'Es wurden nicht genügend Angaben gemacht');
      }
      this.cachedError.POST[path] = e.error;
      return null;
    }
  }

  public async post(path: string, object: any, successMessage: string = '', showErrorMessage: boolean = true, logout: boolean = true): Promise<any> {
    return this.postParams(path, object, null, successMessage, showErrorMessage, logout);
  }

  public async delete(path: string, successMessage: string = '', showErrorMessage: boolean = true, logout: boolean = true): Promise<any>  {
    return this.deleteParams(path, null, successMessage, showErrorMessage, logout);
  }

  public async deleteParams(path: string, params: any = null, successMessage: string = '', showErrorMessage: boolean = true, logout: boolean = true): Promise<any>  {
    try {
      const responseObject = await this.http.delete(this.api + path, { params: params }).toPromise();
      await this.toastService.presentSuccess(successMessage);
      return responseObject;
    } catch (e) {
      if (logout && e.status === 401) {
        this.subject.next();
      } else if (e.status !== 423 && showErrorMessage) {
        await this.toastService.presentError(e.error);
      }
      this.cachedError.DELETE[path] = e.error;
      return null;
    }
  }

  getFileBlob(path: string, params: any = {}): Promise<Blob> {
    return this.http.get(this.api + path, { responseType: 'blob', params: params }).toPromise();
  }

  getFileText(Path: string, Params: any = {}): Promise<string> {
    return this.http.get(this.api + Path, { responseType: "text", params: Params }).toPromise();
  }

}
