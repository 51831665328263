import {Injectable} from '@angular/core';
import {ApiService} from "../global/api.service";
import {Address, Store} from "../../types/models";
import {DeliveryStorageService} from "../storage/delivery-storage.service";
import {LocationService} from "../core/location.service";
import {StoreService} from "./store.service";
import {DeliveryType} from "../../types/enums";
import {Observable, Subject} from "rxjs";
import {TimeSlotService} from "./time-slot.service";

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  private addressChange: Subject<void> = new Subject<void>();
  private storeChange: Subject<void> = new Subject<void>();

  constructor(
    private api: ApiService,
    private storageService: DeliveryStorageService,
    private locationService: LocationService,
    private storeService: StoreService,
    private timeSlotService: TimeSlotService
  ) {
  }

  public get Address(): Observable<void> {
    return this.addressChange.asObservable();
  }

  public get Store(): Observable<void> {
    return this.storeChange.asObservable();
  }

  emitAddressChange() {
    this.addressChange.next();
  }

  emitStoreChange() {
    this.storeChange.next();
  }

  async getDeliveryTime(address: Address): Promise<number> {
    if (!address?.Id) {
      return null;
    }
    return this.api.get(`address/delivery-time/${address.Id}`);
  }

  public async saveBilling(address: Address): Promise<Address> {
    return this.api.post('address/billing', address);
  }

  private async postDelivery(address: Address): Promise<Address> {
    return this.api.post('address/delivery', address, '', false);
  }

  public async saveDelivery(address: Address): Promise<{ Success: boolean, Message: any, Address: Address }> {
    const response = await this.locationService.search(address);
    if (response) {
      address.Longitude = response.Longitude;
      address.Latitude = response.Latitude;
    } else {
      address.Longitude = null;
      address.Latitude = null;
    }
    const success = await this.postDelivery(address);
    if (success) {
      await this.getSelectedDeliveryAddressAndUpdateStore();
    }
    return {
      Success: !!success,
      Message: this.api.getErrorMessage('POST', 'address/delivery'),
      Address: success
    };
  }

  public async getAll(): Promise<Address[]> {
    return this.api.get('address');
  }

  public async getAllBilling(): Promise<Address[]> {
    return this.api.get('address/billing');
  }

  public async getAllDelivery(query: any = {}): Promise<Address[]> {
    return this.api.get('address/delivery', query);
  }

  public async getDelivery(id?: string): Promise<Address> {
    const addressId = id || await this.storageService.getDeliveryAddressId();
    if (!addressId) {
      return null;
    }
    return this.api.get(`address/delivery/${addressId}`);
  }

  public async getBilling(id: string): Promise<Address> {
    if (!id) {
      return null;
    }
    return this.api.get(`address/billing/${id}`);
  }

  private async deleteAddress(id: string): Promise<Address> {
    if (!id) {
      return null;
    }
    return this.api.delete(`address/${id}`);
  }

  public async deleteBilling(id: string): Promise<Address> {
    return this.deleteAddress(id);
  }

  public async deleteDelivery(id: string) {
    if ((await this.storageService.getDeliveryAddressId()) === id) {
      await this.storageService.removeDeliveryAddressId();
      await this.storageService.removeStoreId();
    }
    return this.deleteAddress(id);
  }

  private async getPrimaryDelivery(): Promise<Address> {
    return this.api.get('address/delivery/primary');
  }

  public async getSelectedDeliveryAddressAndUpdateStore(forceEmit: boolean = false): Promise<Address> {
    const id = await this.storageService.getDeliveryAddressId();
    let address: Address;
    if (!id) {
      address = await this.getPrimaryDelivery();
    } else {
      address = await this.getDelivery(id);
    }
    if (id && !address) {
      address = await this.getPrimaryDelivery();
    }
    if (address) {
      await this.setSelectedDeliveryAddressAndFindStore(address, true, forceEmit);
      const deliveryAddressId = await this.storageService.getDeliveryAddressId();
      if (!deliveryAddressId) {
        address = null;
      }
    } else {
      await this.setSelectedAddressAndStore(address, null, forceEmit);
    }
    return address;
  }

  public async removeStoreFromStorage() {
    await this.storageService.removeStoreId();
    await this.storageService.removeIsOpen();
    await this.storageService.removeStoreMinimumOrderValue();
    await this.storageService.removeDeliveryTime();
    await this.storageService.saveDeliveryType(DeliveryType.APPOINTMENT);
    await this.storageService.removeStoreDeliveryCost();
  }

  public async findStoreByAddress(address: Address, deliveryType?: DeliveryType) {
    const storeParams = deliveryType ? { DeliveryType: deliveryType } : {};
    return this.storeService.find(address?.Id, storeParams);
  }

  public async setSelectedAddressAndStore(address: Address, store: Store, forceEmit: boolean = false) {
    const previousStoreId = await this.storageService.getStoreId();
    const previousAddressId = await this.storageService.getDeliveryAddressId();
    if (address) {
      await this.storageService.saveDeliveryAddressId(address.Id);
    } else {
      await this.storageService.removeDeliveryAddressId();
    }
    if (previousStoreId !== store?.Id) {
      await this.timeSlotService.removeSelected();
    }
    await this.setStore(store);
    if (store) {
      const deliveryTime = await this.getDeliveryTime(address);
      await this.storageService.saveDeliveryTime(deliveryTime);
    }
    if (forceEmit || address?.Id !== previousAddressId || store?.Id !== previousStoreId) {
      this.emitAddressChange();
    }
  }

  async setStore(store) {
    let [previousMinimumOrderValue, previousStoreId, previousDeliveryType, previousStoreIsOpen, previousDeliveryCosts] = await Promise.all([
      this.storageService.getStoreMinimumOrderValue(),
      this.storageService.getStoreId(),
      this.storageService.getDeliveryType(),
      this.storageService.getIsOpen(),
      this.storageService.getDeliveryCost()
    ]);
    let minimumOrderValue;
    let storeId;
    let deliveryType;
    let isOpen;
    let deliveryCost;

    if (store) {
      minimumOrderValue = store.MinimumOrderValue;
      storeId = store.Id;
      deliveryType = store.Type;
      isOpen = store.ActiveOpeningHour?.IsOpen;
      deliveryCost = store.DeliveryCost;
      await this.storageService.saveStoreMinimumOrderValue(minimumOrderValue);
      await this.storageService.saveStoreId(storeId);
      await this.storageService.saveDeliveryType(deliveryType);
      await this.storageService.saveStoreDeliveryCost(deliveryCost);
      await this.storageService.saveIsOpen(store.ActiveOpeningHour);
    } else {
      await this.removeStoreFromStorage();
    }
    if (previousStoreId !== storeId
      || previousMinimumOrderValue !== minimumOrderValue
      || previousDeliveryType !== deliveryType
      || isOpen !== previousStoreIsOpen
      || deliveryCost !== previousDeliveryCosts) {
      this.emitStoreChange();
    }
  }

  public async setSelectedDeliveryAddressAndFindStore(address: Address, fallback = false, forceEmit: boolean = false): Promise<void> {
    let store = await this.findStoreByAddress(address);
    if (!store && fallback) {
      await this.storageService.removeDeliveryAddressId();
      address = await this.getPrimaryDelivery();
      store = await this.findStoreByAddress(address);
    }
    await this.setSelectedAddressAndStore(address, store, forceEmit);
  }
}
