import { Pipe, PipeTransform } from '@angular/core';
import {PaymentMethodType} from "../types/enums";
import {PaymentMethod} from "../types/models";

@Pipe({
  name: 'paymentMethodIcon'
})
export class PaymentMethodIconPipe implements PipeTransform {

  paymentMethods = {
    [PaymentMethodType.APPLE_PAY]: 'assets/icons/kiwi-applepay.svg',
    [PaymentMethodType.CREDIT_CARD]: 'assets/icons/kiwi-creditcard.svg',
    [PaymentMethodType.GOOGLE_PAY]: 'assets/icons/kiwi-googlepay.svg',
    [PaymentMethodType.PAYPAL]: 'assets/icons/kiwi-paypal.svg'
  };

  defaultIcon = 'assets/icons/kiwi-creditcard.svg';

  transform(paymentMethod: PaymentMethod): string {
    if (!paymentMethod) {
      return this.defaultIcon;
    }
    return this.paymentMethods[paymentMethod.Name] || this.defaultIcon;
  }

}
