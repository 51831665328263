import { Injectable } from '@angular/core';
import {AdminTimeSlotService} from "./admin-time-slot.service";
import {TimeSlot, TimeSlotGroup} from "../../types/models";
import {ApiService} from "../global/api.service";
import * as moment from "moment/moment";
import {AdminTimeSlotStorageService} from "../storage/admin-time-slot-storage.service";
import {Observable, Subject} from "rxjs";
import {TimeSlotState} from "../../types/enums";
import {AuthService} from "../global/auth.service";
import {UserService} from "../customer/user.service";
import {GeoLocation} from "../../types/interfaces";
import {AdminUserService} from "./admin-user.service";

@Injectable({
  providedIn: 'root'
})
export class AdminTimeSlotGroupService {

  private startLocationTrackingSubject = new Subject<string>();
  private stopLocationTrackingSubject = new Subject<void>();

  constructor(
    private timeSlotService: AdminTimeSlotService,
    private api: ApiService,
    private storageService: AdminTimeSlotStorageService,
    private authService: AuthService,
    private userService: UserService,
    private adminUserService: AdminUserService
  ) { }

  init() {
    this.authService.Logout.subscribe(async () => this.stopLocationTrackingSubject.next());
    this.authService.LoggedIn.subscribe(async () => {
      if (await this.userService.hasRole()) {
        const tracking = await this.adminUserService.getMyTask();
        if (tracking.TimeSlotGroupId) {
          const timeSlotGroup = await this.getMy();
          if (timeSlotGroup && timeSlotGroup.State === TimeSlotState.DELIVERING) {
            return this.startLocationTrackingSubject.next(timeSlotGroup.Id);
          }
        }
      }
    });
  }

  public get StartLocationTracking(): Observable<string> {
    return this.startLocationTrackingSubject.asObservable();
  }

  public get StopLocationTracking(): Observable<void> {
    return this.stopLocationTrackingSubject.asObservable();
  }

  public static getEmpty(): TimeSlotGroup {
    return {
      Name: null,
      PackingTime: null,
      StartTime: null,
      EndTime: null,
      TimeSlots: []
    }
  }

  setOrderAppointments(timeSlotGroup: TimeSlotGroup) {
    if (!timeSlotGroup) {
      return;
    }
    timeSlotGroup.OrderAppointments = [];
    for (const timeSlot of timeSlotGroup.TimeSlots || []) {
      if (timeSlot.OrderAppointments && timeSlot.OrderAppointments.length > 0) {
        timeSlotGroup.OrderAppointments.push(...timeSlot.OrderAppointments);
      }
    }
    timeSlotGroup.OrderAppointments.sort((a, b) => a.Number - b.Number);
  }

  async getAllGroupable(date: any): Promise<TimeSlot[]> {
    const start = moment(date).startOf('d');
    const end = moment(start).add(1, 'd');
    const timeSlots = await this.timeSlotService.getAllByTime(start, end);
    return (timeSlots || []).filter(timeslot => !timeslot.TimeSlotGroupId && !timeslot.ParentId && timeslot.Children?.length === 0);
  }

  async get(id: string): Promise<TimeSlotGroup> {
    const timeSlotGroup = await this.api.get('admin/time-slot-group/' + id);
    this.setOrderAppointments(timeSlotGroup);
    return timeSlotGroup;
  }

  async save(group: TimeSlotGroup): Promise<TimeSlotGroup> {
    return this.api.post('admin/time-slot-group', group, 'Speichern erfolgreich');
  }

  async delete(group: TimeSlotGroup): Promise<TimeSlotGroup> {
    return this.api.delete('admin/time-slot-group/' + group.Id, 'Löschen erfolgreich');
  }

  async getWithRoute(id: string): Promise<TimeSlotGroup> {
    const timeSlotGroup = await this.api.get('admin/time-slot-group/with-route/' + id);
    this.setOrderAppointments(timeSlotGroup);
    return timeSlotGroup;
  }

  async updateRoute(timeSlotGroup: TimeSlotGroup): Promise<String> {
    return this.api.post('admin/time-slot-group/route/' + timeSlotGroup.Id, timeSlotGroup, 'Speichern erfolgreich');
  }

  async getOpen(id: string): Promise<TimeSlotGroup> {
    const response = await this.api.get('admin/time-slot-group/open/' + id);
    this.setOrderAppointments(response);
    return response;
  }

  async getMy(): Promise<TimeSlotGroup> {
    const response = await this.api.get('admin/time-slot-group/my');
    this.setOrderAppointments(response);
    return response;
  }

  async startPacking(timeSlotGroup: TimeSlotGroup): Promise<TimeSlotGroup> {
    return this.api.post('admin/time-slot-group/start/packing/' + timeSlotGroup.Id, {});
  }

  async abortPacking(timeSlotGroup: TimeSlotGroup): Promise<TimeSlotGroup> {
    const response = await this.api.post('admin/time-slot-group/abort/packing/' + timeSlotGroup.Id, {});
    if (!!response) {
      await this.storageService.clearTimeSlotPacking(timeSlotGroup.Id);
    }
    return response;
  }

  async finishPacking(timeSlotGroup: TimeSlotGroup): Promise<TimeSlotGroup> {
    const response = await this.api.post('admin/time-slot-group/finish/packing/' + timeSlotGroup.Id, {});
    if (!!response) {
      await this.storageService.clearTimeSlotPacking(timeSlotGroup.Id);
    }
    return response;
  }

  async startDelivering(timeSlotGroup: TimeSlotGroup): Promise<TimeSlotGroup> {
    const response = await this.api.post('admin/time-slot-group/start/delivering/' + timeSlotGroup.Id, {});
    if (!!response) {
      this.startLocationTrackingSubject.next(response.Id);
    }
    return response;
  }

  async abortDelivering(timeSlotGroup: TimeSlotGroup): Promise<TimeSlotGroup> {
    const response = await this.api.post('admin/time-slot-group/abort/delivering/' + timeSlotGroup.Id, {});
    if (!!response) {
      this.stopLocationTrackingSubject.next();
    }
    return response;
  }

  async finishDelivering(timeSlotGroup: TimeSlotGroup): Promise<TimeSlotGroup> {
    const response = await this.api.post('admin/time-slot-group/finish/delivering/' + timeSlotGroup.Id, {});
    if (!!response) {
      this.stopLocationTrackingSubject.next();
    }
    return response;
  }

  async abort(id: string): Promise<TimeSlotGroup> {
    return this.api.post(`admin/time-slot-group/abort/${id}`, {});
  }

  public async saveLocation(timeSlotGroupId: string, location: GeoLocation): Promise<any> {
    return this.api.post(`admin/time-slot-group/location/${timeSlotGroupId}`, location);
  }

}
