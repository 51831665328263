import {Component, Input, OnInit} from '@angular/core';
import {ButtonConfig} from "../../types/io";

@Component({
  selector: 'app-app-version-modal',
  templateUrl: './app-version-modal.page.html',
  styleUrls: ['./app-version-modal.page.scss'],
})
export class AppVersionModalPage implements OnInit {

  @Input() Status: number;
  @Input() OperatingSystem: string;

  buttonConfig: ButtonConfig;

  constructor() { }

  ngOnInit() {
    this.buttonConfig = {
      update: {
        Text : this.OperatingSystem === 'ios' ? 'Jetzt zum AppStore' : 'Jetzt zum PlayStore',
        Url : this.OperatingSystem === 'ios' ? 'https://apps.apple.com/us/app/kiwi-delivery/id6443841821' : 'https://play.google.com/store/apps/details?id=com.kiwigermany.starter',
      }
    }
  }


}
