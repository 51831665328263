import { Injectable } from '@angular/core';
import {ApiService} from "../global/api.service";
import {TimeSlot} from "../../types/models";
import {DeliveryStorageService} from "../storage/delivery-storage.service";
import * as moment from "moment/moment";
import {KeyMap} from "../../types/interfaces";
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TimeSlotService {

  private timeSlotChange: Subject<void> = new Subject<void>();

  constructor(
    private api: ApiService,
    private deliveryStorage: DeliveryStorageService
  ) { }

  public get TimeSlot(): Observable<void> {
    return this.timeSlotChange.asObservable();
  }

  emitTimeSlotChange() {
    this.timeSlotChange.next();
  }

  async getUpcoming(): Promise<TimeSlot[]> {
    const addressId = await this.deliveryStorage.getDeliveryAddressId();
    if (!addressId) {
      return [];
    }
    return this.api.get('time-slot/upcoming/' + addressId);
  }

  private async getByTimeRange(route: string, start: any, end: any): Promise<any> {
    const addressId = await this.deliveryStorage.getDeliveryAddressId();
    if (!addressId) {
      return [];
    }
    const startTime = moment(start).format('YYYY-MM-DD');
    const endTime = moment(end).subtract(1, 'd').format('YYYY-MM-DD');
    return this.api.get(`${route}/${addressId}/${startTime}/${endTime}`);
  }

  async getByTimeAsMap(start: any, end: any): Promise<KeyMap<TimeSlot[]>> {
    return this.getByTimeRange('time-slot/by-time/as-map', start, end);
  }

  async getByTime(start: any, end: any): Promise<TimeSlot[]> {
    return this.getByTimeRange('time-slot/by-time', start, end);
  }

  async get(timeSlotId: string): Promise<TimeSlot> {
    if (!timeSlotId) {
      return null;
    }
    const addressId = await this.deliveryStorage.getDeliveryAddressId();
    return this.api.get('time-slot/' + timeSlotId, addressId ? {AddressId: addressId}: addressId);
  }

  async getSelected(): Promise<TimeSlot> {
    const timeSlotId = await this.deliveryStorage.getDeliveryTimeSlotId();
    if (timeSlotId) {
      return this.get(timeSlotId);
    } else {
      return null;
    }
  }

  async removeSelected() {
    return this.setSelected(null);
  }

  async setSelected(timeSlot: TimeSlot) {
    const previousTimeSlotId = await this.deliveryStorage.getDeliveryTimeSlotId();
    if (timeSlot) {
      await this.deliveryStorage.saveDeliveryTimeSlotId(timeSlot.Id);
      await this.deliveryStorage.saveTimeSlotDeliveryCost(timeSlot.DeliveryCost);
      await this.deliveryStorage.saveTimeSlotMinimumOrderValue(timeSlot.MinimumOrderValue);
      await this.deliveryStorage.saveTimeSlotFollowUpFee(timeSlot.FollowUpFee);
    } else {
      await this.deliveryStorage.removeTimeSlotDeliveryCost();
      await this.deliveryStorage.removeTimeSlotMinimumOrderValue();
      await this.deliveryStorage.removeDeliveryTimeSlotId();
      await this.deliveryStorage.removeTimeSlotFollowUpFee();
    }
    const timeSlotId = await this.deliveryStorage.getDeliveryTimeSlotId();
    if (timeSlotId !== previousTimeSlotId) {
      this.emitTimeSlotChange();
    }
  }
}
