import { Pipe, PipeTransform } from '@angular/core';
import {NewsType} from "../types/enums";

@Pipe({
  name: 'newsType'
})
export class NewsTypePipe implements PipeTransform {

  newTypes = {
    [NewsType.USER_DELETE]: 'Benutzer Löschanfrage',
    [NewsType.CHARGEBACK_ERROR]: 'Bezahl-Erstattungsfehler',
    [NewsType.OPEN_BILLING]: 'Offene Rechnung',
  };

  transform(value: string): string {
    return this.newTypes[value] || value;
  }

}
