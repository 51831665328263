import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { Address, } from '../../types/models';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { LocationStorageService } from '../storage/location-storage.service';

import * as Leaflet from 'leaflet';
import { OSMReverseResponse, OSMSearchResponse } from '../../types/thirdparty';
import { GeoLocation } from "../../types/interfaces";

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  api = 'https://nominatim.openstreetmap.org/';
  leafletStyle: { [key: string]: any };
  private http: HttpClient;

  constructor(
    private storageService: LocationStorageService,
    private handler: HttpBackend,
  ) {
    this.http = new HttpClient(handler);
    this.leafletStyle = {
      solidLine: {
        className: 'map-line solid-line',
      },
      default: {
        radius: 14,
        renderer: Leaflet.svg(),
        className: 'map-marker default-marker',
        fillOpacity: 0.5
      },
      solid: {
        radius: 14,
        renderer: Leaflet.svg(),
        className: 'map-marker solid-marker',
        fillOpacity: 1
      },
      store: {
        radius: 7,
        renderer: Leaflet.svg(),
        className: 'map-marker store-marker',
        fillOpacity: 1
      },
      driver: {
        className: 'map-marker html-marker  driver-marker',
        htmlTemplate: '<div class="marker-icon"><img src="assets/icons/kiwi-logo.png" /></div>'
      },
      text: {
        className: 'map-marker html-marker  text',
        htmlTemplate: '<div class="marker-icon">$</div>'
      },
      area: {
        type: 'circle',
        renderer: Leaflet.svg(),
        className: 'map-marker area',
        fillOpacity: 0.5,
      },
      notSelected: {
        type: 'circle',
        renderer: Leaflet.svg(),
        className: 'map-marker not-selected',
        fillOpacity: 0.5,
      },
      boundingCircle: {
        type: 'circle',
        renderer: Leaflet.svg(),
        className: 'map-marker bounding-circle',
        fillOpacity: 0,
      },

    };
  }

  distance(mk1: GeoLocation, mk2: GeoLocation): number {
    const earthRadius = 6371.071; // Radius of the Earth in kilometers
    const latitudeRadians1 = Number(mk1.Latitude) * (Math.PI / 180); // Convert degrees to radians
    const latitudeRadians2 = Number(mk2.Latitude) * (Math.PI / 180); // Convert degrees to radians
    const deltaLatitude = latitudeRadians2 - latitudeRadians1; // Radian difference (latitudes)
    const deltaLongitude =
      (Number(mk2.Longitude) - Number(mk1.Longitude)) * (Math.PI / 180); // Radian difference (longitudes)
    const sinDeltaLatitude = Math.sin(deltaLatitude / 2);
    const sinDeltaLongitude = Math.sin(deltaLongitude / 2);
    const square = Math.sqrt(
      sinDeltaLatitude * sinDeltaLatitude +
      Math.cos(latitudeRadians1) *
      Math.cos(latitudeRadians2) *
      sinDeltaLongitude *
      sinDeltaLongitude
    );
    return 2 * earthRadius * Math.asin(square);
  }

  async search(address: Address): Promise<GeoLocation> {
    return (
      (await this.storageService.getLocationByAddress(address)) ||
      (await this.loadAndSaveSearch(address))
    );
  }

  async reverse(location: GeoLocation): Promise<Address> {
    const response: OSMReverseResponse = await this.get('reverse', {
      lat: location.Latitude,
      lon: location.Longitude,
    });
    if (!response || !response.address) {
      return null;
    }
    return {
      City: response.address.city,
      Street: response.address.road + ' ' + response.address.house_number,
      Zip: response.address.postcode,
    };
  }

  async getGeoLocation(): Promise<GeoLocation> {
    return (
      (await this.storageService.getGeoLocation()) ||
      (await this.loadAndSaveGeoLocation())
    );
  }

  async loadAndSaveSearch(address: Address): Promise<GeoLocation> {
    const splitStreet = (address?.Street || '').split(' ');
    if (splitStreet.length <= 1) {
      return null;
    }

    let houseNumber;
    let street;
    for (let i = 0; i < splitStreet.length; i++) {
      const cleaned = splitStreet[i].trim();
      if (cleaned.length === 0) {
        continue;
      }
      if (/\d+.*/.test(cleaned)) {
        houseNumber = cleaned;
        break;
      } else {
        street = street ? street + ' ' + cleaned : cleaned;
      }
    }
    if (!street || street.length === 0) {
      street = address.Street;
    }
    street = street.trim();
    const params = {
      street: houseNumber ? (houseNumber + ' ' + street) : street,
      postalcode: address.Zip,
      city: address.City,
    };
    const responseArray: OSMSearchResponse[] = await this.get('search', params);
    // const typeCheck = searchResponse => searchResponse.addresstype === 'road' || searchResponse.addresstype === 'place';
    // const geoCheck = searchResponse => searchResponse.lat && searchResponse.lon;
    // const responsesWithLocations = (responseArray || []).filter(searchResponse => geoCheck(searchResponse));
    let response = responseArray[0] //responsesWithLocations.find(searchResponse => typeCheck(searchResponse));
    // if (!response && responsesWithLocations.length > 0) {
    // response = responsesWithLocations[0];
    // }
    if (!response) {
      return null;
    }
    const location = {
      Latitude: response.lat,
      Longitude: response.lon,
    };
    await this.storageService.saveLocationByAddress(address, location);
    return location;
  }

  async loadAndSaveGeoLocation(timeout: number = 5000): Promise<GeoLocation> {
    let currentPosition;
    try {
      currentPosition = await Geolocation.getCurrentPosition({ timeout });
    } catch (err) {
      await Geolocation.requestPermissions();
      let info = await Geolocation.checkPermissions();
      if (info.location === 'granted') {
        currentPosition = await Geolocation.getCurrentPosition({ timeout });
      }
    }
    if (!currentPosition) {
      return null;
    }
    const location = {
      Latitude: currentPosition.coords.latitude,
      Longitude: currentPosition.coords.longitude,
    };
    await this.storageService.saveGeoLocation(location);
    return location;
  }

  async get(Path: string, Params: any = {}): Promise<any> {
    try {
      Params.format = 'json';
      const response = await this.http
        // eslint-disable-next-line @typescript-eslint/naming-convention
        .get(this.api + Path, { params: Params, observe: 'response' })
        .toPromise();
      return response.body;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  getLeafletStyle(name: string): any {
    let key;
    if (name && this.leafletStyle.hasOwnProperty(name)) {
      key = name;
    } else {
      key = 'default';
    }
    return this.leafletStyle[key] || {};
  }
}
