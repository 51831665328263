import { Pipe, PipeTransform } from '@angular/core';
import {PrintingState} from "../types/enums";

@Pipe({
  name: 'printingState'
})
export class PrintingStatePipe implements PipeTransform {

  printingStates = {
    [PrintingState.WAITING]: 'Warten',
    [PrintingState.GENERATING]: 'Druckauftrag erstellt',
    [PrintingState.PRINTING]: 'Wird gedruckt',
    [PrintingState.PRINTED]: 'Drucken beendet'
  };

  transform(key: string, ...args: unknown[]): unknown {
    return this.printingStates[key] || key;
  }

}
