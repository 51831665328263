import {Pipe, PipeTransform} from '@angular/core';
import {PaymentMethodType} from "../types/enums";

@Pipe({
  name: 'paymentMethod'
})
export class PaymentMethodPipe implements PipeTransform {

  constructor() {
  }

  paymentMethods = {
    [PaymentMethodType.APPLE_PAY]: 'Apple Pay',
    [PaymentMethodType.BILL]: 'Rechnung',
    [PaymentMethodType.CREDIT_CARD]: 'Kreditkarte / Wallet',
    [PaymentMethodType.GOOGLE_PAY]: 'Google Pay',
    [PaymentMethodType.EC_DELIVERY]: 'EC (Zahlung bei Übergabe)',
    [PaymentMethodType.EC_PIN]: 'EC (PIN)',
    [PaymentMethodType.PAYPAL]: 'Paypal',
    [PaymentMethodType.CHECKOUT]: 'Checkout (PayPal, Klarna, uvm.)',
    [PaymentMethodType.SEPA]: 'Lastschrifteinzug',
  };

  transform(input: any): string {
    if (!input) {
      return null;
    }
    const name = input.PaymentMethod || input.paymentMethod || input.Name || input;
    return this.paymentMethods[name] || name || input;
  }

}
