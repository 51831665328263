import {Injectable} from '@angular/core';
import {ApiService} from "../global/api.service";
import {DeliveryAreaCircle, Store, StoreSetting} from "../../types/models";
import {ValidationService} from "../core/validation.service";
import {LocationService} from '../core/location.service';
import {AddressPipe} from "../../pipes/address.pipe";
import {AuthStorageService} from "../storage/auth-storage.service";
import {DeliveryTypePipe} from "../../pipes/delivery-type.pipe";
import {LeafletCoordinate} from "../../types/thirdparty";

@Injectable({
  providedIn: 'root'
})
export class AdminStoreService {

  constructor(
    private api: ApiService,
    private validationService: ValidationService,
    private locationService: LocationService,
    private addressPipe: AddressPipe,
    private deliveryTypePipe: DeliveryTypePipe,
    private storageService: AuthStorageService
  ) {
  }

  public static getEmptyStore(): Store {
    return {
      Name: null,
      Description: null,
      Address: {
        Street: null,
        Zip: null,
        City: null
      },
      Type: null,
      DeliveryArea: {
        Type: 'circle',
        Data: {},
        DeliveryAreaCircles: []
      },
      Packaging: null,
      MinimumOrderValue: null
    };
  }

  async getAllStoreSettings(): Promise<StoreSetting[]> {
    return this.api.get('admin/store/setting');
  }

  async getStoreSetting(storeId: string): Promise<StoreSetting> {
    return this.api.get('admin/store/setting/' + storeId);
  }

  async saveStoreSetting(storeId: string, setting: StoreSetting): Promise<StoreSetting> {
    return this.api.post('admin/store/setting/' + storeId, setting, 'Speichern erfolgreich');
  }

  async saveStoreBillingSetting(storeId: string, setting: StoreSetting): Promise<StoreSetting> {
    return this.api.post('admin/store/setting/billing/' + storeId, setting, 'Speichern erfolgreich');
  }

  async getAll(params = {}): Promise<Store[]> {
    return this.api.get('admin/store', params);
  }

  async getAllWithPaymentMethodSettings(params = {}): Promise<Store[]> {
    return this.api.get('admin/store/with-payment-method-setting', params);
  }

  async getAllByUserRole(role?: string, params = {}): Promise<Store[]> {
    return this.api.get('admin/store/by-user-role' + (role ? '/' + role : ''), params);
  }

  async get(id: string): Promise<Store> {
    return this.api.get(`admin/store/${id}`);
  }

  async save(store: Store): Promise<Store> {
    if (this.hasErrors(store)) {
      return null;
    }
    const response = await this.locationService.search(store.Address);
    if (response) {
      store.Address.Longitude = response.Longitude;
      store.Address.Latitude = response.Latitude;
    } else {
      store.Address.Longitude = null;
      store.Address.Latitude = null;
    }
    return this.api.post('admin/store', store, 'Speichern erfolgreich.');
  }

  async savePackaging(store: Store): Promise<Store> {
    return this.api.post('admin/store/packaging/' + store.Id, store, 'Speichern erfolgreich.');
  }

  private hasErrors(store: Store): boolean {
    if (store.Draft) {
      return false;
    }
    const notEmptyFields = [
      { field: 'Name', name: 'Name' },
      { field: 'Type', name: 'Lieferart' },
      { field: 'Zip', name: 'PLZ', mapFct: store => store.Address },
      { field: 'Street', name: 'Straße und Hausnummer', mapFct: store => store.Address },
      { field: 'City', name: 'Stadt', mapFct: store => store.Address },
      { field: 'DeliveryArea', name: 'Lieferradius' },
      { field: 'DeliveryCost', name: 'Lieferkosten' },
    ];
    const notNegativeFields = [
      { field: 'DeliveryArea', name: 'Lieferradius' },
      { field: 'DeliveryCost', name: 'Lieferkosten' },
      { field: 'MinimumOrderValue', name: 'Mindestbestellwert' },
      { field: 'FollowUpFee', name: 'Nachbestellgebühr'}
    ];
    return this.validationService.hasEmptyFields(store, notEmptyFields)
      || this.validationService.hasNegativeValues(store, notNegativeFields);
  }

  async delete(store: Store): Promise<Store> {
    if (store.Id) {
      return this.api.delete(`admin/store/${store.Id}`);
    }
    return store;
  }

  async deleteDeliveryAreaCircle(area: DeliveryAreaCircle): Promise<DeliveryAreaCircle> {
    if (area.Id) {
      return this.api.delete('admin/store/delivery-area-circle/' + area.Id);
    }
    return area;
  }

  async deliveryAreaCircleInUse(area: DeliveryAreaCircle): Promise<{ InUse: boolean, Message: string }> {
    const inUse: { TimeSlotTypes?: number, TimeSlots?: number } = area.Id ? await this.api.get((`admin/store/delivery-area-circle/in-use/${area.Id}`)) : {};
    const response = { InUse: false, Message: '' };
    if (inUse.TimeSlotTypes) {
      response.InUse = true;
      response.Message = 'Das Liefergebiet wurde mindestens einer Zeit-Slot Vorlagen zugeordnet. '
        + 'Wenn das Liefergebiet gelöscht wird, so wird es ebenfalls aus allen Vorlagen gelöscht. ';
    }
    if (inUse.TimeSlots) {
      response.InUse = true;
      response.Message += 'Das Liefergebiet ist mindestens einem Zeit-Slot zugeordnet. '
        + 'Wenn das Liefergebiet gelöscht wird, so wird es ebenfalls aus allen Zeit-Slots gelöscht.';
    }
    return response;
  }

  public async inUse(store: Store): Promise<{ InUse: boolean, Message: string }> {
    const inUse: { Stocks?: number, Orders?: number } = store.Id ? await this.api.get((`admin/store/in-use/${store.Id}`)) : {};
    const response = { InUse: false, Message: '' };
    if (inUse.Stocks) {
      response.InUse = true;
      response.Message = 'Die Filiale enthält bereits ' + (inUse.Stocks === 1 ? 'ein Produkte' : inUse.Stocks + ' Produkte') + '. '
        + 'Wenn die Filiale gelöscht wird, werden alle Informationen über den Lagerbestand der Filiale ebenfalls gelöscht. ';
    }
    if (inUse.Orders) {
      response.InUse = true;
      response.Message += 'Die Filiale enthält mindestens eine offene Bestellung. '
        + 'Wenn die Filiale gelöscht wird, können die Bestellinformationen nicht mehr eingesehen werden.';
    }
    return response;
  }

  public async hasProduct(storeId: string, productId: string): Promise<boolean> {
    return this.api.get(`admin/store/has-product/${storeId}/${productId}`);
  }

  getStoreName(store: Store, withType: boolean = true): string {
    if (!store) {
      return '';
    }
    return `
      ${store.Name ? store.Name + ' ' : ''}
      ${withType && store.Type ? '(' + this.deliveryTypePipe.transform(store.Type) + ') ' : ''}
      ${this.addressPipe.transform(store.Address)}
    `;
  }

  async getSelectedStoreId(stores: Store[]) {
    const selectedStoreId = await this.storageService.getEmployeeStoreId();
    return this.getValidStoreId(stores, selectedStoreId);
  }

  async getValidStoreId(stores: Store[], selectedStoreId: string) {
    const publicStores = (stores || []).filter(store => !store.Draft);
    const publicStore = publicStores.length > 0 ? publicStores[0] : null;
    let storeId: string;
    if (!selectedStoreId && stores && stores.length > 0) {
      storeId = publicStore ? publicStore.Id : stores[0].Id;
    } else if (stores && stores.length > 0) {
      const found = (stores || []).find(s => s.Id === selectedStoreId);
      storeId = !!found ? selectedStoreId : publicStore ? publicStore.Id : stores[0].Id;
    } else {
      storeId = null;
    }
    return storeId;
  }

  getLeafletCoordinateFromAddress(store: Store): LeafletCoordinate {
    const address = store?.Address;
    const deliveryArea = store?.DeliveryArea;
    if (address?.Longitude && address?.Latitude) {
      return {lat: Number(address.Latitude), lng: Number(address.Longitude)};
    }  else if (deliveryArea?.Data?.Latitude && deliveryArea?.Data?.Longitude) {
      return {lat: Number(deliveryArea.Data.Latitude), lng: Number(deliveryArea.Data.Longitude)};
    } else {
      return null;
    }
  }

  getLeafletCoordinatesFromDeliveryAreas(store: Store, selected?: { [id: string]: any }): any[] {
    if (store?.DeliveryArea) {
      const deliveryAreas = [];
      for (const area of store.DeliveryArea.DeliveryAreaCircles || []) {
        if (!area.Longitude || !area.Longitude) {
          continue;
        }
        deliveryAreas.push({
          coordinate: { lat: Number(area.Latitude), lng: Number(area.Longitude) },
          styleName: (selected == null || !!selected[area.Id]) ? 'area' : 'notSelected',
          options: { radius: area.Radius || 10, title: area.Name }
        });
      }

      return deliveryAreas;
    } else {
      return null;
    }
  }

  async getSelectedStore(): Promise<Store> {
    const storeId = await this.storageService.getEmployeeStoreId();
    if (!storeId) {
      return null;
    }
    return this.get(storeId);
  }


}
