import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'errorMessage'
})
export class ErrorMessagePipe implements PipeTransform {

  transform(input: any, defaultMessage: string): unknown {
    let value = input ? input.error || input.message || input : null;
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    } else if (typeof value !== 'string') {
      value = String(value);
    }
    if (value === '{}') {
      value = null;
    }
    value = value || defaultMessage;
    return value;
  }

}
