import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {UserService} from "../customer/user.service";
import {AuthStorageService} from '../storage/auth-storage.service';
import {Browser} from '@capacitor/browser';
import {Observable, Subject} from 'rxjs';
import {User} from "../../types/models";
import {TerminalService} from "../customer/terminal.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private logoutListener;
  private logoutKey;
  private loginSubject: Subject<void> = new Subject<void>();
  private logoutSubject: Subject<void> = new Subject<void>();
  private loggedInSubject: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private storageService: AuthStorageService,
    private terminalService: TerminalService
  ) {
  }

  public init() {
    this.apiService.Unauthorized.subscribe(async () => this.logout());
    const getUser = () => this.userService.get()
      .then(user => {
        if (user) {
          this.loggedInSubject.next()
        }
      })
      .catch(() => {
      });
    if (this.apiService.isTerminal()) {
      this.storageService.saveIsTerminal()
        .then(async () => await this.terminalService.getOrCreate())
        .then(getUser);
    } else {
      this.storageService.removeIsTerminal().then(getUser);
    }
  }

  getLogoutListener() {
    return event => {
      const lookup = '$$$logout$$$';
      this.logoutKey = this.logoutKey || '';
      this.logoutKey += event.key;
      if (lookup === this.logoutKey) {
        return this.logout();
      }
      if (lookup.indexOf(this.logoutKey) !== 0) {
        this.logoutKey = '';
      }
    };
  }

  async addLogoutListener() {
    this.removeLogoutListener();
    if (await this.storageService.isTerminal()) {
      this.logoutListener = this.getLogoutListener();
      document.addEventListener('keypress', this.logoutListener)
    }
  }

  removeLogoutListener() {
    if (this.logoutListener) {
      document.removeEventListener('keypress', this.logoutListener);
      this.logoutListener = null;
    }
  }

  public get Logout(): Observable<void> {
    return this.logoutSubject.asObservable();
  }

  public get Login(): Observable<void> {
    return this.loginSubject.asObservable();
  }

  public get LoggedIn(): Observable<void> {
    return this.loggedInSubject.asObservable();
  }

  public async registerByMail(mail: string, password: string): Promise<boolean> {
    return this.apiService.post('signup', { Mail: mail, Password: password });
  }

  public async verifyCode(mail: string, code: string): Promise<boolean> {
    const response = await this.apiService.post('signup/verify', { Mail: mail, Code: code });
    return this.setApiKey(response);
  }

  public async getAndSaveLoginToken(): Promise<string> {
    const token = await this.apiService.get('signin/token');
    await this.storageService.saveAuthToken(token);
    return token;
  }

  public async getApiKey(): Promise<string> {
    return this.storageService.getApiKey();
  }

  public async loginAsGuest(): Promise<void> {
    const apikey = await this.apiService.post(`signin/guest`, {});
    await this.setApiKey({ 'x-api-key': apikey });
    await this.login();
  }

  public async loginByProvider(provider: string): Promise<void> {
    const token = await this.getAndSaveLoginToken();
    const url = this.apiService.getUrl() + `signin/provider?provider=${provider}&token=${token}`;
    return this.openCapacitorBrowser(url);
  }

  async openCapacitorBrowser(url: string) {
    await Browser.open({ url });
    let eventListener = async () => {
      const success = await this.loginByToken();
      if (success) {
        await Browser.removeAllListeners();
        await this.login();
      }
    };
    await Browser.addListener('browserFinished', eventListener);
  }

  async openSystemBrowser(url: string) {
    let eventListener = async () => {
      const success = await this.loginByToken();
      if (success) {
        window.removeEventListener('message', eventListener);
      }
    };
    window.open(url);
    window.addEventListener('message', eventListener, false);
  }

  public async loginByToken() {
    const token = await this.storageService.getAuthToken();
    if (!token) {
      return null;
    }
    const apikey = await this.apiService.get(`signin/provider/${token}`);
    const success = await this.setApiKey(apikey);
    if (success) {
      await this.storageService.removeAuthToken();
      await this.login();
    }
    return success;
  }

  public async kiwiLogin(mail: string, password: string, route: string): Promise<string> {
    const response = await this.apiService.post(route, { Mail: mail, Password: password }, null, false);
    const success = await this.setApiKey(response);
    if (success) {
      await this.login();
    }
    const message = success ? 'ok' : this.apiService.getErrorMessage('POST', route);
    return typeof message === 'string' ? message : '';
  }

  public async loginByMail(mail: string, password: string): Promise<string> {
    return this.kiwiLogin(mail, password, 'signin/kiwi');
  }

  public async adminLoginByMAil(mail: string, password: string): Promise<string> {
    return this.kiwiLogin(mail, password, 'signin/kiwi/admin');
  }

  public async getUserByChip(chipId: string): Promise<{ User: User, Message: string }> {
    const response = { User: null, Message: null };
    const terminalId = await this.storageService.getTerminalId();
    const user = await this.apiService.post('signin/kiwi-chip/user', { ChipId: chipId, TerminalId: terminalId }, null, false);
    response.User = user;
    response.Message = !!user ? 'ok' : this.apiService.getErrorMessage('POST', 'signin/kiwi-chip/user');
    return response;
  }

  public async loginByChip(chipId: string): Promise<string> {
    const terminalId = await this.storageService.getTerminalId();
    const response = await this.apiService.post('signin/kiwi-chip', { ChipId: chipId, TerminalId: terminalId }, null, false);
    const success = await this.setApiKey(response);
    if (success) {
      await this.login();
    }
    const message = success ? 'ok' : this.apiService.getErrorMessage('POST', 'signin/kiwi-chip');
    return typeof message === 'string' ? message : '';
  }

  private async setApiKey(serverResponse: any): Promise<boolean> {
    if (serverResponse && serverResponse['x-api-key']) {
      await this.storageService.saveApiKey(serverResponse['x-api-key']);
      return true;
    }
    return false;
  }

  public async isLoggedIn(): Promise<boolean> {
    return await this.storageService.getApiKey() != null;
  }

  public async logout(): Promise<void> {
    this.logoutSubject.next();
    setTimeout(async () =>  await this.storageService.clear(), 1000);
  }

  public async login(): Promise<void> {
    if (await this.isLoggedIn()) {
      this.loginSubject.next();
      this.loggedInSubject.next();
    }
  }

  checkPassword(password: string, passwordConfirmed: string) {
    if (!password) {
      return 'Es muss ein Passwort angegeben werden.';
    } else if (!passwordConfirmed) {
      return 'Das Passwort muss wiederholt werden.';
    } else if (password !== passwordConfirmed) {
      return 'Die Passwörter stimmen nicht überein.';
    }
    return null;
  }

  async saveNewPassword(oldPassword: string, newPassword: string): Promise<{ Success: boolean, Message: string }> {
    const response = await this.apiService.post('signin/password', { Password: oldPassword, NewPassword: newPassword }, '', false)
    if (!response) {
      return { Success: false, Message: this.apiService.getErrorMessage('POST', 'signin/password') };
    }
    return { Success: true, Message: response };
  }

  async forgotPassword(mail: string): Promise<boolean> {
    return this.apiService.post('signin/password/reset', { Mail: mail }, '', false);
  }

  async isDefaultPassword(): Promise<boolean> {
    return this.apiService.get('signin/password/is-default');
  }
}
