// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.count-down {
  width: 8rem;
  height: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--ion-color-primary);
  border-radius: 50%;
  color: var(--ion-color-primary);
  font-size: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/idle-modal/idle-modal.page.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,0CAAA;EACA,kBAAA;EACA,+BAAA;EACA,iBAAA;AACF","sourcesContent":[".count-down {\n  width: 8rem;\n  height: 8rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  border: 2px solid var(--ion-color-primary);\n  border-radius: 50%;\n  color: var(--ion-color-primary);\n  font-size: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
