import {Pipe, PipeTransform} from '@angular/core';
import {Weekday} from '../types/enums';

@Pipe({
  name: 'weekday'
})
export class WeekdayPipe implements PipeTransform {

  transform(weekday: Weekday, format?: string): string {
    if (weekday === Weekday.MO) {
      return format ==='short' ? 'Mo.' : 'Montag';
    } else if (weekday === Weekday.TU) {
      return  format ==='short' ? 'Di.' : 'Dienstag';
    } else if (weekday === Weekday.WE) {
      return  format ==='short' ? 'Mi.' : 'Mittwoch';
    } else if (weekday === Weekday.TH) {
      return  format ==='short' ? 'Do.' : 'Donnerstag';
    } else if (weekday === Weekday.FR) {
      return  format ==='short' ? 'Fr.' : 'Freitag';
    } else if (weekday === Weekday.SA) {
      return  format ==='short' ? 'Sa.' : 'Samstag';
    } else if (weekday === Weekday.SU) {
      return  format ==='short' ? 'So.' : 'Sonntag';
    }
    return weekday;
  }

}
