import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArrayService {

  constructor() { }


  toMap<Type>(anArray: Type[], key: string = 'Id'): { [key: string]: Type } {
    if (!Array.isArray(anArray)) {
      return {};
    }
    return (anArray || []).reduce((a, v) => ({ ...a,  [key ? v[key] : v]: v }), {});
  }
}
