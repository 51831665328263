import { Pipe, PipeTransform } from '@angular/core';
import {ProviderType} from "../types/enums";

@Pipe({
  name: 'provider'
})
export class ProviderPipe implements PipeTransform {

  providerMap = {
    [ProviderType.APPLE] : 'Apple',
    [ProviderType.GOOGLE] : 'Google',
    [ProviderType.FACEBOOK] : 'Facebook',
    [ProviderType.KIWI] : 'Kiwi',
    [ProviderType.KIWI_CHIP] : 'Kiwi (Chip)'
  };

  transform(value: string): string {
    return this.providerMap[value] || value;
  }

}
