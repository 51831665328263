import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DecimalPipe} from "@angular/common";
import {PricePipe} from "./pipes/price.pipe";
import { RolePipe } from './pipes/role.pipe';
import { AddressPipe } from './pipes/address.pipe';
import { RecipeProductAmountPipe } from './pipes/recipe-product-amount.pipe';
import { ProductAmountPipe } from './pipes/product-amount.pipe';
import { DeliveryStatePipe } from './pipes/delivery-state.pipe';
import { PushSettingsPipe } from './pipes/push-settings.pipe';
import { PrintingStatePipe } from './pipes/printing-state.pipe';
import {PaymentMethodPipe} from './pipes/payment-method.pipe';
import {WeekdayPipe} from './pipes/weekday.pipe';
import {DeliveryTypePipe} from "./pipes/delivery-type.pipe";
import {UserNamePipe} from "./pipes/user-name.pipe";
import {TimeModePipe} from "./pipes/time-mode.pipe";
import {TimeSlotStatePipe} from "./pipes/time-slot-state.pipe";
import {NewsTypePipe} from "./pipes/news-type.pipe";
import {UserTypePipe} from "./pipes/user-type.pipe";
import {PaymentMethodIconPipe} from "./pipes/payment-method-icon.pipe";
import {ErrorMessagePipe} from "./pipes/error-message.pipe";
import {ProviderPipe} from "./pipes/provider.pipe";

const declarations = [
  AddressPipe,
  DeliveryStatePipe,
  DeliveryTypePipe,
  ErrorMessagePipe,
  NewsTypePipe,
  PaymentMethodIconPipe,
  PaymentMethodPipe,
  PricePipe,
  PrintingStatePipe,
  ProductAmountPipe,
  ProviderPipe,
  PushSettingsPipe,
  RecipeProductAmountPipe,
  RolePipe,
  TimeModePipe,
  TimeSlotStatePipe,
  UserNamePipe,
  UserTypePipe,
  WeekdayPipe
];

@NgModule({
  declarations: declarations,
  imports: [IonicModule],
  providers: [DecimalPipe, ...declarations],
  exports: [...declarations]
})
export class AppPipesModule {}
