import {Component} from '@angular/core';
import {StatusBar} from '@capacitor/status-bar';
import {SplashScreen} from '@capacitor/splash-screen';
import {PlatformService} from './services/core/platform.service';
import {registerLocaleData} from '@angular/common';
import localeDeDe from '@angular/common/locales/de';
import {AppVersionService} from './services/global/app-version.service';
import {register} from 'swiper/element/bundle';
import {StartUpService} from "./services/global/start-up.service";

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    private platformService: PlatformService,
    private appVersionService: AppVersionService,
    private startUpService: StartUpService
  ) {
    this.initializeApp().then(() => {}).catch(e => console.log('error while init', e));
  }

  async initializeApp() {
    SplashScreen.show();
    await this.platformService.init();
    this.initColors();
    registerLocaleData(localeDeDe);
    this.startUpService.init();
    await this.checkAppVersion();
    setTimeout(() => SplashScreen.hide(), 800);
  }

  async checkAppVersion() {
    this.appVersionService.init();
    await this.appVersionService.checkVersion();
  }

  initColors() {
    if (this.platformService.isApp()) {
      StatusBar.setBackgroundColor({ color: '#232323' });
      StatusBar.show();
      document.body.classList.add('dark');
    }
  }
}
