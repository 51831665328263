import {Pipe, PipeTransform} from '@angular/core';
import {DeliveryType, UserType} from "../types/enums";

@Pipe({
  name: 'userType'
})
export class UserTypePipe implements PipeTransform {

  userTypes = {
    [UserType.PRIVATE]: 'Privat',
    [UserType.CORPORATE]: 'Geschäftlich',
    'none': '- ohne Einschränkung -'
  };

  userTypesLong = {
    [UserType.PRIVATE]: 'Privatkunden',
    [UserType.CORPORATE]: 'Geschäftskunden',
    'none': '- ohne Einschränkung -'
  };

  transform(value: string, long: boolean = false, hideNone: boolean = true): string {
    if (hideNone && value === 'none') {
      return null;
    }
    const translation = long ? this.userTypesLong : this.userTypes;
    return translation[value] || value;
  }
}
