import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {ProviderType} from "../../types/enums";
import {Observable, Subject} from "rxjs";

enum AuthStorage {
  API_KEY = 'x-api-key',
  EMPLOYEE_STORE = 'employee-store-id',
  GUEST = 'is-guest',
  CHIP = 'is-chip',
  PROVIDER_TYPE = 'provider-type',
  SIGN_IN_MODE = 'sign-in-mode',
  TERMINAL = 'is-terminal',
  TERMINAL_ID = 'terminal-id',
  TOKEN = 'auth-token',
}

@Injectable({
  providedIn: 'root'
})
export class AuthStorageService {

  private terminalChangeSubject: Subject<void> = new Subject<void>();

  constructor(
    private storageService: StorageService
  ) { }


  public get Terminal(): Observable<void> {
    return this.terminalChangeSubject.asObservable();
  }

  async saveTerminalId(id: string) {
    const previousId = await this.getTerminalId();
    await this.storageService.save(AuthStorage.TERMINAL_ID, id);
    if (previousId !== id) {
      this.terminalChangeSubject.next();
    }
  }

  async getTerminalId(): Promise<string> {
    return this.storageService.get(AuthStorage.TERMINAL_ID);
  }

  async saveSignInMode(key: string) {
    return this.storageService.save(AuthStorage.SIGN_IN_MODE, key);
  }

  async removeSignInMode() {
    return this.storageService.remove(AuthStorage.SIGN_IN_MODE);
  }

  async getSignInMode(): Promise<string> {
    return this.storageService.get(AuthStorage.SIGN_IN_MODE);
  }

  async saveAuthToken(token: string) {
    return this.storageService.save(AuthStorage.TOKEN, token);
  }

  async removeAuthToken() {
    return this.storageService.remove(AuthStorage.TOKEN);
  }

  async getAuthToken(): Promise<string> {
    return this.storageService.get(AuthStorage.TOKEN);
  }

  async saveProviderType(providerType: ProviderType) {
    return this.storageService.save(AuthStorage.PROVIDER_TYPE, providerType);
  }

  async removeProviderType() {
    return this.storageService.remove(AuthStorage.PROVIDER_TYPE);
  }

  async getProviderType(): Promise<ProviderType> {
    return this.storageService.get(AuthStorage.PROVIDER_TYPE);
  }

  async removeEmployeeStoreId() {
    return this.storageService.remove(AuthStorage.EMPLOYEE_STORE);
  }

  async saveEmployeeStoreId(id: string) {
    return this.storageService.save(AuthStorage.EMPLOYEE_STORE, id);
  }

  async getEmployeeStoreId(): Promise<string> {
    return this.storageService.get(AuthStorage.EMPLOYEE_STORE);
  }

  async saveApiKey(apiKey: string) {
    return this.storageService.save(AuthStorage.API_KEY, apiKey);
  }

  async saveIsGuest() {
    return this.storageService.saveBoolean(AuthStorage.GUEST, true);
  }

  async removeIsGuest() {
    return this.storageService.remove(AuthStorage.GUEST);
  }

  async isGuest(): Promise<boolean> {
    return this.storageService.getBoolean(AuthStorage.GUEST);
  }

  async saveIsTerminal() {
    await this.storageService.saveBoolean(AuthStorage.TERMINAL, true);
    this.terminalChangeSubject.next();
  }

  async removeIsTerminal() {
    await this.storageService.remove(AuthStorage.TERMINAL);
    await this.storageService.remove(AuthStorage.TERMINAL_ID);
    this.terminalChangeSubject.next();
  }

  async isTerminal(): Promise<boolean> {
    return this.storageService.getBoolean(AuthStorage.TERMINAL);
  }

  async isChip(): Promise<boolean> {
    const providerType = await this.getProviderType();
    return providerType === ProviderType.KIWI_CHIP;
  }

  async getApiKey(): Promise<string> {
    return this.storageService.get(AuthStorage.API_KEY);
  }

  async removeApiKey() {
    return this.storageService.remove(AuthStorage.API_KEY);
  }

  async clear() {
    return this.storageService.remove(
      AuthStorage.API_KEY,
      AuthStorage.CHIP,
      AuthStorage.EMPLOYEE_STORE,
      AuthStorage.GUEST,
      AuthStorage.PROVIDER_TYPE,
      AuthStorage.TOKEN,
    );
  }
}
