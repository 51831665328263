import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (location) {
  const terminalKey = '?terminal-id';
  if (location.search.indexOf(terminalKey) === 0) {
    const id = (location.search.split(terminalKey)[1].split('&')[0] || '').replace('=', '');
    localStorage.setItem('terminal-id', id);
  }
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
