import {Injectable} from '@angular/core';
import {ApiService} from '../global/api.service';
import {Order, PaymentLog} from '../../types/models';
import {AdminOrderStorageService} from '../storage/admin-order-storage.service';
import {Observable, Subject} from 'rxjs';
import {GeoLocation} from "../../types/interfaces";
import {DeliveryState, OrderState, PaymentLogType} from "../../types/enums";
import {UserService} from "../customer/user.service";
import {AuthService} from "../global/auth.service";
import {AdminUserService} from "./admin-user.service";
import * as moment from "moment/moment";

@Injectable({
  providedIn: 'root'
})
export class AdminOrderService {

  private startLocationTrackingSubject = new Subject<string>();
  private stopLocationTrackingSubject = new Subject<void>();

  constructor(
    private api: ApiService,
    private storageService: AdminOrderStorageService,
    private authService: AuthService,
    private userService: UserService,
    private adminUserService: AdminUserService
  ) {
  }

  init() {
    this.authService.Logout.subscribe(async () => this.stopLocationTrackingSubject.next());
    this.authService.LoggedIn.subscribe(async () => {
      if (await this.userService.hasRole()) {
        const tracking = await this.adminUserService.getMyTask();
        if (!tracking?.OrderId) {
          return;
        }
        const order = await this.getMy();
        if (!order) {
          return;
        }
        const deliveringIndex = order.DeliveryState.findIndex(s => s.Key === DeliveryState.DELIVERING);
        const deliveredIndex = order.DeliveryState.findIndex(s => s.Key === DeliveryState.DELIVERED);
        const deliveringDone = deliveringIndex >= 0 ? order.DeliveryState[deliveringIndex].Done : true;
        const deliveredDone = deliveredIndex >= 0 ? order.DeliveryState[deliveredIndex].Done : true;
        if (deliveringDone && !deliveredDone) {
          return this.startLocationTrackingSubject.next(order.Id);
        }
      }
    });
  }

  public static getEmptyOrder() {
    return {
      Id: null,
      Price: null,
      Tip: null,
      DeliveryCost: null,
      DeliveryState: [],
      State: null,
      PrintingState: null,
      EmployeeId: null,
      Employee: null,
      Note: null,
      AddressId: null,
      Address: null,
      StoreId: null,
      OrderProducts: [],
      UserId: null,
      User: null,
      CreatedAt: null,
      UpdatedAt: null,
      Chargeback: null
    };
  }

  public get StartLocationTracking(): Observable<string> {
    return this.startLocationTrackingSubject.asObservable();
  }

  public get StopLocationTracking(): Observable<void> {
    return this.stopLocationTrackingSubject.asObservable();
  }

  async getAllByTime(start: any, end: any): Promise<Order[]> {
    const startDate = moment(start).format('YYYY-MM-DD');
    const endDate = moment(end).format('YYYY-MM-DD');
    return this.api.get(`admin/order/by-time/${startDate}/${endDate}`);
  }

  async getAllOpen(): Promise<Order[]> {
    return this.api.get('admin/order/open');
  }

  async get(id: string): Promise<Order> {
    if (!id) {
      return null;
    }
    return this.api.get(`admin/order/${id}`);
  }

  async getMy(): Promise<Order> {
    return this.api.get(`admin/order/my`);
  }

  async getAllLogs(id: string, type?: PaymentLogType): Promise<PaymentLog[]> {
    const queryParams = type ? { Type: type } : null;
    return this.api.get('admin/order/logs/' + id, queryParams);
  }

  public async saveLocation(id: string, location: GeoLocation): Promise<any> {
    return this.api.post(`admin/order/location/${id}`, location);
  }

  async startPacking(id: string): Promise<Order> {
    await this.storageService.clear();
    return this.api.post(`admin/order/start/packing/${id}`, {});
  }

  async abortPacking(id: string): Promise<Order> {
    const success = await this.api.post(`admin/order/abort/packing/${id}`, {});
    if (success) {
      await this.storageService.clearPacking();
    }
    return success;
  }

  async finishPacking(id: string): Promise<Order> {
    const success = await this.api.post(`admin/order/finish/packing/${id}`, {});
    if (success) {
      await this.storageService.clearPacking();
    }
    return success;
  }

  async startDelivered(id: string): Promise<Order> {
    await this.storageService.clear();
    return this.api.post(`admin/order/start/delivered/${id}`, {});
  }

  async startDelivering(id: string): Promise<Order> {
    await this.storageService.clear();
    const success = await this.api.post(`admin/order/start/delivering/${id}`, {});
    if (success) {
      this.startLocationTrackingSubject.next(id);
    }
    return success;
  }

  async abortDelivering(id: string): Promise<Order> {
    const success = await this.api.post(`admin/order/abort/delivering/${id}`, {});
    if (success) {
      this.stopLocationTrackingSubject.next();
    }
    return success;
  }

  async finishDelivering(id: string): Promise<Order> {
    const success = await this.api.post(`admin/order/finish/delivering/${id}`, {});
    if (success) {
      this.stopLocationTrackingSubject.next();
    }
    return success;
  }

  async abort(id: string): Promise<Order> {
    return this.api.post(`admin/order/abort/${id}`, {});
  }

  async finish(order: Order): Promise<Order> {
    const success = await this.api.post(`admin/order/finish`, order, 'Auftrag abgeschlossen');
    if (success) {
      await this.storageService.clear();
    }
    return success;
  }

  async cancelOrder(order: Order, cascade = false): Promise<string> {
    const params: any = {};
    if (cascade) {
      params.cascade = cascade;
    }
    return this.api.deleteParams('admin/order/' + order.Id, params, 'Löschen erfolgreich', true);
  }

  async revertOrderWithOpenPayment(orderId: string): Promise<Order> {
    return this.api.delete('admin/order/revert/' + orderId, 'Stornierung erfolgreich', true);
  }

  async bookCorrections(order: Order): Promise<string> {
    const success = await this.api.post('admin/order/book-corrections', order, 'Speichern erfolgreich');
    await this.storageService.clearAmountsByOrderId(order.Id);
    return success;
  }

  async bookReturns(order: Order): Promise<string> {
    const success = await this.api.post('admin/order/book-returns', order, 'Speichern erfolgreich');
    await this.storageService.clearReturnsByOrderId(order.Id);
    return success;
  }

  async bookDepositReturns(order: Order): Promise<string> {
    const success = await this.api.post('admin/order/book-deposit-returns', order, 'Speichern erfolgreich');
    await this.storageService.clearDepositReturns();
    return success;
  }

  async bookAll(order: Order): Promise<string> {
    const success = await this.api.post('admin/order/book-all', order, 'Speichern erfolgreich');
    await this.storageService.clearDepositReturns();
    await this.storageService.clearAmountsByOrderId(order.Id);
    return success;
  }

  async getAllByUserId(userId: string, limit?: number): Promise<Order[]> {
    const params: any = {};
    if (limit) {
      params.Limit = limit;
    }
    return this.api.get('admin/order/by-user/' + userId, params);
  }

  async getByUserId(userId: string, id: string): Promise<Order> {
    if (!id || !userId) {
      return null;
    }
    return this.api.get('admin/order/by-user/' + userId + '/' + id);
  }

  showDeleteOrder(order: Order) {
    return order?.State !== OrderState.DONE
  }

  canBeFinished(order: Order): boolean {
    const parentOrder = order;
    const openPaymentCondition = order => order.Payment.PromisePayed && !order.Payment.Payed && order.Payment.Method?.DeliveryPayment;
    const ordersWithOpenDeliveryPayments = parentOrder?.Orders?.length > 0
      ? parentOrder.Orders.filter(order => openPaymentCondition(order))
      : openPaymentCondition(parentOrder) ? [parentOrder] : [];
    return ordersWithOpenDeliveryPayments.length === 0;
  }
}
