import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const pages = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'admin-order-edit', //TODO remove this after changed in api (see api: printer.service + order.push.service as well as qr codes in receipts)
    loadChildren: () => import('./pages/order/admin/order-edit/order-edit.module').then((m) => m.OrderEditPageModule),
  },
  {
    path: 'stock-order-edit', //TODO remove this after changed in api (see api: order.push.service)
    loadChildren: () => import('./pages/stock-order/admin/stock-order-edit/stock-order-edit.module').then((m) => m.StockOrderEditPageModule),
  },
  {
    path: 'cart-detail',
    loadChildren: () => import('./pages/cart/customer/cart-detail/cart-detail.module').then(m => m.CartDetailPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/cart/customer/checkout/checkout.module').then((m) => m.CheckoutPageModule),
  },
  {
    path: 'scanner',
    loadChildren: () => import('./pages/scanner/scanner.module').then((m) => m.ScannerPageModule),
  },
  {
    path: 'signin',
    loadChildren: () => import('./pages/sign/signin/signin.module').then((m) => m.SigninPageModule),
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/sign/signup/signup.module').then((m) => m.SignupPageModule),
  }
];

const modals = [
  {
    path: 'add-role-modal',
    loadChildren: () => import('./modals/add-role-modal/add-role-modal.module').then(m => m.AddRoleModalPageModule)
  },
  {
    path: 'address-display-modal',
    loadChildren: () => import('./modals/address-display-modal/address-display-modal.module').then(m => m.AddressDisplayModalPageModule)
  },
  {
    path: 'admin-billing-api-keys',
    loadChildren: () => import('./modals/admin-billing-api-keys/admin-billing-api-keys.module').then( m => m.AdminBillingApiKeysPageModule)
  },
  {
    path: 'admin-login-modal',
    loadChildren: () => import('./modals/admin-login-modal/admin-login-modal.module').then( m => m.AdminLoginModalPageModule)
  },
  {
    path: 'admin-order-correction-modal',
    loadChildren: () => import('./modals/admin-order-correction-modal/admin-order-correction-modal.module').then(m => m.AdminOrderCorrectionModalPageModule)
  },
  {
    path: 'admin-order-info-modal',
    loadChildren: () => import('./modals/admin-order-info-modal/admin-order-info-modal.module').then(m => m.AdminOrderInfoModalPageModule)
  },
  {
    path: 'admin-order-payment-modal',
    loadChildren: () => import('./modals/admin-order-payment-modal/admin-order-payment-modal.module').then(m => m.AdminOrderPaymentModalPageModule)
  },
  {
    path: 'admin-payment-method-api-keys-setting',
    loadChildren: () => import('./modals/admin-payment-method-api-keys-setting/admin-payment-method-api-keys-setting.module').then(m => m.AdminPaymentMethodApiKeysSettingPageModule)
  },
  {
    path: 'admin-terminal-group-select-modal',
    loadChildren: () => import('./modals/admin-terminal-group-select-modal/admin-terminal-group-select-modal.module').then( m => m.AdminTerminalGroupSelectModalPageModule)
  },
  {
    path: 'app-version-modal',
    loadChildren: () => import('./modals/app-version-modal/app-version-modal.module').then(m => m.AppVersionModalPageModule)
  },
  {
    path: 'billing-address-modal',
    loadChildren: () => import('./modals/billing-address-modal/billing-address-modal.module').then(m => m.BillingAddressModalPageModule)
  },
  {
    path: 'chip-login-modal',
    loadChildren: () => import('./modals/chip-login-modal/chip-login-modal.module').then( m => m.ChipLoginModalPageModule)
  },
  {
    path: 'confirm-modal',
    loadChildren: () => import('./modals/confirm-modal/confirm-modal.module').then((m) => m.ConfirmModalPageModule),
  },
  {
    path: 'coupon-modal',
    loadChildren: () => import('./modals/coupon-modal/coupon-modal.module').then(m => m.CouponModalPageModule)
  },
  {
    path: 'delivery-type-select-modal',
    loadChildren: () => import('./modals/delivery-type-select-modal/delivery-type-select-modal.module').then(m => m.DeliveryTypeSelectModalPageModule)
  },
  {
    path: 'forgot-password-modal',
    loadChildren: () => import('./modals/forgot-password-modal/forgot-password-modal.module').then((m) => m.ForgotPasswordModalPageModule),
  },
  {
    path: 'icon-select-modal',
    loadChildren: () => import('./modals/icon-select-modal/icon-select-modal.module').then((m) => m.IconSelectModalPageModule),
  },
  {
    path: 'idle-modal',
    loadChildren: () => import('./modals/idle-modal/idle-modal.module').then( m => m.IdleModalPageModule)
  },
  {
    path: 'opening-hours-modal',
    loadChildren: () => import('./modals/opening-hours-modal/opening-hours-modal.module').then(m => m.OpeningHoursModalPageModule)
  },
  {
    path: 'password-edit-modal',
    loadChildren: () => import('./modals/password-edit-modal/password-edit-modal.module').then( m => m.PasswordEditModalPageModule)
  },
  {
    path: 'payment-method-modal',
    loadChildren: () => import('./modals/payment-method-modal/payment-method-modal.module').then(m => m.PaymentMethodModalPageModule)
  },
  {
    path: 'payment-verification-modal',
    loadChildren: () => import('./modals/payment-verification-modal/payment-verification-modal.module').then(m => m.PaymentVerificationModalPageModule)
  },
  {
    path: 'pin-verification-modal',
    loadChildren: () => import('./modals/pin-verification-modal/pin-verification-modal.module').then((m) => m.PinVerificationModalPageModule),
  },
  {
    path: 'product-detail-modal',
    loadChildren: () => import('./modals/product-detail-modal/product-detail-modal.module').then((m) => m.ProductDetailModalPageModule),
  },
  {
    path: 'product-select-modal',
    loadChildren: () => import('./modals/product-select-modal/product-select-modal.module').then(m => m.ProductSelectModalPageModule)
  },
  {
    path: 'time-slot-select-modal',
    loadChildren: () => import('./modals/time-slot-select-modal/time-slot-select-modal.module').then(m => m.TimeSlotSelectModalPageModule)
  },
  {
    path: 'user-information-modal',
    loadChildren: () => import('./modals/user-information-modal/user-information-modal.module').then(m => m.UserInformationModalPageModule)
  }
];

const adminNavigation = [
  {
    path: 'coupon-navigation',
    loadChildren: () => import('./pages/navigation/admin/coupon-navigation/coupon-navigation.module').then(m => m.CouponNavigationPageModule)
  },
  {
    path: 'master-data-navigation',
    loadChildren: () => import('./pages/navigation/admin/master-data-navigation/master-data-navigation.module').then(m => m.MasterDataNavigationPageModule)
  },
  {
    path: 'news-navigation',
    loadChildren: () => import('./pages/navigation/admin/news-navigation/news-navigation.module').then(m => m.NewsNavigationPageModule)
  },
  {
    path: 'recipe-import-navigation',
    loadChildren: () => import('./pages/navigation/admin/recipe-import-navigation/recipe-import-navigation.module').then((m) => m.RecipeImportNavigationPageModule),
  },
  {
    path: 'statistic-navigation',
    loadChildren: () => import('./pages/navigation/admin/statistic-navigation/statistic-navigation.module').then((m) => m.StatisticNavigationModule),
  },
  {
    path: 'stock-navigation',
    loadChildren: () => import('./pages/navigation/admin/stock-navigation/stock-navigation.module').then((m) => m.StockNavigationPageModule),
  },
  {
    path: 'stock-order-navigation',
    loadChildren: () => import('./pages/navigation/admin/stock-order-navigation/stock-order-navigation.module').then((m) => m.StockOrderNavigationPageModule),
  },
  {
    path: 'store-setting-navigation',
    loadChildren: () => import('./pages/navigation/admin/store-setting-navigation/store-setting-navigation.module').then(m => m.StoreSettingNavigationPageModule)
  },
  {
    path: 'task-navigation',
    loadChildren: () => import('./pages/navigation/admin/task-navigation/task-navigation.module').then((m) => m.TaskNavigationPageModule),
  },
  {
    path: 'terminal-navigation',
    loadChildren: () => import('./pages/navigation/admin/terminal-navigation/terminal-navigation.module').then(m => m.TerminalNavigationPageModule)
  },
];

const adminPages = [
  {
    path: 'address-edit',
    loadChildren: () => import('./pages/address/admin/address-edit/address-edit.module').then(m => m.AddressEditPageModule)
  },
  {
    path: 'appointment-detail',
    loadChildren: () => import('./pages/appointment/admin/appointment-detail/appointment-detail.module').then(m => m.AppointmentDetailPageModule)
  },
  {
    path: 'appointment-edit',
    loadChildren: () => import('./pages/appointment/admin/appointment-edit/appointment-edit.module').then( m => m.AppointmentEditPageModule)
  },
  {
    path: 'category-edit',
    loadChildren: () => import('./pages/category/admin/category-edit/category-edit.module').then((m) => m.CategoryEditPageModule),
  },
  {
    path: 'billing-address-edit',
    loadChildren: () => import('./pages/address/admin/billing-address-edit/billing-address-edit.module').then(m => m.BillingAddressEditPageModule)
  },
  {
    path: 'category-list',
    loadChildren: () => import('./pages/category/admin/category-list/category-list.module').then((m) => m.CategoryListPageModule),
  },
  {
    path: 'competition-edit',
    loadChildren: () => import('./pages/competition/admin/competition-edit/competition-edit.module').then(m => m.CompetitionEditPageModule)
  },
  {
    path: 'competition-list',
    loadChildren: () => import('./pages/competition/admin/competition-list/competition-list.module').then(m => m.CompetitionListPageModule)
  },
  {
    path: 'competition-participant-list',
    loadChildren: () => import('./pages/competition/admin/competition-participant-list/competition-participant-list.module').then(m => m.CompetitionParticipantListPageModule)
  },
  {
    path: 'competition-prize-create',
    loadChildren: () => import('./pages/competition/admin/competition-prize-create/competition-prize-create.module').then(m => m.CompetitionPrizeCreatePageModule)
  },
  {
    path: 'competition-prize-edit',
    loadChildren: () => import('./pages/competition/admin/competition-prize-edit/competition-prize-edit.module').then(m => m.CompetitionPrizeEditPageModule)
  },
  {
    path: 'competition-prize-list',
    loadChildren: () => import('./pages/competition/admin/competition-prize-list/competition-prize-list.module').then(m => m.CompetitionPrizeListPageModule)
  },
  {
    path: 'cook-butler-import',
    loadChildren: () => import('./pages/recipe-import/admin/cook-butler-import/cook-butler-import.module').then((m) => m.CookButlerImportPageModule),
  },
  {
    path: 'cook-butler-search',
    loadChildren: () => import('./pages/recipe-import/admin/cook-butler-search/cook-butler-search.module').then((m) => m.CookButlerSearchPageModule),
  },
  {
    path: 'coupon-edit',
    loadChildren: () => import('./pages/coupon/admin/coupon-edit/coupon-edit.module').then(m => m.CouponEditPageModule)
  },
  {
    path: 'coupon-expired-list',
    loadChildren: () => import('./pages/coupon/admin/coupon-expired-list/coupon-expired-list.module').then(m => m.CouponExpiredListPageModule)
  },
  {
    path: 'coupon-list',
    loadChildren: () => import('./pages/coupon/admin/coupon-list/coupon-list.module').then(m => m.CouponListPageModule)
  },
  {
    path: 'done-news-list',
    loadChildren: () => import('./pages/news/admin/done-news-list/done-news-list.module').then(m => m.DoneNewsListPageModule)
  },
  {
    path: 'employee-list',
    loadChildren: () => import('./pages/user/admin/employee-list/employee-list.module').then((m) => m.EmployeeListPageModule),
  },
  {
    path: 'employee-my-task',
    loadChildren: () => import('./pages/user/admin/employee-my-task/employee-my-task.module').then(m => m.EmployeeMyTaskPageModule)
  },
  {
    path: 'gerne-kochen-import',
    loadChildren: () => import('./pages/recipe-import/admin/gerne-kochen-import/gerne-kochen-import.module').then(m => m.GerneKochenImportPageModule)
  },
  {
    path: 'gerne-kochen-search',
    loadChildren: () => import('./pages/recipe-import/admin/gerne-kochen-search/gerne-kochen-search.module').then(m => m.GerneKochenSearchPageModule)
  },
  {
    path: 'health-check',
    loadChildren: () => import('./pages/health-check/health-check.module').then((m) => m.HealthCheckPageModule),
  },
  {
    path: 'ingredient-group-edit',
    loadChildren: () => import('./pages/ingredient-group/admin/ingredient-group-edit/ingredient-group-edit.module').then(m => m.IngredientGroupEditPageModule)
  },
  {
    path: 'ingredient-group-list',
    loadChildren: () => import('./pages/ingredient-group/admin/ingredient-group-list/ingredient-group-list.module').then(m => m.IngredientGroupListPageModule)
  },
  {
    path: 'inventory-edit',
    loadChildren: () => import('./pages/inventory/admin/inventory-edit/inventory-edit.module').then(m => m.InventoryEditPageModule)
  },
  {
    path: 'inventory-list',
    loadChildren: () => import('./pages/inventory/admin/inventory-list/inventory-list.module').then(m => m.InventoryListPageModule)
  },
  {
    path: 'inventory-location-edit',
    loadChildren: () => import('./pages/inventory/admin/inventory-location-edit/inventory-location-edit.module').then((m) => m.InventoryLocationEditPageModule),
  },
  {
    path: 'inventory-location-list',
    loadChildren: () => import('./pages/inventory/admin/inventory-location-list/inventory-location-list.module').then((m) => m.InventoryLocationListPageModule),
  },
  {
    path: 'lieferzeit-product-list',
    loadChildren: () => import('./pages/product/admin/lieferzeit-product-list/lieferzeit-product-list.module').then(m => m.LieferzeitProductListPageModule)
  },
  {
    path: 'mandate-edit',
    loadChildren: () => import('./pages/mandate/admin/mandate-edit/mandate-edit.module').then(m => m.MandateEditPageModule)
  },
  {
    path: 'news-edit',
    loadChildren: () => import('./pages/news/admin/news-edit/news-edit.module').then(m => m.NewsEditPageModule)
  },
  {
    path: 'news-list',
    loadChildren: () => import('./pages/news/admin/news-list/news-list.module').then(m => m.NewsListPageModule)
  },
  {
    path: 'nutrition-score-edit',
    loadChildren: () => import('./pages/nutrition-score/admin/nutrition-score-edit/nutrition-score-edit.module').then((m) => m.NutritionScoreEditPageModule),
  },
  {
    path: 'nutrition-score-list',
    loadChildren: () => import('./pages/nutrition-score/admin/nutrition-score-list/nutrition-score-list.module').then((m) => m.NutritionScoreListPageModule),
  },
  {
    path: 'open-order-list',
    loadChildren: () => import('./pages/order/admin/open-order-list/open-order-list.module').then((m) => m.OpenOrderListPageModule),
  },
  {
    path: 'open-pending-time-slot-element-list',
    loadChildren: () => import('./pages/time-slot-element/admin/open-pending-time-slot-element-list/open-pending-time-slot-element-list.module').then(m => m.OpenPendingTimeSlotElementListPageModule)
  },
  {
    path: 'open-time-slot-edit',
    loadChildren: () => import('./pages/time-slot/admin/open-time-slot-edit/open-time-slot-edit.module').then(m => m.OpenTimeSlotEditPageModule)
  },
  {
    path: 'open-time-slot-element-list',
    loadChildren: () => import('./pages/time-slot-element/admin/open-time-slot-element-list/open-time-slot-element-list.module').then(m => m.OpenTimeSlotElementListPageModule)
  },
  {
    path: 'open-time-slot-group-edit',
    loadChildren: () => import('./pages/time-slot-group/admin/open-time-slot-group-edit/open-time-slot-group-edit.module').then(m => m.OpenTimeSlotGroupEditPageModule)
  },
  {
    path: 'opening-hours-edit',
    loadChildren: () => import('./pages/opening-hours/admin/opening-hours-edit/opening-hours-edit.module').then(m => m.OpeningHoursEditPageModule)
  },
  {
    path: 'order-delete',
    loadChildren: () => import('./pages/order/admin/order-delete/order-delete.module').then( m => m.OrderDeletePageModule)
  },
  {
    path: 'order-detail',
    loadChildren: () => import('./pages/order/admin/order-detail/order-detail.module').then((m) => m.OrderDetailPageModule),
  },
  {
    path: 'order-edit',
    loadChildren: () => import('./pages/order/admin/order-edit/order-edit.module').then((m) => m.OrderEditPageModule),
  },
  {
    path: 'order-list',
    loadChildren: () => import('./pages/order/admin/order-list/order-list.module').then( m => m.OrderListPageModule)
  },
  {
    path: 'order-receipt-detail',
    loadChildren: () => import('./pages/order/admin/order-receipt-detail/order-receipt-detail.module').then(m => m.OrderReceiptDetailPageModule)
  },
  {
    path: 'payment-method-edit',
    loadChildren: () => import('./pages/payment-method/admin/payment-method-edit/payment-method-edit.module').then(m => m.PaymentMethodEditPageModule)
  },
  {
    path: 'payment-method-list',
    loadChildren: () => import('./pages/payment-method/admin/payment-method-list/payment-method-list.module').then(m => m.PaymentMethodListPageModule)
  },
  {
    path: 'printer',
    loadChildren: () => import('./pages/printer/printer.module').then((m) => m.PrinterPageModule),
  },
  {
    path: 'product-edit',
    loadChildren: () => import('./pages/product/admin/product-edit/product-edit.module').then((m) => m.ProductEditPageModule),
  },
  {
    path: 'product-import',
    loadChildren: () => import('./pages/product/admin/product-import/product-import.module').then(m => m.ProductImportPageModule)
  },
  {
    path: 'product-list',
    loadChildren: () => import('./pages/product/admin/product-list/product-list.module').then((m) => m.ProductListPageModule),
  },
  {
    path: 'recipe-edit',
    loadChildren: () => import('./pages/recipe/admin/recipe-edit/recipe-edit.module').then((m) => m.RecipeEditPageModule),
  },
  {
    path: 'recipe-list',
    loadChildren: () => import('./pages/recipe/admin/recipe-list/recipe-list.module').then((m) => m.RecipeListPageModule),
  },
  {
    path: 'recipe-product-list',
    loadChildren: () => import('./pages/recipe/admin/recipe-product-list/recipe-product-list.module').then((m) => m.RecipeProductListPageModule),
  },
  {
    path: 'revenue',
    loadChildren: () => import('./pages/statistic/admin/revenue/revenue.module').then((m) => m.RevenuePageModule),
  },
  {
    path: 'role-list',
    loadChildren: () => import('./pages/role/admin/role-list/role-list.module').then((m) => m.RoleListPageModule),
  },
  {
    path: 'sales',
    loadChildren: () => import('./pages/statistic/admin/sales/sales.module').then((m) => m.SalesPageModule),
  },
  {
    path: 'search-list',
    loadChildren: () => import('./pages/search/admin/search-list/search-list.module').then((m) => m.SearchListPageModule),
  },
  {
    path: 'stock-edit',
    loadChildren: () => import('./pages/inventory/admin/stock-edit/stock-edit.module').then((m) => m.StockEditPageModule),
  },
  {
    path: 'stock-incoming-edit',
    loadChildren: () => import('./pages/inventory/admin/stock-incoming-edit/stock-incoming-edit.module').then((m) => m.StockIncomingEditPageModule),
  },
  {
    path: 'stock-loss-create',
    loadChildren: () => import('./pages/stock-loss/admin/stock-loss-create/stock-loss-create.module').then((m) => m.StockLossCreateModule),
  },
  {
    path: 'stock-order-edit',
    loadChildren: () => import('./pages/stock-order/admin/stock-order-edit/stock-order-edit.module').then((m) => m.StockOrderEditPageModule),
  },
  {
    path: 'stock-list',
    loadChildren: () => import('./pages/inventory/admin/stock-list/stock-list.module').then((m) => m.StockListPageModule),
  },
  {
    path: 'stock-return-edit',
    loadChildren: () => import('./pages/inventory/admin/stock-return-edit/stock-return-edit.module').then((m) => m.StockReturnEditModule),
  },
  {
    path: 'store-edit',
    loadChildren: () => import('./pages/store/admin/store-edit/store-edit.module').then((m) => m.StoreEditPageModule),
  },
  {
    path: 'store-list',
    loadChildren: () => import('./pages/store/admin/store-list/store-list.module').then((m) => m.StoreListPageModule),
  },
  {
    path: 'store-news-list',
    loadChildren: () => import('./pages/news/admin/store-news-list/store-news-list.module').then(m => m.StoreNewsListPageModule)
  },
  {
    path: 'store-packaging-edit',
    loadChildren: () => import('./pages/store/admin/store-packaging-edit/store-packaging-edit.module').then(m => m.StorePackagingEditPageModule)
  },
  {
    path: 'store-setting-edit',
    loadChildren: () => import('./pages/store/admin/store-setting-edit/store-setting-edit.module').then(m => m.StoreSettingEditPageModule)
  },
  {
    path: 'terminal-detail',
    loadChildren: () => import('./pages/terminal/admin/terminal-detail/terminal-detail.module').then( m => m.TerminalDetailPageModule)
  },
  {
    path: 'terminal-edit',
    loadChildren: () => import('./pages/terminal/admin/terminal-edit/terminal-edit.module').then( m => m.TerminalEditPageModule)
  },
  {
    path: 'terminal-list',
    loadChildren: () => import('./pages/terminal/admin/terminal-list/terminal-list.module').then( m => m.TerminalListPageModule)
  },
  {
    path: 'terminal-group-list',
    loadChildren: () => import('./pages/terminal-group/admin/terminal-group-list/terminal-group-list.module').then( m => m.TerminalGroupListPageModule)
  },
  {
    path: 'terminal-group-edit',
    loadChildren: () => import('./pages/terminal-group/admin/terminal-group-edit/terminal-group-edit.module').then( m => m.TerminalGroupEditPageModule)
  },
  {
    path: 'time-slot-create',
    loadChildren: () => import('./pages/time-slot/admin/time-slot-create/time-slot-create.module').then(m => m.TimeSlotCreatePageModule)
  },
  {
    path: 'time-slot-detail',
    loadChildren: () => import('./pages/time-slot/admin/time-slot-detail/time-slot-detail.module').then(m => m.TimeSlotDetailPageModule)
  },
  {
    path: 'time-slot-edit',
    loadChildren: () => import('./pages/time-slot/admin/time-slot-edit/time-slot-edit.module').then(m => m.TimeSlotEditPageModule)
  },
  {
    path: 'time-slot-group-detail',
    loadChildren: () => import('./pages/time-slot-group/admin/time-slot-group-detail/time-slot-group-detail.module').then(m => m.TimeSlotGroupDetailPageModule)
  },
  {
    path: 'time-slot-group-edit',
    loadChildren: () => import('./pages/time-slot-group/admin/time-slot-group-edit/time-slot-group-edit.module').then(m => m.TimeSlotGroupEditPageModule)
  },
  {
    path: 'time-slot-group-route-edit',
    loadChildren: () => import('./pages/time-slot-group/admin/time-slot-group-route-edit/time-slot-group-route-edit.module').then(m => m.TimeSlotGroupRouteEditPageModule)
  },
  {
    path: 'time-slot-list',
    loadChildren: () => import('./pages/time-slot/admin/time-slot-list/time-slot-list.module').then(m => m.TimeSlotListPageModule)
  },
  {
    path: 'time-slot-route-edit',
    loadChildren: () => import('./pages/time-slot/admin/time-slot-route-edit/time-slot-route-edit.module').then(m => m.TimeSlotRouteEditPageModule)
  },
  {
    path: 'time-slot-split-edit',
    loadChildren: () => import('./pages/time-slot-split/admin/time-slot-split-edit/time-slot-split-edit.module').then(m => m.TimeSlotSplitEditPageModule)
  },
  {
    path: 'time-slot-type-edit',
    loadChildren: () => import('./pages/time-slot-type/admin/time-slot-type-edit/time-slot-type-edit.module').then(m => m.TimeSlotTypeEditPageModule)
  },
  {
    path: 'time-slot-type-list',
    loadChildren: () => import('./pages/time-slot-type/admin/time-slot-type-list/time-slot-type-list.module').then(m => m.TimeSlotTypeListPageModule)
  },
  {
    path: 'user-edit',
    loadChildren: () => import('./pages/user/admin/user-edit/user-edit.module').then( m => m.UserEditPageModule)
  },
  {
    path: 'user-list',
    loadChildren: () => import('./pages/user/admin/user-list/user-list.module').then( m => m.UserListPageModule)
  },
  {
    path: 'user-order-detail',
    loadChildren: () => import('./pages/order/admin/user-order-detail/user-order-detail.module').then( m => m.UserOrderDetailPageModule)
  },
  {
    path: 'user-order-list',
    loadChildren: () => import('./pages/order/admin/user-order-list/user-order-list.module').then( m => m.UserOrderListPageModule)
  },
  {
    path: 'user-payment-method-edit',
    loadChildren: () => import('./pages/payment-method/admin/user-payment-method-edit/user-payment-method-edit.module').then( m => m.UserPaymentMethodEditPageModule)
  }
];

const routes: Routes = [
  ...pages,
  ...modals,
  {
    path: 'admin',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/navigation/admin/navigation/navigation.module').then((m) => m.NavigationPageModule),
      },
      ...adminPages,
      ...adminNavigation
    ]
  },
  {
    path: '**',
    redirectTo: '/not-found',
    pathMatch: 'full'
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
