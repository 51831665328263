import {Injectable} from '@angular/core';
import {registerPlugin} from '@capacitor/core';
import {BackgroundGeolocationPlugin} from '@capacitor-community/background-geolocation';
import {AdminOrderStorageService} from '../storage/admin-order-storage.service';
import {LocationService} from '../core/location.service';
import {AdminOrderService} from './admin-order.service';
import {GeoLocation} from "../../types/interfaces";
import {AdminTimeSlotService} from "./admin-time-slot.service";
import {AdminTimeSlotGroupService} from "./admin-time-slot-group.service";
import {AdminUserService} from "./admin-user.service";

const BackgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>(
  'BackgroundGeolocation'
);

@Injectable({
  providedIn: 'root',
})
export class AdminDeliveryLocationService {

  static readonly INTERVAL_OFFSET = 1000 * 30; // 30s

  currentPosition: GeoLocation = {
    Latitude: 52,
    Longitude: 9,
    Speed: 0,
  };
  trackId: string;
  backgroundWatcherId: string;
  intervalId: any;

  constructor(
    private storageService: AdminOrderStorageService,
    private locationService: LocationService,
    private orderService: AdminOrderService,
    private timeSlotService: AdminTimeSlotService,
    private timeSlotGroupService: AdminTimeSlotGroupService,
    private userService: AdminUserService
  ) {
  }

  init() {
    this.orderService.StartLocationTracking.subscribe(async orderId => this.startTracking(orderId));
    this.orderService.StopLocationTracking.subscribe(async () => this.stopTracking());
    this.timeSlotService.StartLocationTracking.subscribe(async timeSlotId => this.startTracking(timeSlotId));
    this.timeSlotService.StopLocationTracking.subscribe(async () => this.stopTracking());
    this.timeSlotGroupService.StartLocationTracking.subscribe(async timeSlotGroupId => this.startTracking(timeSlotGroupId));
    this.timeSlotGroupService.StopLocationTracking.subscribe(async () => this.stopTracking());
  }

  private startInterval(): void {
    this.intervalId = setInterval(async () => this.checkTracking(), AdminDeliveryLocationService.INTERVAL_OFFSET);
  }

  private stopInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  private async checkTracking(): Promise<void> {
    await this.removeWatcher();
    await this.updateCurrentPosition();
  }

  private async updateCurrentPosition() {
      try {
        this.backgroundWatcherId = await this.createBackgroundWatcherAndSetPosition();
      } catch (Err) {
        await this.setPositionByForegroundGeoLocation();
      }
  }

  private async createBackgroundWatcherAndSetPosition(): Promise<string> {
    return BackgroundGeolocation.addWatcher(
      {
        backgroundMessage: 'Dein Standort wird im Hintergrund aufgezeichnet und an den Kunden gesendet.',
        backgroundTitle: 'Standortüberwachung während der Lieferung',
        requestPermissions: true,
        stale: false,
        distanceFilter: 200,
      },
      async (location, error) => {
        if (error) {
          if (error.code === 'NOT_AUTHORIZED') {
            if (window.confirm('Wir benötigen deinen Standort, um Lieferungen verfolgen zu können.')) {
              await BackgroundGeolocation.openSettings();
            }
          }
          return console.error(error);
        }
        return this.savePosition({
          Latitude: location.latitude,
          Longitude: location.longitude,
          Speed: location.speed
        });
      }
    );
  }

  private async setPositionByForegroundGeoLocation() {
    try {
      const location = await this.locationService.loadAndSaveGeoLocation(1000);
      return this.savePosition(location);
    } catch (e) {
      console.log('no location tracking possible');
    }
  }

  private async removeWatcher() {
    if (this.backgroundWatcherId) {
      try {
        await BackgroundGeolocation.removeWatcher({ id: this.backgroundWatcherId });
        this.backgroundWatcherId = null;
      } catch (e) {
        console.log('removing watcher failed');
      }
    }
  }

  private savePosition(location: GeoLocation) {
    this.currentPosition = location;
    return this.userService.saveTaskLocation(this.trackId, location);
  }


  private async startTracking(id: string) {
    await this.stopTracking();
    this.trackId = id;
    await this.updateCurrentPosition();
    this.startInterval();
  }


  private async stopTracking(): Promise<void> {
    this.trackId = null;
    this.stopInterval();
    return this.removeWatcher();
  }
}
