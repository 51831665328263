import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {OpeningHour} from '../../types/models';
import {DeliveryType} from "../../types/enums";
import {AuthStorageService} from "./auth-storage.service";

enum DeliveryStorage {
  ADDRESS = 'delivery-address-id',
  BILLING_ADDRESS = 'billing-address-id',
  DELIVERY_NOTE = 'delivery-note',
  DELIVERY_TIP = 'delivery-tip',
  DELIVERY_TIME = 'delivery-time',
  DELIVERY_TYPE = 'delivery-type',
  STORE = 'store-id',
  STORE_MINIMUM_ORDER = 'store-minim-order-value',
  STORE_DELIVERY_COST = 'store-delivery-cost',
  STORE_OPEN = 'store-is-open',
  TIMESLOT = 'delivery-time-slot-id',
  TIMESLOT_DELIVERY_COST = 'timeslot-delivery-cost',
  TIMESLOT_FOLLOWUP_FEE = 'timeslot-followup-fee',
  TIMESLOT_MINIMUM_ORDER = 'timeslot-minim-order-value',
  SHOW_APPOINTMENTS = 'show-appointments',
  USE_BILLING_ADDRESS = 'billing-address'
}

@Injectable({
  providedIn: 'root'
})
export class DeliveryStorageService {

  constructor(
    private storageService: StorageService,
    private authStorage: AuthStorageService
  ) {
  }

  async clear() {
    await this.storageService.remove(
      DeliveryStorage.ADDRESS,
      DeliveryStorage.BILLING_ADDRESS,
      DeliveryStorage.USE_BILLING_ADDRESS,
      DeliveryStorage.STORE,
      DeliveryStorage.STORE_MINIMUM_ORDER,
      DeliveryStorage.STORE_DELIVERY_COST,
      DeliveryStorage.STORE_OPEN,
      DeliveryStorage.DELIVERY_TYPE,
      DeliveryStorage.DELIVERY_NOTE,
      DeliveryStorage.DELIVERY_TIME,
      DeliveryStorage.DELIVERY_TIP,
      DeliveryStorage.TIMESLOT,
      DeliveryStorage.TIMESLOT_DELIVERY_COST,
      DeliveryStorage.TIMESLOT_FOLLOWUP_FEE,
      DeliveryStorage.TIMESLOT_MINIMUM_ORDER,
      DeliveryStorage.SHOW_APPOINTMENTS
    );
  }

  async clearDeliveryData() {
    return this.storageService.remove(
      DeliveryStorage.DELIVERY_NOTE,
      DeliveryStorage.DELIVERY_TIP,
      DeliveryStorage.USE_BILLING_ADDRESS,
    );
  }

  async saveStoreMinimumOrderValue(value: number) {
    return this.storageService.saveNumber(DeliveryStorage.STORE_MINIMUM_ORDER, value);
  }

  async saveTimeSlotMinimumOrderValue(value: number) {
    if (value === null) {
      return this.removeTimeSlotMinimumOrderValue();
    }
    return this.storageService.saveNumber(DeliveryStorage.TIMESLOT_MINIMUM_ORDER, value);
  }

  async getMinimumOrderValue(): Promise<number> {
    const [storeMinimumOrderValue, timeSlotMinimumOrderValue] = await Promise.all([
      this.getStoreMinimumOrderValue(),
      this.getTimeSlotMinimumOrderValue()
    ]);
    if (timeSlotMinimumOrderValue != null) {
      return timeSlotMinimumOrderValue;
    }
    return storeMinimumOrderValue;
  }

  async getStoreMinimumOrderValue(): Promise<number> {
    return this.storageService.getNumber(DeliveryStorage.STORE_MINIMUM_ORDER);
  }

  async getTimeSlotMinimumOrderValue(): Promise<number> {
    return this.storageService.getNumber(DeliveryStorage.TIMESLOT_MINIMUM_ORDER);
  }

  async removeStoreMinimumOrderValue() {
    return this.storageService.remove(DeliveryStorage.STORE_MINIMUM_ORDER);
  }

  async removeTimeSlotMinimumOrderValue() {
    return this.storageService.remove(DeliveryStorage.TIMESLOT_MINIMUM_ORDER);
  }

  async getTimeSlotFollowUpFee(): Promise<number> {
    return this.storageService.getNumber(DeliveryStorage.TIMESLOT_FOLLOWUP_FEE);
  }

  async removeTimeSlotFollowUpFee() {
    return this.storageService.remove(DeliveryStorage.TIMESLOT_FOLLOWUP_FEE);
  }

  async saveTimeSlotFollowUpFee(value: number) {
    if (!value) {
      return this.removeTimeSlotFollowUpFee();
    }
    return this.storageService.saveNumber(DeliveryStorage.TIMESLOT_FOLLOWUP_FEE, value);
  }

  async saveStoreDeliveryCost(value: number) {
    return this.storageService.saveNumber(DeliveryStorage.STORE_DELIVERY_COST, value);
  }

  async saveTimeSlotDeliveryCost(value: number) {
    if (value === null) {
      return this.removeTimeSlotDeliveryCost();
    }
    return this.storageService.saveNumber(DeliveryStorage.TIMESLOT_DELIVERY_COST, value);
  }

  async getDeliveryCost(): Promise<number> {
    const [storeDeliveryCost, timeSlotDeliverCost] = await Promise.all([
      this.getStoreDeliveryCost(),
      this.getTimeSlotDeliveryCost()
    ]);
    if (timeSlotDeliverCost != null) {
      return timeSlotDeliverCost;
    }
    return storeDeliveryCost;
  }

  async getStoreDeliveryCost(): Promise<number> {
    return this.storageService.getNumber(DeliveryStorage.STORE_DELIVERY_COST);
  }

  async getTimeSlotDeliveryCost(): Promise<number> {
    return this.storageService.getNumber(DeliveryStorage.TIMESLOT_DELIVERY_COST);
  }

  async removeStoreDeliveryCost() {
    return this.storageService.remove(DeliveryStorage.STORE_DELIVERY_COST);
  }

  async removeTimeSlotDeliveryCost() {
    return this.storageService.remove(DeliveryStorage.TIMESLOT_DELIVERY_COST);
  }

  async saveDeliveryTime(deliveryTime: number) {
    if (deliveryTime === null) {
      return this.removeDeliveryTime();
    }
    return this.storageService.saveNumber(DeliveryStorage.DELIVERY_TIME, deliveryTime);
  }

  async getDeliveryTime(): Promise<number> {
    return this.storageService.getNumber(DeliveryStorage.DELIVERY_TIME);
  }

  async removeDeliveryTime() {
    return this.storageService.remove(DeliveryStorage.DELIVERY_TIME);
  }

  async saveIsOpen(openingHour: OpeningHour) {
    if (!openingHour) {
      return;
    }
    return this.storageService.saveBoolean(DeliveryStorage.STORE_OPEN, openingHour.IsOpen);
  }

  async getIsOpen(): Promise<boolean> {
    return this.storageService.getBoolean(DeliveryStorage.STORE_OPEN);
  }

  async removeIsOpen() {
    return this.storageService.remove(DeliveryStorage.STORE_OPEN);
  }

  async removeStoreId() {
    await this.storageService.remove(DeliveryStorage.STORE);
  }

  async saveStoreId(id: string) {
    return this.storageService.save(DeliveryStorage.STORE, id);
  }

  async getStoreId(): Promise<string> {
    return this.storageService.get(DeliveryStorage.STORE);
  }

  async saveDeliveryNote(note: string) {
    return this.storageService.save(DeliveryStorage.DELIVERY_NOTE, note);
  }

  async getDeliveryNote(): Promise<string> {
    return this.storageService.get(DeliveryStorage.DELIVERY_NOTE);
  }

  async saveBillingAddressId(id: string) {
    return this.storageService.save(DeliveryStorage.BILLING_ADDRESS, id);
  }

  async saveUseBillingAddress(value: boolean) {
    if (value) {
      await this.storageService.saveBoolean(DeliveryStorage.USE_BILLING_ADDRESS, value);
    } else {
      await this.storageService.remove(DeliveryStorage.USE_BILLING_ADDRESS);
    }
  }

  async getUseBillingAddress(): Promise<boolean> {
    return this.storageService.getBoolean(DeliveryStorage.USE_BILLING_ADDRESS);
  }

  async getBillingAddressId(): Promise<string> {
    return this.storageService.get(DeliveryStorage.BILLING_ADDRESS);
  }

  async removeBillingAddressId() {
    return this.storageService.remove(DeliveryStorage.BILLING_ADDRESS);
  }

  async saveDeliveryTip(amount: number) {
    return this.storageService.saveNumber(DeliveryStorage.DELIVERY_TIP, amount);
  }

  async getDeliveryTip(): Promise<number> {
    return this.storageService.getNumber(DeliveryStorage.DELIVERY_TIP);
  }

  async getDeliveryAddressId(): Promise<string> {
    return this.storageService.get(DeliveryStorage.ADDRESS);
  }

  async saveDeliveryAddressId(id: string) {
    return this.storageService.save(DeliveryStorage.ADDRESS, id);
  }

  async removeDeliveryAddressId() {
    await this.storageService.remove(DeliveryStorage.ADDRESS);
  }

  async getDeliveryTimeSlotId(): Promise<string> {
    return this.storageService.get(DeliveryStorage.TIMESLOT);
  }

  async saveDeliveryTimeSlotId(id: string) {
    return this.storageService.save(DeliveryStorage.TIMESLOT, id);
  }

  async removeDeliveryTimeSlotId() {
    await this.storageService.remove(DeliveryStorage.TIMESLOT);
  }

  async getDeliveryType(): Promise<DeliveryType> {
    if (await this.showAppointments()) {
      const value = await this.storageService.get(DeliveryStorage.DELIVERY_TYPE);
      const chipUser = await this.authStorage.isChip();
      return value === DeliveryType.APPOINTMENT ? DeliveryType.APPOINTMENT : chipUser ? DeliveryType.APPOINTMENT : DeliveryType.INSTANT;
    }
    return DeliveryType.INSTANT;
  }

  async saveDeliveryType(type: DeliveryType) {
    if (type === DeliveryType.INSTANT) {
      await this.removeTimeSlotFollowUpFee();
    }
    return this.storageService.save(DeliveryStorage.DELIVERY_TYPE, type);
  }

  async showAppointments(): Promise<boolean> {
    return this.storageService.getBoolean(DeliveryStorage.SHOW_APPOINTMENTS);
  }

  async saveShowAppointments(flag: boolean) {
    return this.storageService.saveBoolean(DeliveryStorage.SHOW_APPOINTMENTS, flag);
  }

}
