import { Pipe, PipeTransform } from '@angular/core';
import {RoleType} from "../types/enums";

@Pipe({
  name: 'role'
})
export class RolePipe implements PipeTransform {

   roleTypes = {
    [RoleType.ADMIN]: 'Administrator',
    [RoleType.STORE_MANAGER]: 'Filialleiter',
    [RoleType.STOCK_EMPLOYEE]: 'Lagerist',
    [RoleType.DRIVER]: 'Lieferant',
    [RoleType.DATA_MANAGER]: 'Verwalter'
  };

  transform(value: string): string {
    return this.roleTypes[value] || value;
  }

}
