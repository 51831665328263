import {Injectable} from '@angular/core';
import {ApiService} from "../global/api.service";
import {CartStorageService} from "../storage/cart-storage.service";
import {Cart, Recipe} from '../../types/models';
import {KeyMap} from "../../types/interfaces";
import {NavigationEnd, Router} from "@angular/router";
import {Observable, Subscription} from "rxjs";
import {AuthStorageService} from "../storage/auth-storage.service";
import {CartProductItemStorage, CartRecipeStorage, CartProductStorage, CartStorage} from "../../types/storage";
import {AuthService} from "../global/auth.service";

@Injectable({
  providedIn: 'root'
})
export class CartService {

  routeSubscription: Subscription;
  watchedRoutes = [
    'product-category-list',
    'product-category-detail',
    'product-sub-category-list',
    'product-detail',
    'search-detail',
    'recipe-category-list',
    'recipe-category-detail',
    'recipe-sub-category-list',
    'recipe-detail',
    'favorite'
  ];

  constructor(
    private api: ApiService,
    private storageService: CartStorageService,
    private router: Router,
    private authStorage: AuthStorageService,
    private authService: AuthService
  ) {
  }

  init() {
    this.authService.LoggedIn.subscribe(async () => this.startSynchronize());
    this.authService.Logout.subscribe(async () => this.stopSynchronize());
  }

  public get Change(): Observable<void> {
    return this.storageService.Change;
  }

  async stopSynchronize() {
    if (this.routeSubscription) {
      await this.synchronizeCartIfNeeded();
      await this.storageService.clear();
      this.routeSubscription.unsubscribe();
      this.routeSubscription = null;
    }
  }

  urlIsWatched(url: string): boolean {
    if (!url || url.length === 0) {
      return false;
    }
    for (const watchedUrl of this.watchedRoutes) {
      if (url.indexOf(watchedUrl) >= 0) {
        return true;
      }
    }
    return false;
  }

  async getSynchronizedCart(): Promise<{ Products?: CartProductStorage, Recipes?: CartRecipeStorage, Id?: string }> {
    return this.api.get('cart/synchronize');
  }

  async startSynchronize() {
    await this.stopSynchronize();
    const cart = await this.getSynchronizedCart();
    if (cart?.Products) {
      await this.storageService.saveProductCart(cart.Products);
    }
    if (cart?.Recipes) {
      await this.storageService.saveRecipeCart(cart.Recipes);
    }
    await this.storageService.setSynchronized(true);
    this.routeSubscription = this.router.events.subscribe(async event => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      if (!this.urlIsWatched(event.urlAfterRedirects)) {
        return;
      }
      return this.synchronizeCartIfNeeded();
    })
  }

  async synchronizeCartIfNeeded() {
    return this.synchronizeCart(true);
  }

  async synchronizeCart(synCheck: boolean = true) {
    const isGuest = await this.authStorage.isGuest();
    if (isGuest) {
      return;
    }
    const isInSync = synCheck && await this.storageService.isSynchronized();
    if (isInSync) {
      return;
    }
    const cart: CartStorage = {
      Products: await this.storageService.getProductCart(),
      Recipes: await this.storageService.getRecipeCart()
    };
    await this.api.post('cart/synchronize', cart, '', false, false);
    await this.storageService.setSynchronized(true);
  }

  async removeRecipe(recipe: Recipe) {
    return this.storageService.removeRecipe(recipe?.Id);
  }

  async getRecipesInCart(): Promise<string[]> {
    return Object.keys(await this.storageService.getRecipeCart());
  }

  async saveProduct(cartProduct: CartProductItemStorage) {
    return this.storageService.saveProduct(cartProduct);
  }

  async getProduct(productId: string): Promise<CartProductItemStorage> {
    return this.storageService.getProduct(productId);
  }

  async getProductCart(): Promise<CartProductStorage> {
    return this.storageService.getProductCart();
  }

  async saveProductCart(cart: CartProductStorage, recipe?: Recipe) {
    await this.storageService.saveProductCart(cart);
    await this.storageService.addRecipe(recipe?.Id);
  }

  async isEmpty(): Promise<boolean> {
    return this.storageService.isEmpty();
  }

  async get(): Promise<Cart> {
    if (await this.isEmpty()) {
      return { OrderProducts: [] };
    }
    const cart = await this.getCart();
    return this.loadCart(cart);
  }

  async loadCart(cart: { Products: KeyMap<number>, Recipes: string[] }): Promise<Cart> {
    return this.api.postParams('cart', cart, { Full: true });
  }

  private async getCart(): Promise<{ Products: KeyMap<number>, Recipes: string[] }> {
    const { Products: productCart, Recipes: recipes } = await this.storageService.getCart();
    const productMap = {};
    for (const productId in productCart || {}) {
      productMap[productId] = productCart[productId].Amount;
    }
    return { Products: productMap, Recipes: recipes };
  }

  async clear() {
    await this.api.post('cart/clear', null, '', false, false);
    return this.storageService.clearCart();
  }
}
